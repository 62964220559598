import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppCommonModule } from '@shared/common/common.module';
import { AppDialogsModule } from '@shared/dialogs/dialogs.module';
import {
    ShiftPreviewDialogComponent
} from '@shared/modules/shifts/shift-preview-dialog/shift-preview-dialog.component';
import {
    CarerContractsCommonModule
} from 'projects/shell/src/modules/carers/contracts-common/carer-contracts-common.module';
import { AppCarerSelectModule } from '../carers/select/carer-select.module';
import { AppFixedRatesModule } from '../fixed-rates/fixed-rates.module';

const dialogs = [ShiftPreviewDialogComponent];
const components = [];

@NgModule({
    declarations: [
        ...dialogs,
        ...components
    ],
    imports: [
        AppCommonModule,
        AppDialogsModule,
        AppCarerSelectModule,
        CarerContractsCommonModule,
        AppFixedRatesModule
    ],
    exports: [AppCommonModule, ...dialogs, ...components],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppShiftsModule {}
