import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { AppBadgeModule } from '@shared/common/badges/badge/badge.module';
import { AppPermissionsModule } from '@shared/common/permissions/permissions.module';
import { AppTimestampModule } from '@shared/common/timestamp/timestamp.module';
import { AppCountriesModule } from './common/countries/countries.module';
import { AppShiftsModule } from './modules/shifts/shifts.module';
import { ObjectValuesPipe } from './pipes/object-values.pipe';
import { NhsNumberValidator } from './validation/nhs-number.validator';

const materialModules = [
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonToggleModule,
];

@NgModule({
    imports: [
        AppCountriesModule,
        AppPermissionsModule,
        FormsModule,
        HttpClientModule,
        AppShiftsModule,
        ...materialModules,
        MatButtonToggleModule,
        AppBadgeModule,
        AppTimestampModule,
    ],
    exports: [
        AppCountriesModule,
        AppPermissionsModule,
        FormsModule,
        HttpClientModule,
        AppShiftsModule,
        ...materialModules,
        AppBadgeModule,
        AppTimestampModule,
        NhsNumberValidator,
        ObjectValuesPipe
    ],
    providers: [],
    declarations: [NhsNumberValidator, ObjectValuesPipe],
})
export class AppSharedModule {
}
