import type { OnInit } from '@angular/core';
import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { AbstractComponent } from '@shared/common/abstract.component';
import { makeProvider } from '@shared/form-fields/make-provider';
import { DateRangeValue } from '@shared/form-fields/date-range/date-range.value';
import { readableDateRange } from '@shared/utils/dates/readable-date-range';
import { DateTime } from 'luxon';

const todayDateTime = DateTime.local().startOf('day');

export enum PresetKey {
    today = 'today',
    yesterday = 'yesterday',
    last7Days = 'last_7_days',
    last28Days = 'last_28_days',
    last30Days = 'last_30_days',
    thisWeek = 'this_week',
    thisMonth = 'this_month',
    thisYear = 'this_year',
    lastWeek = 'last_week',
    lastMonth = 'last_month',
    lastYear = 'last_year',
}

const presets: Record<PresetKey, () => DateRangeValue> = {
    today: () => ({
        start: todayDateTime.startOf('day'),
        end: todayDateTime.endOf('day')
    }),

    yesterday: () => ({
        start: todayDateTime.startOf('day').minus({ day: 1 }),
        end: todayDateTime.endOf('day').minus({ day: 1 })
    }),

    // eslint-disable-next-line @typescript-eslint/naming-convention
    last_7_days: () => ({
        start: todayDateTime.startOf('day').minus({ day: 7 }),
        end: todayDateTime.endOf('day')
    }),

    // eslint-disable-next-line @typescript-eslint/naming-convention
    last_28_days: () => ({
        start: todayDateTime.startOf('day').minus({ day: 28 }),
        end: todayDateTime.endOf('day')
    }),

    // eslint-disable-next-line @typescript-eslint/naming-convention
    last_30_days: () => ({
        start: todayDateTime.startOf('day').minus({ day: 30 }),
        end: todayDateTime.endOf('day')
    }),

    // eslint-disable-next-line @typescript-eslint/naming-convention
    this_week: () => ({
        start: todayDateTime.startOf('week'),
        end: todayDateTime.endOf('week')
    }),

    // eslint-disable-next-line @typescript-eslint/naming-convention
    this_month: () => ({
        start: todayDateTime.startOf('month'),
        end: todayDateTime.endOf('month')
    }),

    // eslint-disable-next-line @typescript-eslint/naming-convention
    this_year: () => ({
        start: todayDateTime.startOf('year'),
        end: todayDateTime.endOf('year')
    }),

    // eslint-disable-next-line @typescript-eslint/naming-convention
    last_week: () => ({
        start: todayDateTime.minus({ week: 1 }).startOf('week'),
        end: todayDateTime.minus({ week: 1 }).endOf('week')
    }),

    // eslint-disable-next-line @typescript-eslint/naming-convention
    last_month: () => ({
        start: todayDateTime.minus({ month: 1 }).startOf('month'),
        end: todayDateTime.minus({ month: 1 }).endOf('month')
    }),

    // eslint-disable-next-line @typescript-eslint/naming-convention
    last_year: () => ({
        start: todayDateTime.minus({ year: 1 }).startOf('year'),
        end: todayDateTime.minus({ year: 1 }).endOf('year')
    }),
};

const presetLabels: Record<PresetKey, string> = {
    [PresetKey.today]: 'Today',
    [PresetKey.last28Days]: 'Last 28 days',
    [PresetKey.last30Days]: 'Last 30 days',
    [PresetKey.last7Days]: 'Last 7 days',
    [PresetKey.lastMonth]: 'Last month',
    [PresetKey.lastWeek]: 'Last week',
    [PresetKey.lastYear]: 'Last year',
    [PresetKey.thisMonth]: 'This month',
    [PresetKey.thisWeek]: 'This week',
    [PresetKey.thisYear]: 'This year',
    [PresetKey.yesterday]: 'Yesterday',
};

@Component({
    selector: 'app-input-date-range',
    styleUrls: ['./date-range.component.scss'],
    template: `
        <mat-form-field appearance="fill">
            <mat-date-range-input [rangePicker]="dateRangePicker"
                                  [max]="_max"
                                  [min]="_min">
                <input matStartDate
                       placeholder="Start date"
                       [(ngModel)]="_value.start">

                <input matEndDate
                       placeholder="End date"
                       [(ngModel)]="_value.end">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="dateRangePicker" />
            <mat-date-range-picker #dateRangePicker (closed)="valueChange.emit(_value)" />
        </mat-form-field>

        <button mat-stroked-button
                (click)="dateRangePicker.open()">
            {{ dateRange }}
            <mat-icon>date_range</mat-icon>

            <button mat-icon-button
                    *ngIf="showPresets"
                    (click)="$event.stopPropagation()"
                    [matMenuTriggerFor]="datePresetMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
        </button>

        <mat-menu #datePresetMenu="matMenu"
                  class="date-range-preset-menu">
            <ng-container *ngFor="let item of presets">

                <button
                    *ngIf="shouldShowPreset(item.key)"
                    mat-menu-item
                    [attr.data-testid]="item.key"
                    (click)="preset(item.key)">
                    {{ item.label}}
                </button>
            </ng-container>

            <!--            <button mat-menu-item (click)="preset('today')">Today</button>-->
            <!--            <button mat-menu-item (click)="preset('yesterday')">Yesterday</button>-->
            <!--            <button mat-menu-item (click)="preset('this_week')">This week</button>-->
            <!--            <button mat-menu-item (click)="preset('this_month')">This month</button>-->
            <!--            <button mat-menu-item (click)="preset('this_year')">This year</button>-->
            <!--            <button mat-menu-item (click)="preset('last_week')">Last week</button>-->
            <!--            <button mat-menu-item (click)="preset('last_month')">Last month</button>-->
            <!--            <button mat-menu-item (click)="preset('last_year')">Last year</button>-->
            <!--            <button mat-menu-item (click)="preset('last_7_days')">Last 7 days</button>-->
            <!--            <button mat-menu-item (click)="preset('last_28_days')">Last 28 days</button>-->
            <!--            <button mat-menu-item (click)="preset('last_30_days')">Last 30 days</button>-->
        </mat-menu>
    `,
    providers: [makeProvider(DateRangeComponent)],
})
export class DateRangeComponent extends AbstractComponent implements OnInit {
    @ViewChild('dateRangePicker', { read: MatDateRangePicker }) dateRangePicker: MatDateRangePicker<any>;

    readonly presets: { key: PresetKey; label: string }[] = Object.values(PresetKey).map(key => ({
        key,
        label: presetLabels[key]
    }));

    _value: DateRangeValue = { start: null, end: null };

    @Output() valueChange = new EventEmitter<DateRangeValue>();

    @Input() max: DateTime;
    @Input() min: DateTime;

    @Input() maxDays: number;

    @Input() onlyPresets: PresetKey[];
    @Input() showPresets = true;

    @Input() set value(value: DateRangeValue) {
        this._value = value ?? { start: null, end: null };
    }

    constructor(protected element: ElementRef, protected renderer: Renderer2) {
        super();
    }

    ngOnInit(): void {
        this.renderer.addClass(this.element.nativeElement, 'app-form-field');
    }

    get dateRange(): string {
        return readableDateRange(
            this._value?.start,
            this._value?.end
        );
    }

    get _max(): DateTime {
        if (this.max) {
            return this.max;
        }

        if (this.maxDays) {
            const start = this._value?.start;

            if (start) {
                return start.plus({ days: this.maxDays });
            }
        }
    }

    get _min(): DateTime {
        if (this.min) {
            return this.min;
        }

        if (this.maxDays) {
            const end = this._value?.end;

            if (end) {
                return end.minus({ days: this.maxDays });
            }
        }
    }

    preset(key: PresetKey) {
        if (presets.hasOwnProperty(key)) {
            this._value = presets[key]();
            this.valueChange.emit(this._value);
        }
    }

    open() {
        this.dateRangePicker?.open();
    }

    shouldShowPreset(key: PresetKey): boolean {
        if (this.onlyPresets) {
            return this.onlyPresets.includes(key);
        }

        return true;
    }
}
