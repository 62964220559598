export type HelpArticleKey =
    | 'first-steps'
    | 'loose-availability'
    | 'capacity-tools'
    | 'carer-contracts'
    | 'service-areas'
    | 'client-service-contracts'
    | 'tasks'
    | 'release-notes'
    | 'finance-dashboard'
    | 'incident-reports'
    | 'hastee'
    | 'publishing'
    | 'vaccinations'
    | 'access-care-circle'
    | 'overlapping-visits'
    | 'rounds'
    | 'carer-agenda'
    | 'schedule-entries-and-bookings'
    | 'client-agenda'
    | 'split-billing'
    | 'service-contract-rounding-precision'
    | 'custom-fields-dropdown'
    | 'controcc-bury'
    | 'controcc-east-sussex'
    | 'controcc-redbridge'
    | 'controcc-oxfordshire'
    | 'dynamics-gp'
    | 'dynamics-gp-export'
    | 'training-matrix'
    | 'tea-roulette'
    | 'mail-opt-outs:manage'
    | 'notification-groups'
    | 'body-maps'
    | 'teams'
    | 'global-buyer'
    | 'global-contact'
    | 'prsb-about-me'
    | 'bulk-update'
    | 'web-push'
    | 'formulation'
    | 'additional-support-plan'
    | 'contingency-plan'
    | 'care-plan'
    | 'advance-statement'
    | 'gp-connect-getting-started'
    | 'gp-connect-access-levels'
    | 'gp-connect-sections'
    ;

export interface HelpArticle {
    i: number; // Intercom
}

/* eslint-disable @typescript-eslint/naming-convention */
export const articles: Record<HelpArticleKey, HelpArticle> = {
    'first-steps': { i: 9142 },
    'loose-availability': { i: 9200 },
    'capacity-tools': { i: 9268 },
    'carer-contracts': { i: 9136 },
    'service-areas': { i: 9270 },
    'client-service-contracts': { i: 9163 },
    tasks: { i: 9134 },
    'release-notes': { i: 9250 },
    'finance-dashboard': { i: 9123 },
    'incident-reports': { i: 9284 },
    hastee: { i: 9263 },
    publishing: { i: 9225 },
    vaccinations: { i: 9201 },
    'access-care-circle': { i: 9205 },
    'overlapping-visits': { i: 9236 },
    rounds: { i: 9220 },
    'carer-agenda': { i: 107814 },
    'schedule-entries-and-bookings': { i: 9171 },
    'client-agenda': { i: 103709 },
    'split-billing': { i: 18543 },
    'service-contract-rounding-precision': { i: 19003 },
    'custom-fields-dropdown': { i: 19226 },
    'controcc-east-sussex': { i: 19232 },
    'controcc-redbridge': { i: 43790 },
    'controcc-oxfordshire': { i: 44732 },
    'controcc-bury': { i: 9257 },
    'dynamics-gp': { i: 22081 },
    'dynamics-gp-export': { i: 22083 },
    'training-matrix': { i: 56535 },
    'tea-roulette': { i: 58284 },
    'mail-opt-outs:manage': { i: 73232 },
    'notification-groups': { i: 9145 },
    'body-maps': { i: 93039 },
    teams: { i: 9189 },
    'global-buyer': { i: 28009 },
    'global-contact': { i: 9138 },
    'prsb-about-me': { i: 154255 },
    'bulk-update': { i: 124873 },
    'web-push': { i: 204719 },

    'formulation': { i: 146811 },
    'additional-support-plan': { i: 146809 },
    'contingency-plan': { i: 146808 },
    'care-plan': { i: 146807 },
    'advance-statement': { i: 146810 },

    'gp-connect-getting-started': { i: 221538 },
    'gp-connect-access-levels': { i: 221734 },
    'gp-connect-sections': { i: 221742 },
};
/* eslint-enable @typescript-eslint/naming-convention */
