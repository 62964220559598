import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
    name: 'arrayFilter',
    pure: false
}) export class ArrayFilterPipe implements PipeTransform {
    transform(value: any[], filterFn: (a: any) => boolean) {
        return value?.length ? value.filter((a => filterFn(a))) : [];
    }
}
