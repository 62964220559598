import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-loading-label',
    templateUrl: './loading-label.component.html',
    styleUrls: ['./loading-label.component.scss']
})
export class LoadingLabelComponent {
    @Input() loading: boolean;
    @Input() diameter = 24;
    @Input() label: string;
}
