import { isPresent } from '@shared/utils/helpers';
import { differenceInWeeks, distanceInWordsToNow, endOfDay as _endOfDay, isSameDay as _isSameDay, isSameMonth as _isSameMonth, isSameYear as _isSameYear, isToday as _isToday, isTomorrow as _isTomorrow, isYesterday as _isYesterday, parse, startOfDay, } from 'date-fns';
import type { DurationObjectUnits, DurationUnit } from 'luxon';
import { DateTime, Interval } from 'luxon';
import type { DateObjectUnits } from 'luxon/src/datetime';
import { roundToNearest } from './math';
import { pad } from './strings';

export const DATE_SHORT_FORMAT = 'DD';
export const DATE_FORMAT = 'EEE DD';
export const DATE_WITHOUT_YEAR_FORMAT = 'EEE d LLL';
export const DATE_MONTH_FORMAT = 'd LLL';
export const DATE_MONTH_YEAR_FORMAT = 'd LLL yyyy';
export const TIMESTAMP_FORMAT = 'T, EEE DD';
export const TIMESTAMP_SECONDS_FORMAT = 'HH:mm:ss, EEE DD';
export const TIME_FORMAT = 'HH:mm';

export const EPOCH = new Date(0);

export const todayDateTime = DateTime.local().startOf('day');
export const startOfWeekDateTime = todayDateTime.startOf('week');
export const endOfDayDateTime = todayDateTime.endOf('week');
export const startOfMonthDateTime = todayDateTime.startOf('month');
export const endOfMonthDateTime = todayDateTime.endOf('month');
export const startOfYearDateTime = todayDateTime.startOf('year');
export const endOfYearDateTime = todayDateTime.endOf('year');

export function toDateTime(value: string | DateTime | Date, isUtc = true): DateTime | null {
    if (!value) {
        return null;
    }

    if (typeof value === 'string' && value.length === 'YYYY-MM-DD'.length) {
        return DateTime.fromISO(value, {
            zone: undefined,
        })
    }

    if (value instanceof Date) {
        return DateTime.fromJSDate(value, {
            zone: isUtc ? 'utc' : undefined,
        });
    }

    if (value instanceof DateTime) {
        if (!value.isValid) {
            return null;
        }

        return value;
    }

    if (value) {
        let dateTime = DateTime.fromSQL(value, {
            zone: isUtc ? 'utc' : undefined,
        });

        if (!dateTime.isValid) {
            dateTime = DateTime.fromISO(value);
        }

        if (!dateTime.isValid) {
            console.warn(`Invalid DateTime instance: ${dateTime.invalidReason}, ${dateTime.invalidExplanation}`);
        }

        return dateTime;
    }

    return null;
}

export function weeksSinceEpoch(instance: DateTime): number {
    return differenceInWeeks(instance.toJSDate(), EPOCH);
}

/**
 * Format the given moment in a readable format.
 */
export function formatReadableDate(date: DateTime, today = DateTime.local().startOf('day')): string {
    if (!date) {
        return;
    }

    const formatted = date.toFormat(DATE_FORMAT);

    const diffDays = date.startOf('day').diff(today.startOf('day'), 'days').days;

    switch (diffDays) {
        case -1:
            return `Yesterday (${formatted})`;

        case 0:
            return `Today (${formatted})`;

        case 1:
            return `Tomorrow (${formatted})`;
    }

    return formatted;
}

/**
 * Format the given moment in a readable format.
 */
export function dateTimeReadable(date: DateTime, today = DateTime.local()): string {
    if (!date) {
        return;
    }

    let dateFormat;
    const diffDays = today.startOf('day').diff(date.startOf('day'), 'days').days;

    switch (diffDays) {
        case -1:
            dateFormat = '\'Yesterday\'';
            break;
        case 0:
            dateFormat = '\'Today\'';
            break;
        case 1:
            dateFormat = '\'Tomorrow\'';
            break;
        default:
            dateFormat = isSameYear(date, today) ? DATE_SHORT_FORMAT : DATE_FORMAT;
    }

    return date.toFormat(`HH:mm, ${dateFormat}`);
}


/**
 * Transform the given decimal representation of hours into seconds.
 *
 * @param value
 * @returns
 */
export function decimalHoursToSeconds(value: string | number): number {
    if (typeof value === 'string') {
        value = parseFloat(value);
    }

    if (isNaN(value)) {
        return null;
    }

    return Math.floor(value * 3600);
}

export function decimalHoursToDateObject(value: number): DateObjectUnits {
    const hour = Math.floor(value);
    const minute = (value % 1) * 60;

    return {
        hour,
        minute,
    };
}

/**
 * Takes a decimal and returns a time string. E.g. 1.5 -> 1:30
 *
 * @param input
 * @param roundToMinutes
 * @return
 */
export function decimalHoursToHoursMinutes(input: number, roundToMinutes = 0): string {
    let hours = Math.floor(input);
    let minutes = (input % 1) * 60;

    if (roundToMinutes) {
        minutes = Math.min(60, roundToNearest(minutes, roundToMinutes));
    }

    if (minutes === 60) {
        hours += 1;
        minutes = 0;
    }

    return `${pad(hours, 2)}:${pad(minutes, 2)}`;
}

/**
 * Check whether the specified event falls on the given date.
 *
 * @param date
 * @param eventStart
 * @param eventFinish
 * @returns
 */
export function fallsOnDate(date: Date, eventStart: Date, eventFinish: Date): boolean {
    const dateStartMillis = startOfDay(date).valueOf();
    const dateFinishMillis = _endOfDay(date).valueOf();

    const startMillis = eventStart.valueOf();
    const finishMillis = eventFinish.valueOf();

    return intercepts([dateStartMillis, dateFinishMillis], [startMillis, finishMillis]);
}

/**
 * Takes two arrays containing the start and finish times in seconds.
 */
export function intercepts(first: [number, number], second: [number, number], allowEqualTo = false): boolean {
    return allowEqualTo
        ? !(second[1] <= first[0] || second[0] >= first[1])
        : !(second[1] < first[0] || second[0] > first[1]);
}

/**
 * Check if the given value is a date (without time).
 *
 * @param value
 * @returns
 */
export function isDateString(value: string): boolean {
    return /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/.test(value);
}

/**
 * Converts HH:mm to a decimal representation of hours.
 */
export function hoursMinutesToDecimal(value: string): number {
    const seconds = hoursMinutesToSeconds(value);

    return seconds !== null ? seconds / 3600 : null;
}

/**
 * Transform the given HH:mm string into seconds.
 *
 * @param value
 * @returns
 */
export function hoursMinutesToSeconds(value: string): number {
    if (value.indexOf(':') === -1) {
        return null;
    }

    const parts = value.split(':');
    const hours = parseInt(parts[0], 10) || 0;
    const minutes = parseInt(parts[1], 10) || 0;

    return Math.floor(hours * 3600 + minutes * 60);
}

/**
 * Transform the given HH:mm string into seconds.
 *
 * @param value
 * @returns
 */
export function hoursMinutesSecondsToSeconds(value: string): number {
    if (value.indexOf(':') === -1) {
        return null;
    }

    const parts = value.split(':');
    const hours = parseInt(parts[0], 10) || 0;
    const minutes = parseInt(parts[1], 10) || 0;
    const seconds = parseInt(parts[2], 10) || 0;

    return Math.floor((hours * 3600) + (minutes * 60) + seconds);
}

/**
 * Convert minutes into a readable string.
 *
 * @param value
 * @returns
 */
export function minutesToReadable(value: number): string {
    if (value === null) {
        return null;
    }

    return secondsToReadable(value * 60);
}

/**
 * Convert minutes into a readable string.
 *
 * @param value
 * @returns
 */
export function minutesToShortReadable(value: number): string {
    return secondsToShortReadable(value * 60);
}

/**
 * Convert seconds into a readable string.
 *
 * @param value
 * @returns
 */
export function secondsToHoursMinutes(value: number): string {
    if (!isPresent(value)) {
        return null;
    }

    // Value should be in seconds
    const hours = Math.floor(value / 3600);
    value %= 3600;
    const minutes = Math.floor(value / 60);

    return `${hours}:${pad(minutes)}`;
}

/**
 * Convert seconds into a readable string.
 *
 * @param value
 * @returns
 */
export function secondsToHoursMinutesSeconds(value: number): string {
    if (!isPresent(value)) {
        return null;
    }

    // Value should be in seconds
    const hours = Math.floor(value / 3600);
    value %= 3600;
    const minutes = Math.floor(value / 60);
    value %= 60;
    const seconds = value;

    return `${hours}:${pad(minutes)}:${pad(seconds)}`;
}

/**
 * Convert seconds into a readable string.
 */
export function secondsToReadable(value: number, defaultString: string = '0m'): string {
    if (!isPresent(value)) {
        return undefined;
    }

    if (isNaN(value) || value === 0) {
        return defaultString;
    }

    const isNegative = value < 0;

    value = Math.abs(value);

    // Value should be in seconds
    const hours = Math.floor(value / 3600);
    value %= 3600;
    const minutes = Math.floor(value / 60);
    value %= 60;
    const seconds = value;

    const parts = [];

    if (hours > 0) {
        parts.push(`${hours}h`);

        if (minutes > 0) {
            parts.push(`${minutes}m`);
        }
    } else if (minutes > 0) {
        parts.push(`${minutes}m`);
        if (seconds > 0) {
            parts.push(`${seconds}s`);
        }
    } else {
        parts.push(`${seconds}s`);
    }

    return (isNegative ? '-' : '') + parts.join(' ');
}

/**
 * Convert seconds into a readable string.
 */
export function secondsToReadablePrecise(value: number, defaultString: string = '0m'): string {
    if (!isPresent(value)) {
        return undefined;
    }

    if (isNaN(value) || value === 0) {
        return defaultString;
    }

    const isNegative = value < 0;

    value = Math.abs(value);

    // Value should be in seconds
    const hours = Math.floor(value / 3600);
    value %= 3600;
    const minutes = Math.floor(value / 60);
    value %= 60;
    const seconds = value;

    const parts = [];

    if (hours > 0) {
        parts.push(`${hours}h`);
    }
    if (minutes > 0) {
        parts.push(`${minutes}m`);
    }
    if (seconds > 0) {
        parts.push(`${seconds}s`);
    }

    return (isNegative ? '-' : '') + parts.join(' ');
}

/**
 * Convert seconds into a readable string.
 *
 * @param value
 * @returns
 */
export function secondsToShortReadable(value: number): string {
    if (!value) {
        return '0m';
    }

    // Value should be in seconds
    const hours = Math.floor(value / 3600);
    value %= 3600;
    const minutes = Math.floor(value / 60);
    const parts = [];

    if (hours > 0) {
        parts.push(`${hours}h`);

        if (minutes > 0) {
            parts.push(`${pad(minutes, 2)}m`);
        }
    } else if (minutes > 0) {
        parts.push(`${minutes}m`);
    }

    return parts.join(' ');
}

export function toSqlDate(value: DateTime | Date | string, convertToUtc = true): string {
    if (!value) {
        return undefined;
    }

    if (value instanceof DateTime) {
        if (convertToUtc) {
            return value.toUTC().toSQLDate();
        }

        return value.toSQLDate();
    }

    value = parse(value);

    if (convertToUtc) {
        value = toUtc(value);
    }

    return DateTime.fromJSDate(value).toSQLDate();
}

export function toUtc(date: Date): Date {
    return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
    );
}

export function toSqlDateTime(value: Date | string, convertToUtc = true): string {
    if (!value) {
        return undefined;
    }

    value = parse(value);

    if (convertToUtc) {
        value = toUtc(value);
    }

    return DateTime.fromJSDate(value).toSQL();
}

export function toIso8601(value: Date | string, convertToUtc = true): string {
    if (!value) {
        return undefined;
    }

    value = parse(value);

    if (convertToUtc) {
        value = toUtc(value);
    }

    return value.toISOString();
}

// @ts-ignore
const units: Intl.RelativeTimeFormatUnit[] = [
    'year',
    'month',
    'week',
    'day',
    'hour',
    'minute',
    'second',
];

export function timeAgo(dateTime: DateTime) {
    if ('RelativeTimeFormat' in Intl) {
        const diff = dateTime.diffNow().shiftTo(...units);
        const unit = units.find(u => diff.get(u) !== 0) || 'second';

        // @ts-ignore
        const relativeFormatter = new Intl.RelativeTimeFormat('en', {
            numeric: 'always',
            style: 'narrow',
        });

        return relativeFormatter.format(
            Math.trunc(diff.as(unit)),
            unit
        );
    }

    return dayRelative(dateTime) || (distanceInWordsToNow(dateTime.toJSDate()) + 'ago');
}

export function dayRelative(date: DateTime, reference = DateTime.local().startOf('day')): string {
    const diffDays = date.startOf('day').diff(reference, 'days').days;

    switch (diffDays) {
        case -1:
            return 'Yesterday';
        case 0:
            return 'Today';
        case 1:
            return 'Tomorrow';
    }

    return null;
}

export const toSqlFormat = toSqlDateTime;

export function isSameOrBefore(a: DateTime, b: DateTime, unit?: DurationUnit) {
    if (unit) {
        a = a.startOf(unit);
        b = b.startOf(unit);
    }

    return a.valueOf() <= b.valueOf();
}

export function isSameOrAfter(a: DateTime, b: DateTime, unit?: DurationUnit) {
    if (unit) {
        a = a.startOf(unit);
        b = b.startOf(unit);
    }

    return a.valueOf() >= b.valueOf();
}

export function isBefore(a: Date | DateTime, b: Date | DateTime) {
    return a.valueOf() < b.valueOf();
}

export function isBeforeNow(date: Date | DateTime): boolean {
    return date.valueOf() < new Date().valueOf();
}

export function isAfter(a: Date | DateTime, b: Date | DateTime) {
    return a.valueOf() > b.valueOf();
}

export function isAfterNow(date: Date | DateTime): boolean {
    return date.valueOf() > new Date().valueOf();
}

export function isSameDay(a: Date | DateTime, b: Date | DateTime) {
    return _isSameDay(toJSDate(a), toJSDate(b));
}

export function isSameMonth(a: Date | DateTime, b: Date | DateTime) {
    return _isSameMonth(toJSDate(a), toJSDate(b));
}

export function isSameYear(a: Date | DateTime, b: Date | DateTime) {
    return _isSameYear(toJSDate(a), toJSDate(b));
}

export function isToday(a: Date | DateTime) {
    return _isToday(toJSDate(a));
}

export function isTomorrow(a: Date | DateTime) {
    return _isTomorrow(toJSDate(a));
}

export function isYesterday(a: Date | DateTime) {
    return _isYesterday(toJSDate(a));
}

function toJSDate(value: Date | DateTime): Date {
    if (value instanceof DateTime) {
        return value.toJSDate();
    }

    return value;
}

export function formatDate(value: DateTime, local = true, format?: string): string {
    if (!(value instanceof DateTime)) {
        return value;
    }

    if (local) {
        value = value.toLocal();
    }

    if (format) {
        return value.toFormat(format);
    }

    if (value.year === todayDateTime.year) {
        return value.toFormat(DATE_WITHOUT_YEAR_FORMAT);
    }

    return value.toFormat(DATE_FORMAT);
}

export function dateWithOrdinal(number: number, withHTMLSup: boolean = false): string {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    const ordinal = (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
    return number + (withHTMLSup ? `<sup>${ordinal}</sup>` : ordinal);
}

export function nextBirthdayFormatDate(value: DateTime, local = true, withHTMLSup = false): string {
    if (!(value instanceof DateTime)) {
        return value;
    }

    if (local) {
        value = value.toLocal();
    }
    return value.toFormat("EEE ") + dateWithOrdinal(value.day, withHTMLSup) + value.toFormat(" LLL");
}

/**
 * @param value
 * @param local
 * @return
 */
export function formatDateWithYear(value: DateTime, local = true): string {
    if (!(value instanceof DateTime)) {
        return value;
    }

    if (local) {
        value = value.toLocal();
    }

    return value.toFormat(DATE_FORMAT);
}


/**
 * @param value
 * @param local
 * @return
 */
export function formatDateShort(value: DateTime, local = true) {
    if (!value) {
        return;
    }

    if (local) {
        value = value.toLocal();
    }

    return value.toFormat(DATE_SHORT_FORMAT);
}

export function formatTime(value: DateTime, local = true): string {
    if (!(value instanceof DateTime)) {
        return value;
    }

    if (local) {
        value = value.toLocal();
    }

    return value.toFormat('HH:mm');
}

export function formatTimestamp(value: DateTime, local = true, format?: string): string {
    if (!value) {
        return null;
    }

    if (local) {
        value = value.toLocal();
    }

    return value.toFormat(format ?? TIMESTAMP_FORMAT);
}

/**
 * Whether the given start and finish fall on dayMoment.
 */
export function fallsOnDay(date: DateTime, start: DateTime, finish: DateTime): boolean {
    return isSameDay(date, start) || isSameDay(date, finish) || Interval.fromDateTimes(start, finish).contains(date);
}

export function parseDurationObjectFromString(value: string): DurationObjectUnits {
    const object: DurationObjectUnits = {};
    const params = value.split('|');

    const allowedIntervals = [
        'year',
        'years',
        'quarter',
        'quarters',
        'month',
        'months',
        'week',
        'weeks',
        'day',
        'days',
        'hour',
        'hours',
        'minute',
        'minutes',
        'second',
        'seconds',
        'millisecond',
        'milliseconds',
    ];

    params.forEach(p => {
        const [interval, v] = p.split(':');

        if (allowedIntervals.includes(interval)) {
            object[interval] = parseInt(v, 10);
        } else {
            console.warn('Unsupported interval', interval, v);
        }
    });

    return object;
}

export function createDates(from: DateTime, days: number): DateTime[] {
    const dates = [];

    for (let i = 0; i < days; i++) {
        dates.push(from.plus({ days: i }));
    }

    return dates;
}

/**
 * Gets the actual number of hours in the given day.
 */
export function getHoursInDay(date: DateTime): number {
    const endOfDay = date.endOf('day');

    return endOfDay.diff(endOfDay.minus({ days: 1 }), 'hours').hours;
}


export function isDstStartDate(date: DateTime): boolean {
    // Check if the day is shorter, because the clocks have gone backward.
    return getDateDstDelta(date) < 0;
}

export function isDstEndDate(date: DateTime): boolean {
    // Check if the day is longer
    return getDateDstDelta(date) > 0;
}

/**
 * Gets the Daylight Saving Time (DST) delta for the given date; how many hours difference are there on the given date?
 */
export function getDateDstDelta(date: DateTime): number {
    const endOfDay = date.endOf('day');
    const hoursInDay = endOfDay.diff(endOfDay.minus({ days: 1 }), 'hours').hours;

    return hoursInDay - 24;
}
