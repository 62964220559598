import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SpinnerComponent } from './spinner.component';

@NgModule({
    imports: [CommonModule, MatProgressSpinnerModule],
    declarations: [SpinnerComponent],
    exports: [CommonModule, MatProgressSpinnerModule, SpinnerComponent]
})
export class AppSpinnerModule {

}
