import type { SpecialDay } from '@shared/modules/special-days/special-day';
import { Presenter } from '@shared/presenters/presenter';
import { DATE_MONTH_FORMAT, DATE_MONTH_YEAR_FORMAT } from '@shared/utils/date-time';
import type { DateTime } from 'luxon';

export class SpecialDayPresenter extends Presenter<SpecialDay> {
    get date(): DateTime {
        return this.toDateTime('date', false);
    }

    get isRecurring(): boolean {
        return this.data.date_accuracy === 'day_month';
    }

    get when(): string {
        return this._get('when', () => {
            switch (this.get('date_accuracy')) {
                case 'day_month':
                    return this.formatDate(this.date, false, DATE_MONTH_FORMAT);

                default:
                    return this.formatDate(this.date, false, DATE_MONTH_YEAR_FORMAT);
            }
        });
    }
}
