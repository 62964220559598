import type { Model } from '@shared/models/model';

export interface Condition {
    source: any;
    operator: any;
    value: ConditionValue;
    value_options: {
        [prop: string]: any;
    };
}

export enum ConditionValue {
    specialDay = 'special_day',
    publicHoliday = 'public_holiday',
}

export const CONDITION_VALUE_OPTIONS = Object.freeze([
    {
        label: 'Special Day',
        value: ConditionValue.specialDay
    },
    {
        label: 'Public Holiday',
        value: ConditionValue.publicHoliday
    },
]);

export interface Action {
    source: any;
    operator: any;
    value: any;
    value_options: {
        [prop: string]: any;
    };
}

export interface Rule {
    name: string;
    actions: Action[];
    conditions: Condition[];
    pay_element_code?: string;
}

export interface FixedRateOwner {
    fixed_rates?: { data: FixedRate[] };
    default_fixed_rate_id?: string;
}

export interface FixedRate extends Model {
    name: string;
    amount: number;

    rules?: Rule[];

    is_default?: boolean;
    pay_element_code?: string;

    [prop: string]: any;
}
