<app-auth-form-card
    [title]="'Password reset request'"
    [subTitle]="'Submitted successfully'"
    [showHelpContact]="false">
    <cll-panel colour="primary">
        <cll-icon cllPanelIcon>info</cll-icon>
        Password reset request successfully submitted. If these details align with a registered account, an email will
        be sent out shortly.
    </cll-panel>
    <button
        class="login-form-card__back"
        cll-filled-button
        colour="secondary"
        [routerLink]="['/auth/login']">
        Back to login
    </button>
</app-auth-form-card>
