/**
 * Fetcher
 *
 * Base Fetcher class.
 *
 * @author Dec Norton <decnorton@gmail.com>
 */

import type { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import get from 'lodash-es/get';
import isObject from 'lodash-es/isObject';
import type { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import type { PaginatorConfig } from '../pagination/paginator';
import { Paginator } from '../pagination/paginator';
import { AppErrorHandler } from '../services/error-handler/app-error-handler.service';
import { AppEvents, AppEventSource } from '../services/event-emitter/app-events.service';
import type { ApiParams } from './api-params';
import type { ApiResponse } from './responses/api-response';

const DEFAULT_HEADERS = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Accept: 'application/json',
};

type ResourceKey = string | number;

@Injectable({ providedIn: 'root' })
export abstract class Fetcher<TModel = any,
    TCreateBody = Record<string, any> | FormData,
    TUpdateBody = Partial<TModel>,
    TPatchBody = TUpdateBody> {
    protected apiUrl = '/api/v1';
    protected _registeredEvents = [];

    abstract readonly modelName: string;

    constructor(protected errorHandler: AppErrorHandler,
                protected events: AppEvents,
                protected http: HttpClient,
    ) {
        this._registerEventType('create');
        this._registerEventType('delete');
        this._registerEventType('update');
        this._registerEventType('patch');
        this._registerEventType('create_or_update');

        this.init();
    }

    abstract get resourceName(): string;

    public clone(replace: { [p in keyof this]?: any } = {}): this {
        const instance = new (this.constructor as any)(
            this.errorHandler,
            this.events,
            this.http
        );

        // Overwrite the given keys
        for (const key in replace) {
            if (key in replace) {
                instance[key] = replace[key];
            }
        }

        return instance;
    }

    get subresourceName(): string {
        return null;
    }

    protected _registerEventType(type, { success = true, error = true, source = AppEventSource.global } = {}) {
        if (success) {
            const eventName = this.eventName(type, success);

            this.events.registerEventType(eventName, {
                source,
            });

            this._registeredEvents.push(eventName);
        }

        if (error) {
            const eventName = this.eventName(type, false);

            this.events.registerEventType(eventName, {
                source,
            });

            this._registeredEvents.push(eventName);
        }
    }

    /**
     * @return
     */
    get registeredEvents() {
        return [...this._registeredEvents];
    }

    /**
     * @return
     */
    get registeredSuccessEvents() {
        return this._registeredEvents.filter(event => event.slice(-8) === '_success');
    }

    get successEvents() {
        return this.events.name(this.registeredSuccessEvents);
    }

    /**
     * @param type
     * @param data
     * @param success
     * @protected
     */
    protected _broadcast(type: string, data, { success = true } = {}) {
        this.events.broadcast(this.eventName(type, success), {
            model: this.modelName,
            resource: this.resourceName,
            id: get(data, 'data.id', get(data, 'id')),
            type,
            data,
        });
    }

    /**
     * @param type
     * @param data
     * @protected
     */
    protected _broadcastError(type, data) {
        return this._broadcast(type, data, { success: false });
    }

    /**
     * Used for any initialisation without overriding the constructor
     * which requires injection.
     */
    init() {
    }

    /**
     * @param type
     * @param success
     * @return
     */
    eventName(type, success = true) {
        return `${this.resourceName}:${type}_${success ? 'success' : 'error'}`;
    }

    /**
     * @param key
     * @param append
     * @return
     * @protected
     */
    _getUrl(key?: ResourceKey, ...append: (ResourceKey)[]): string {
        return `${this.apiUrl}/${this.resourceName}`
            + (key ? `/${key}` : '')
            + (append?.length ? `/${append.join('/')}` : '');
    }

    // _delete<E>(url, body, options) {
    //     const headers = get(options, 'headers');
    //     const timeout = get(options, 'timeout');
    //
    //     this._transformOptions(options);
    //
    //     return this.http.request<ApiResponse<E>>('delete', url, {
    //         // cache: false,
    //         // timeout,
    //         headers: _buildHeaders(headers),
    //         params: options,
    //         body,
    //     })
    //         .pipe(map(response => response.data))
    //         .pipe(onErrorResumeNext((response: HttpErrorResponse) => {
    //             return this._transformError(response);
    //         }));
    // }

    _get<T = TModel>(url, options = {}): Observable<ApiResponse<T>> {
        const headers = get(options, 'headers');

        return this.http.get<ApiResponse<T>>(url, {
            headers: buildHeaders(headers),
            params: this._transformOptions(options),
        });
    }

    _delete<R = void>(url, options): Observable<R> {
        const headers = get(options, 'headers');

        return this.http.delete<R>(url, {
            headers: buildHeaders(headers),
            params: this._transformOptions(options),
        });
    }

    _post<T = TModel>(url: string, body: any, options: ApiParams = {}): Observable<ApiResponse<T>> {
        const headers = get(options, 'headers');

        return this.http.post<ApiResponse<T>>(url, body, {
            headers: buildHeaders(headers),
            params: this._transformOptions(options),
        });
    }

    _patch<T = TModel>(url: string, body: any = {}, options: ApiParams = {}): Observable<ApiResponse<T>> {
        const headers = get(options, 'headers');

        return this.http.patch<ApiResponse<T>>(url, body, {
            headers: buildHeaders(headers),
            params: this._transformOptions(options),
        });
    }

    _put<T = TModel>(url: string, body: any, options: ApiParams = {}): Observable<ApiResponse<T>> {
        const headers = buildHeaders(get(options, 'headers'));

        if (body instanceof FormData) {
            delete headers['Content-Type'];

            body.append('_method', 'PUT');

            return this.http.post<ApiResponse<T>>(url, body, {
                headers,
                params: this._transformOptions(options),
            });
        }

        return this.http.put<ApiResponse<T>>(url, body, {
            headers,
            params: this._transformOptions(options),
        });
    }

    _transformOptions(options: any) {
        if (isObject(options)) {
            // Convert to CSV if array
            Object.keys(options).forEach(key => {
                const value = options[key];

                if (Array.isArray(value)) {
                    options[key] = value.join(',');
                }

                if (value instanceof Set) {
                    options[key] = Array.from(value as any);
                }

                if (value === null || value === undefined) {
                    options[key] = '';
                }
            });

            delete options['headers'];
            delete options['timeout'];
            delete options['cache'];
        }

        return options;
    }

    all<T = TModel, P = ApiParams>(params: P = undefined): Observable<ApiResponse<T[]>> {
        const url = this._getUrl();

        return this._get<T[]>(url, {
            ...(params ?? {}),
            all: true
        });
    }

    /**
     * Used when the query params for a request are expected to exceed the maximum allowed length of a URL (2048 characters).
     */
    allPost<T = TModel>(body: ApiParams = undefined, params: ApiParams = {}): Observable<ApiResponse<T[]>> {
        const url = this._getUrl();

        return this._post<T[]>(url, {
            _method: 'GET',
            ...body
        }, {
            all: true,
            ...params,
        });
    }

    count(params: ApiParams = {}): Observable<number> {
        return this._get<{
            value: number
        }>(this._getUrl('count'), params)
            .pipe(
                map(r => r.data.value),
            );
    }

    create<T = TModel>(body: TCreateBody, params: ApiParams = {}): Observable<T> {
        return this.rawCreate<T>(body, params).pipe(
            map(r => r.data),
        );
    }

    rawCreate<T = TModel>(body: TCreateBody, params: ApiParams = {}): Observable<ApiResponse<T>> {
        return this._post<T>(this._getUrl(), body, params)
            .pipe(this.tapAndBroadcast('create'));
    }

    /**
     * DELETE requests normally respond with 204 No Content
     *
     * @param id
     * @param params
     */
    delete<R = void>(id: string, params: ApiParams = {}): Observable<R> {
        return this._delete<R>(this._getUrl(id), params)
            .pipe(
                this.tapAndBroadcast('delete'),
            );
    }

    deleteWithPassword<R = void>(id: ResourceKey, body: {
        password: string
    }): Observable<R> {
        return this.http.post<R>(this._getUrl(id), {
            _method: 'DELETE',
            ...body,
        }, {
            headers: buildHeaders(),
        }).pipe(
            this.tapAndBroadcast('delete'),
        );
    }

    restore<T = TModel>(key: ResourceKey, params: ApiParams = {}): Observable<T> {
        if (!key) {
            return throwError(() => new Error('Missing key'));
        }

        return this._put<T>(`${this._getUrl(key)}/restore`, params).pipe(map(response => response.data));
    }

    // delete(key, body, params = {}) {
    //     if (!key) {
    //         return this.$q.reject('Missing key');
    //     }
    //
    //     return this._delete(this._getUrl(key), body, params)
    //         .then(data => {
    //             this.clearCache();
    //
    //             this._broadcast('delete', {
    //                 ...data,
    //                 id: key,
    //             });
    //
    //             return data;
    //         }, error => {
    //             this._broadcastError('delete', error);
    //
    //             return this.$q.reject(error);
    //         });
    // }

    get<T = TModel>(key: ResourceKey, params: ApiParams = {}): Observable<T> {
        return this.rawGet<T>(key, params).pipe(
            map(response => response.data)
        );
    }

    rawGet<T = TModel>(key: ResourceKey, params: ApiParams = {}): Observable<ApiResponse<T>> {
        if (!key) {
            return throwError(() => new Error('Missing key'));
        }

        return this._get<T>(this._getUrl(key), params);
    }

    update<T = TModel, P = TUpdateBody | FormData>(key: ResourceKey, body: P, params: ApiParams = {}): Observable<T> {
        return this.rawUpdate<T, P>(key, body, params)
            .pipe(map(response => response.data));
    }

    rawUpdate<T = TModel, P = Partial<TModel>>(key: ResourceKey, body: P, params: ApiParams = {}): Observable<ApiResponse<T>> {
        if (!key) {
            return throwError(new Error('Missing key'));
        }

        return this._put<T>(this._getUrl(key), body, params);
    }

    paginate(params: ApiParams = {}) {
        return this._get<TModel[]>(this._getUrl(), params);
    }

    paginatePost(body: Record<string, unknown> | undefined, params: ApiParams = {}) {
        return this._post<TModel[]>(this._getUrl(), {
            _method: 'GET',
            ...body
        }, {
            ...params
        });
    }

    paginator(config: PaginatorConfig<TModel>): Paginator<TModel> {
        return new Paginator<TModel>(config, this.errorHandler);
    }

    patch<T = TModel, Body = TPatchBody>(key: ResourceKey, body: Body, params: ApiParams = {}): Observable<T> {
        return this.patchResponse<T, Body>(key, body, params).pipe(map(response => response.data));
    }

    patchResponse<T = TModel, Body = TPatchBody>(key, body: Body, params: ApiParams = {}): Observable<ApiResponse<T>> {
        if (!key) {
            return throwError(new Error('Missing key'));
        }

        return this._patch<T>(this._getUrl(key), body, params);
    }

    tapAndBroadcast(eventType: string): <T>(source: Observable<T>) => Observable<T> {
        return <T>(source: Observable<T>) => source.pipe(
            tap(
                data => {
                    this._broadcast(eventType, data);
                },
                error => {
                    this._broadcastError(eventType, error);
                },
            ),
        );
    }
}

export function buildHeaders(headers = {}): HttpHeaders | {
    [header: string]: string | string[]
} {
    if (headers) {
        return {
            ...DEFAULT_HEADERS,
            ...headers,
        };
    }

    return {
        ...DEFAULT_HEADERS,
    };
}
