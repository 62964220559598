import type { ClientScheduleEntry } from '@shared/modules/client-schedule-entries/client-schedule-entry';
import type { ClientTaskScheduleEntry } from '@shared/modules/client-task-schedule-entries/client-task-schedule-entry';
import type { ScheduledDay } from '@shared/modules/client-task-schedule-entries/client-task-schedule-entry.presenter';
import type { ScheduleOptions } from '@shared/modules/clients/client';
import { toDateTime } from '@shared/utils/date-time';
import { orderBy } from 'lodash-es';
import { DateTime } from 'luxon';

export const EPOCH = DateTime.fromMillis(0);
export const DEFAULT_STARTS_FROM = '2018-01-01';
export const DEFAULT_WEEKS = 1;
export const DAY_LABELS = Object.freeze([
    { label: 'Monday', value: 0 },
    { label: 'Tuesday', value: 1 },
    { label: 'Wednesday', value: 2 },
    { label: 'Thursday', value: 3 },
    { label: 'Friday', value: 4 },
    { label: 'Saturday', value: 5 },
    { label: 'Sunday', value: 6 },
]);

/* eslint-disable @typescript-eslint/naming-convention */
export const DAYS: { [prop: number]: string } = Object.freeze({
    0: 'Monday',
    1: 'Tuesday',
    2: 'Wednesday',
    3: 'Thursday',
    4: 'Friday',
    5: 'Saturday',
    6: 'Sunday',
});
/* eslint-enable @typescript-eslint/naming-convention */

export function buildScheduleDays(task: ClientTaskScheduleEntry | ClientScheduleEntry, numberOfWeeks: number): ScheduledDay[] {
    if (!task) {
        return [];
    }

    const result = new Array(numberOfWeeks).fill(undefined).flatMap((value, week) => {
        const dayKeys = Object.keys(DAYS).map(k => parseInt(k, 10));

        return dayKeys.map((d: number) => {
            const day: string = DAYS[d];

            return {
                day: d,
                week,
                label: `${day.charAt(0)}`,
                tooltip: `${day}, week ${week + 1}`,
                enabled: task.week.includes(week) && task.day.includes(d)
            };
        });
    });

    return orderBy(result, ['week', 'day'], ['asc', 'asc']);
}


export function currentWeekOfCycle(scheduleOptions: ScheduleOptions): number {
    const weeks = scheduleOptions?.weeks;
    const startsFrom = scheduleOptions?.starts_from;

    if (weeks === 1) {
        return 1;
    }

    if (!weeks || !startsFrom) {
        return null;
    }

    const scheduleStartsFrom = toDateTime(startsFrom, false);

    const startOfWeek = DateTime.local().startOf('week');

    const epoch = EPOCH.startOf('week');

    const weeks1 = Math.round(startOfWeek.diff(epoch, 'weeks').weeks);
    const weeks2 = Math.round(scheduleStartsFrom.startOf('week').diff(epoch, 'weeks').weeks);

    let weeksDelta = (weeks1 - weeks2) % weeks;

    if (weeksDelta < 0) {
        weeksDelta = weeks + weeksDelta;
    }

    return weeksDelta + 1;
}
