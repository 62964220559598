<app-auth-shell-background/>

<div class="app-content">
    <app-auth-logo />

    <router-outlet />
</div>

<p class="copyright">
    Copyright &copy; 2024 <a href="https://carelinelive.com/" target="_blank">CareLineLive</a>.
    Part of <a href="https://www.mas-group.co.uk/" target="_blank">MAS Group</a>. All rights reserved.
</p>
