import type {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest } from '@angular/common/http';
import {
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import type { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export class AuthHttpInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event.status === 401) {
                        window.location.href = '/';
                    }
                }
            }, error => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {
                        window.location.href = '/';
                    }
                }
            }));
    }

}
