import { Injectable } from '@angular/core';
import type { SpecialDay } from '@shared/modules/special-days/special-day';
import { Fetcher } from '../../http/fetcher';

@Injectable({
    providedIn: 'root',
})
export class SpecialDayFetcher extends Fetcher<SpecialDay> {
    readonly modelName = 'SpecialDay';

    get resourceName(): string {
        return 'special-days';
    }
}
