import { HttpErrorResponse } from '@angular/common/http';
import { ApiError } from '@shared/http/errors/api-error';
import { parseHttpErrorResponse } from '@shared/services/error-handler/parse';
import { isObject } from 'lodash-es';

export function getErrorMessage(error: any): string {
    if (error instanceof HttpErrorResponse) {
        error = parseHttpErrorResponse(error);
    }

    if (error instanceof ApiError) {
        if (error.code === 422) {
            const validationErrorMessages = error.validationErrorMessages;

            if (validationErrorMessages && validationErrorMessages.length > 0) {
                return error.validationErrorMessages.join('\n');
            }

            return error.message;
        }

        return error.message;
    }

    if (error instanceof HttpErrorResponse) {
        error = error.error;
    }

    return ApiError.instanceOf(error) || (isObject(error) && error['message']) ? error['message'] : 'A problem occurred';
}
