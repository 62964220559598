import { Component, Input } from '@angular/core';
import { expandAnimation } from '@shared/common/animations/expandAnimation';
import { scaleInAnimation } from '@shared/common/animations/scaleInAnimation';

@Component({
    selector: 'app-spinner',
    template: `
        <div class="spinner-wrapper" [@scaleIn]>
            <mat-spinner [diameter]="diameter" aria-label="Loading data" />
        </div>
    `,
    styleUrls: ['./spinner.component.scss'],
    animations: [
        scaleInAnimation('0.5s'),
        expandAnimation(),
    ]
})
export class SpinnerComponent {
    @Input() diameter = 24;
}
