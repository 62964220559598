<cll-dialog-layout>
    <cll-dialog-header>
        <cll-dialog-title>MAS Group - Privacy Collection Notice</cll-dialog-title>
    </cll-dialog-header>

    <p>
        In this privacy collection notice, “we”, “us” and “our” refer to MAS Technicae Group (International) Ltd
        (07904826), including all subsidiaries and trading names not limited to CareLineLive, MAS Design Ltd, MAS
        Networks Ltd and MAS Australia Pty Ltd (ABN 92 612 371 945) (together, the <b>MAS Group</b>, <b>we</b> or
        <b>us</b>).
    </p>
    <h4 class="text-title-medium">Collection and Use</h4>
    <p>
        We are committed to managing and protecting your personal information in accordance with our obligations
        under the Privacy Act 1998 (Cth) (<b>Privacy Act</b>) and the Australian Privacy Principles (<b>APPs</b>),
        and in accordance with our
        <a href="https://carelinelive.com/privacy-policy/" target="_blank">Privacy Policy </a>.
    </p>
    <p>
        This Privacy Collection Notice applies to the personal information we may collect from you in connection
        with enabling you to access and use our website and services. The types of personal information we may
        collect, as defined in our Privacy Policy, include:
    </p>

    <ul>
        <li>name;</li>
        <li>address;</li>
        <li>age and/or date of birth;</li>
        <li>gender;</li>
        <li>identification information, such as driver licence details or passport number</li>
        <li>email address;</li>
        <li>telephone number or mobile number;</li>
        <li>occupational and employment details including employment status and any previous work experience;</li>
        <li>
            information provided to us via customer surveys, contained in any enquiry you submit to us regarding
            goods and/or services, or that you post for publication on our website or through our services;
        </li>

        <li>details about the contracts you and/or your employer hold with us;</li>
        <li>photographs or images of you;</li>
        <li>
            the geographical location and referral source from which you have come to our website and the pages you
            have visited including information about the timing, frequency and pattern of your service use;
        </li>

        <li>the length of your visit to our website, page views and navigation paths; and</li>
        <li>
            technical data, which may include IP address, the types of devices you are using to access the Websites,
            device attributes, browser type and version, language and operating system.
        </li>
    </ul>
    <p>
        The primary purpose for collecting this information is for our legitimate business interests to enable you
        to access and use our website and services. We will use your personal information for that purpose, and we
        may also use it to operate, improve and optimise our website and services.
    </p>

    <p>
        We take all reasonable steps to protect the personal information we hold about you against loss and
        unauthorised access, use, modification or disclosure.
    </p>
    <h4 class="text-title-medium">Consequences if you fail to provide personal information</h4>
    <p>
        If you choose not to provide personal information to us, you may be unable to access and use our website and
        services.
    </p>
    <h4 class="text-title-medium">Disclosure of personal information</h4>
    <p>
        We may disclose the personal information to third parties including our service providers for the same
        purposes as we collected it.
    </p>
    <h4 class="text-title-medium">Overseas Disclosure</h4>
    <p>
        As part of a global organisation, we may transfer personal information overseas, or to our third party
        service providers located outside of Australia, for our general business purposes, including cloud and data
        storage services. These service providers are located in the United Kingdom, Australia and Ireland. Were we
        transfer personal information to such third parties, we take reasonable steps to ensure they comply with
        Australian privacy laws.
    </p>
    <h4 class="text-title-medium">Privacy Policy</h4>
    <p>
        Our
        <a
                href="https://carelinelive.com/privacy-policy/"
                target="_blank"
        >Privacy Policy</a
        >. sets out further information:
    </p>
    <ul>
        <li>on how we may collect, use, handle or disclose your personal information;</li>
        <li>about how you may access and seek the correction of your personal information; and</li>
        <li>on how you may complain about a breach of the APPs and how we will deal with such complaints.</li>
    </ul>

    <h4 class="text-title-medium">Contact</h4>
    <p>
        Our Privacy Policy contains information about how you may contact us, access and request correction of your
        personal information, make a complaint about the handling of your personal information and how a privacy
        complaint will be dealt with by us.
    </p>

    <cll-dialog-footer>
        <button cll-filled-button
                cllDialogClose>
            Close
        </button>
    </cll-dialog-footer>
</cll-dialog-layout>
