export enum CarePackageVisibility {
    profile = 'profile',

    clientContact = 'clientContact',
    clientAddress = 'clientAddress',
    clientEmail = 'clientEmail',
    clientPhone = 'clientPhone',

    importantContacts = 'importantContacts',
    importantContactPhone = 'importantContactPhone',
    importantContactAddress = 'importantContactAddress',

    medical = 'medical',
    medication = 'medication',
    tasks = 'tasks',
}

export enum CarePackageVisibilityLabels {
    profile = 'Client profile',

    clientContact = 'Client contact information',
    clientAddress = 'Client address',
    clientEmail = 'Client email',
    clientPhone = 'Client phone numbers',

    importantContacts = 'Important contacts',
    importantContactPhone = 'Important contact phone numbers',
    importantContactAddress = 'Important contact addresses',

    medical = 'Medical information',
    medication = 'Medication',
    tasks = 'Tasks',
}
