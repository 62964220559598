import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormFieldsSharedModule } from '@shared/form-fields/form-fields-shared.module';
import { CarerContractSelectComponent } from './carer-contract-select.component';


@NgModule({
    declarations: [
        CarerContractSelectComponent
    ],
    imports: [
        CommonModule,
        FormFieldsSharedModule
    ],
    exports: [
        CarerContractSelectComponent
    ]
})
export class CarerContractSelectModule {
}
