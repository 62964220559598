import type { OnInit } from '@angular/core';
import { Component, ElementRef, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'app-snackbar-success',
    template: `
        <div>
            <mat-icon *ngIf="icon">{{ icon }}</mat-icon>

            <span data-testid="popup:success-message">{{data.message}}</span>
        </div>

        <div class="mat-simple-snackbar-action" *ngIf="hasAction">
            <button mat-button (click)="action()">{{ data.action }}</button>
        </div>
    `,
    styleUrls: ['./app-success-snackbar.component.scss'],
})
export class AppSuccessSnackbarComponent implements OnInit {
    /** Data that was injected into the snack bar. */
    data: { message: string; action: string; status: string };

    public icon: string;

    constructor(
        private elementRef: ElementRef,
        public snackBarRef: MatSnackBarRef<AppSuccessSnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) data: any) {
        this.data = data;

        switch (this.data.status) {
            case 'success':
                this.icon = 'check';
                break;

            case 'error':
                this.icon = 'error';
                break;
        }
    }

    ngOnInit(): void {
        if (this.data.status) {
            this.elementRef.nativeElement.classList.add(this.data.status);
        }
    }

    /** Performs the action on the snack bar. */
    action(): void {
        this.snackBarRef.dismissWithAction();
    }

    /** If the action button should be shown. */
    get hasAction(): boolean {
        return !!this.data.action;
    }
}
