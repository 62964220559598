import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import type { CurrencyMaskConfig } from './currency-mask.config';
import { CURRENCY_MASK_CONFIG } from './currency-mask.config';
import { CurrencyMaskDirective } from './currency-mask.directive';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [CurrencyMaskDirective],
    exports: [CurrencyMaskDirective]
})
export class CurrencyMaskModule {
    static forRoot(config: CurrencyMaskConfig): ModuleWithProviders<CurrencyMaskModule> {
        return {
            ngModule: CurrencyMaskModule,
            providers: [{
                provide: CURRENCY_MASK_CONFIG,
                useValue: config,
            }]
        };
    }
}
