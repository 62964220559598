import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import type { DialogService } from '@mastechnicaegroup/carelinelive-ui';
import { ComponentsModule } from '@mastechnicaegroup/carelinelive-ui';
import { DialogWidth } from '@shared/dialogs/index';
import { AppSharedModule } from '@shared/shared.module';

@Component({
    selector: 'app-collection-notice-dialog',
    templateUrl: './collection-notice-dialog.component.html',
    styleUrls: ['./collection-notice-dialog.component.scss'],
    imports: [AppSharedModule, ComponentsModule],
    standalone: true,
})
export class CollectionNoticeDialogComponent {
    constructor(
        protected readonly dialogRef: DialogRef<null, CollectionNoticeDialogComponent>
    ) {
    }

    public static show(dialog: DialogService) {
        return dialog.openFullScreen(CollectionNoticeDialogComponent, {
            maxWidth: DialogWidth.medium,
        });
    }
}
