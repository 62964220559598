export enum LoginAttemptResult {
    success = 'success',
    mfaChallenge = 'mfa_challenge',

    invalidCredentials = 'invalid_credentials',
    mfaInvalid = 'mfa_invalid',

    expired = 'expired',

    locked = 'locked',

    // Not used yet
    passwordResetRequired = 'password_reset_required',
}

export interface LoginResponse {
    status: LoginAttemptResult;
    message: string;

    user_id?: string;
    redirect_to?: string;
}
