import type { MatDateFormats } from '@angular/material/core';

export const MAT_LUXON_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'd/L/yyyy',
    },
    display: {
        dateInput: 'd/L/yyyy',
        monthYearLabel: 'LLL yyyy',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'LLL yyyy',
    },
};
