import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InputDialogComponent } from '@shared/dialogs/input/input-dialog.component';

import { makeProvider } from '@shared/form-fields/make-provider';
import type { HourlyRateRange } from '@shared/modules/rates/hourly-rate-range';
import { HourlyRateRangeDayEnum } from '@shared/modules/rates/hourly-rate-range';
import type { NonProRataRateDictionary } from '@shared/modules/rates/non-pro-rata-rate';
import { createNonProRataRateDictionary } from '@shared/modules/rates/non-pro-rata-rate';
import type { SpecialDay } from '@shared/modules/special-days/special-day';
import { SpecialDayFetcher } from '@shared/modules/special-days/special-day.fetcher';
import { SpecialDayPresenter } from '@shared/modules/special-days/special-day.presenter';
import { clone, roundToNearest } from '@shared/utils';
import { equals, isPresent } from '@shared/utils/helpers';
import { sortBy } from 'lodash-es';
import { AppSnackBar } from '../../../services/snackbar/app-snackbar.service';

interface HourlyRateRangeModel extends Omit<HourlyRateRange, 'non_pro_rata_rates'> {
    all_day: boolean;
    non_pro_rata_rates: NonProRataRateDictionary;
}

function buildModel(value: HourlyRateRange[]): HourlyRateRangeModel[] {
    return value.map(range => {
        const allDay = range.start === '00:00'
            && (range.finish === '23:59' || range.finish === '24:00');

        return ({
            ...range,
            all_day: allDay,
            non_pro_rata_rates: createNonProRataRateDictionary(range.non_pro_rata_rates),
        });
    });
}

@Component({
    selector: 'app-hourly-rate-ranges',
    styleUrls: ['./hourly-rate-ranges.component.scss'],
    templateUrl: './hourly-rate-ranges.component.html',
    providers: [makeProvider(HourlyRateRangesComponent)],
})
@UntilDestroy()
export class HourlyRateRangesComponent {
    @Input() appliesFrom: string;

    rates: number[] = [];
    specialDays: (SpecialDay & { rangeValue: { special_day_id: string } })[];

    _ranges: HourlyRateRange[];
    _rangesModel: HourlyRateRangeModel[] = [];

    @Input()
    set ranges(value: HourlyRateRange[]) {
        this._ranges = value;

        if (value) {
            this._rangesModel = buildModel(value);

            this.rates = Array.from(new Set<number>(
                this._rangesModel.flatMap(range => Object.keys(range.non_pro_rata_rates ?? {}).map(i => parseInt(i, 10))),
            ));
        }

        this._updateModelRates();
    }

    @Output() rangesChange = new EventEmitter<HourlyRateRange[]>();

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private dialog: MatDialog,
        private snackBar: AppSnackBar,
        private specialDayFetcher: SpecialDayFetcher,
    ) {
        this.fetchSpecialDays();
    }

    private fetchSpecialDays() {
        this.specialDayFetcher.all().subscribe(response => {
            this.specialDays = SpecialDayPresenter.attach(response.data).map(day => ({
                ...day,
                rangeValue: { special_day_id: day.id },
            }));
        });
    }

    private _updateModelRates() {
        if (this._rangesModel) {
            this._rangesModel.forEach(range => {
                range.non_pro_rata_rates = range.non_pro_rata_rates ?? {};

                this.rates.forEach(rate => {
                    if (!range.non_pro_rata_rates.hasOwnProperty(rate)) {
                        range.non_pro_rata_rates[rate] = {
                            duration: rate,
                            amount: undefined,
                        };
                    }
                });

                Object.keys(range.non_pro_rata_rates).forEach(key => {
                    const duration =  parseInt(key, 10);

                    if (!this.rates.includes(duration)) {
                        delete range.non_pro_rata_rates[duration];
                    }
                });
            });

            this.changeDetectorRef.detectChanges();
        }
    }

    _onModelChanged() {
        const previousRanges = clone(this._ranges);

        this._ranges = this._rangesModel
            .filter(range => range.day && range.start && range.finish)
            .map(range => this._toHourlyRateRange(range));

        const hasChanged = !equals(previousRanges, this._ranges);

        if (hasChanged) {
            if (this._ranges.length) {
                this.rangesChange.emit(this._ranges);
            } else {
                this.rangesChange.emit(null);
            }
        }
    }

    addRate() {
        const lastDuration = this.rates[this.rates.length - 1];

        InputDialogComponent.show(this.dialog, {
            title: 'Add non-pro-rata rate',
            label: 'Rate duration',
            type: 'duration',
            okay: 'Add rate',
            initialValue: roundToNearest(
                (lastDuration ?? 0) + 900,
                900,
            ),
        }).afterClosed().subscribe(result => {
            if (result?.success) {
                const duration = result.value;

                if (duration && !isNaN(duration) && duration !== 0 && !this.rates.includes(duration)) {
                    this.rates.push(duration);
                    this.rates.sort((a, b) => a - b);
                    this._updateModelRates();
                }
            }
        });
    }

    addRange() {
        this._rangesModel.push({
            day: HourlyRateRangeDayEnum.weekday,
            all_day: true,
            start: '00:00',
            finish: '23:59',
            hourly_rate: undefined,
            non_pro_rata_rates: {},
            pay_element_code: null,
            description: null,
        });

        this._updateModelRates();
    }

    deleteRate(duration: number) {
        const previousRanges = clone(this._ranges);
        const previousRates = clone(this.rates);

        this.rates.splice(this.rates.indexOf(duration), 1);

        this._updateModelRates();
        this._onModelChanged();

        this.snackBar.showUndo('Rate deleted')
            .afterDismissed()
            .subscribe(result => {
                if (result.dismissedByAction) {
                    this.rates = previousRates;
                    this.ranges = previousRanges;
                    this._onModelChanged();
                }
            });
    }

    deleteRange(range: HourlyRateRangeModel) {
        const previousValue = clone(this._ranges);

        this._rangesModel.splice(this._rangesModel.indexOf(range), 1);
        this._updateModelRates();
        this._onModelChanged();

        this.snackBar.showUndo('Range deleted')
            .afterDismissed()
            .subscribe(result => {
                if (result.dismissedByAction) {
                    this.ranges = previousValue;
                    this._onModelChanged();
                }
            });
    }

    onAllDayChanged(range: HourlyRateRangeModel) {
        if (range.all_day) {
            range.start = '00:00';
            range.finish = '23:59';
        } else {
            range.start = '09:00';
            range.finish = '18:00';
        }
    }

    _compareDaysWith(a, b) {
        if (a === b) {
            return true;
        }

        return equals(a, b);
    }

    _trackRange(index: number, range: HourlyRateRangeModel) {
        return JSON.stringify({
            start: range.start,
            finish: range.finish,
            day: range.day,
        });
    }

    _trackRateFn(index: number, duration: number) {
        return duration;
    }

    _toHourlyRateRange(range: HourlyRateRangeModel): HourlyRateRange {
        const rates = Object.values(range.non_pro_rata_rates)
            .filter(rate => rate.duration && isPresent(rate.amount));

        if (range.all_day) {
            range.start = '00:00';
            range.finish = '23:59';
        }

        return {
            day: range.day,
            start: range.start,
            finish: range.finish,
            hourly_rate: range.hourly_rate,
            non_pro_rata_rates: rates.length ? sortBy(rates, 'duration') : null,
            pay_element_code: range.pay_element_code,
            description: range.description,
        };
    }

    showRangeDescriptionDialog($event: MouseEvent, range: HourlyRateRangeModel) {
        $event.stopImmediatePropagation();

        InputDialogComponent.show(this.dialog, {
            title: 'Range description',
            type: 'text',
            content: 'This description will be used to describe the rate in all financial calculations',
            label: 'Description',
            placeholder: 'e.g., Weekend evening rate',
            initialValue: range.description,
        }).afterClosed().pipe(
            untilDestroyed(this)
        ).subscribe(result => {
            if (result?.success) {
                requestAnimationFrame(() => {
                    range.description = result.value;
                    this._onModelChanged();
                });
            }
        });
    }
}
