import { NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { LuxonDateAdapter } from './luxon-date-adapter';
import { MAT_LUXON_DATE_FORMATS } from './luxon-date-formats';

export * from './luxon-date-adapter';
export * from './luxon-date-formats';

@NgModule({
    providers: [
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
    ],
})
export class LuxonDateModule {}

@NgModule({
    imports: [LuxonDateModule],
    exports: [LuxonDateModule],
    providers: [{
        provide: MAT_DATE_FORMATS,
        useValue: MAT_LUXON_DATE_FORMATS
    }],
})
export class AppLuxonDateModule {}
