import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { AppCommonModule } from '@shared/common/common.module';
import { AlertDialogComponent } from '@shared/dialogs/alert/alert-dialog-component.service';
import { ConfirmPasswordDialogComponent } from '@shared/dialogs/confirm-password/confirm-password-dialog.component';
import { ConfirmDialogComponent } from '@shared/dialogs/confirm/confirm-dialog.component';
import { DangerConfirmDialogComponent } from './danger-confirm-dialog/danger-confirm-dialog.component';
import {
    DialogHeaderComponent,
    DialogIconComponent,
    DialogSubtitleComponent,
    DialogTitleComponent,
} from './header/header.component';
import { InputDialogComponent } from './input/input-dialog.component';
import { SlideshowDialogComponent } from './slideshow-dialog/slideshow-dialog.component';

const components = [
    AlertDialogComponent,
    ConfirmDialogComponent,
    ConfirmPasswordDialogComponent,
    DangerConfirmDialogComponent,
    DialogHeaderComponent,
    DialogIconComponent,
    DialogSubtitleComponent,
    DialogTitleComponent,
    InputDialogComponent,
    SlideshowDialogComponent,
];

const material = [
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
];

@NgModule({
    declarations: [...components],
    imports: [...material, FormsModule, CommonModule, AppCommonModule],
    exports: [...components, ...material],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }, { provide: MatDialogRef, useValue: {} }]
})
export class AppDialogsModule {
}
