import { googleMapsKey } from '@shared/config';
import type { Address } from '@shared/modules/addresses/address';
import { Presenter } from '@shared/presenters/presenter';
import type { LatLng } from '@shared/utils/geo';
import { head } from 'lodash-es';

export class AddressPresenter extends Presenter<Address> {
    get type() {
        return this._get('type', () => this.get('address_type.data.description'));
    }

    get localAddressParts() {
        return [
            this.data.street_1,
            this.data.street_2,
            this.data.town,
            this.data.county,
            this.data.post_code
        ].filter(Boolean);
    }

    get firstLine() {
        return head(this.localAddressParts);
    }

    get localAddress() {
        return this.localAddressParts.join('\n');
    }

    get localAddressWithoutFirstLineParts(): string[] {
        return this.localAddressParts.splice(1);
    }

    get localAddressWithoutFirstLine() {
        return this.localAddressWithoutFirstLineParts.join('\n');
    }

    get fullAddressFirstLine() {
        return head(this.fullAddressParts);
    }

    get fullAddressWithoutFirstLine() {
        return this.fullAddressParts.splice(1).join('\n');
    }

    get fullAddressParts() {
        return [
            this.data.street_1,
            this.data.street_2,
            this.data.town,
            this.data.county,
            this.data.post_code,
            this.data.country,
        ].filter(Boolean);
    }

    get googleMapsUrl() {
        if (this.data.manual_geocode && this.data.lat && this.data.lng) {
            return `https://www.google.com/maps/search/?api=1&query=${this.data.lat},${this.data.lng}`;
        }

        return `https://www.google.com/maps/search/?api=1&query=${this.fullAddressParts.join(',')}`;
    }

    get staticMapUrl() {
        return this._get('staticMapUrl', () => {
            const url = new URL('https://maps.googleapis.com/maps/api/staticmap');

            const params = new URLSearchParams({
                zoom: '15',
                size: '480x180',
                scale: '2',
                maptype: 'roadmap',
                key: googleMapsKey
            });

            if (this.data.lat && this.data.lng) {
                params.append('center', `${this.data.lat},${this.data.lng}`);
                params.append('markers', `color:red|${this.data.lat},${this.data.lng}`);

                return `${url}?${params}`;
            }

            params.append('center', this.fullAddressParts.join(','));

            return `${url}?${params}`;
        });
    }

    get fullAddress() {
        return this.fullAddressParts.join('\n');
    }

    get googleLatLng(): LatLng | null {
        return this._get('googleLatLng', (): LatLng | null => {
            if (this.data.lat && this.data.lng) {
                return { lat: this.data.lat, lng: this.data.lng };
            }

            return null;
        });
    }

    get postCode() {
        return this._get('postcode', () => this.data.post_code);
    }
}
