import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AuthModule } from './app/auth.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AuthModule)
    .catch(err => console.log(err));

// Tell Webpack where to load remote files from. This overrides the default deployUrl option
// in angular.json with a value that can be provided by on the window object.
// eslint-disable-next-line @typescript-eslint/naming-convention
declare var __webpack_public_path__: string;

if (window['deployUrl'] && window['deployUrl']['auth']) {
    __webpack_public_path__ = window['deployUrl']['auth'];
}
