/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractComponent } from '@shared/common/abstract.component';
import type { DialogResult } from '@shared/dialogs/constants';

@Directive()
@UntilDestroy()
export abstract class DialogComponent<Result = DialogResult> extends AbstractComponent {
    dialogResult: Result;

    protected constructor(
        protected dialogRef?: MatDialogRef<any>,
    ) {
        super();

        if (dialogRef) {
            dialogRef.disableClose = true;

            dialogRef.backdropClick().pipe(untilDestroyed(this)).subscribe($event => {
                this.closeWithResult();
            });

            dialogRef.keydownEvents().pipe(untilDestroyed(this)).subscribe($event => {
                if ($event.key === 'Escape') {
                    this.closeWithResult();
                }
            });
        }
    }

    closeWithResult() {
        if (!this.dialogRef) {
            console.warn('dialogRef property not set');
        }

        this.dialogRef?.close(this.dialogResult);
    }
}
