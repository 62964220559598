import { DateTime } from 'luxon';
import {
    DATE_FORMAT,
    endOfDayDateTime,
    endOfMonthDateTime,
    endOfYearDateTime,
    formatDateShort,
    isSameDay,
    isSameMonth,
    isSameYear,
    startOfMonthDateTime,
    startOfWeekDateTime,
    startOfYearDateTime,
    TIMESTAMP_FORMAT,
    todayDateTime
} from '../date-time';

export function formatShortDateRange(
    start: DateTime,
    finish: DateTime,
) {
    if (!start && !finish) {
        return null;
    }

    if (start && finish) {
        return start.toLocal().toFormat('d/L/yyyy') + ' - ' + finish.toLocal().toFormat('d/L/yyyy');
    }

    if (start && !finish) {
        return start.toLocal().toFormat('d/L/yyyy') + ' - ongoing';
    }

    if (!start && finish) {
        return 'until ' + finish.toLocal().toFormat('d/L/yyyy');
    }

    return null;
}

export function formatDateRange(
    start: DateTime,
    finish: DateTime,
    options: {
        includeTime?: boolean;
        includeOnwards?: boolean;
    } = {}
) {
    return readableDateRange(
        start,
        finish,
        options?.includeTime ?? false,
        options?.includeOnwards ?? false
    );
}

export function readableDateRange(
    start: DateTime,
    finish: DateTime,
    includeTime = false,
    includeOnwards = false
): string {
    const finishIsThisYear = isSameYear(finish, DateTime.local());

    if (!start?.isValid) {
        if (finish?.isValid) {
            const format = finishIsThisYear ? 'd MMM' : 'd MMM yyyy';

            if (includeTime) {
                return `until ${finish.toFormat(`HH:mm, ${format}`)}`;
            }

            return `until ${finish.toFormat(format)}`;
        }

        return null;
    }

    if (!finish?.isValid) {
        let startFormatted;

        if (!includeTime && start.toSQLDate() === todayDateTime.toSQLDate()) {
            startFormatted = 'Today';
        } else {
            startFormatted = start.toFormat(includeTime ? TIMESTAMP_FORMAT : DATE_FORMAT);
        }


        if (includeOnwards) {
            return `${startFormatted} - ongoing`;
        }

        return startFormatted;
    }


    if (isSameDay(start, finish)) {
        if (includeTime) {
            return `${start.toFormat('T')} - ${finish.toFormat('T')}, ${start.toFormat(finishIsThisYear ? 'd MMM' : 'd MMM yyyy')}`;
        }

        return start.toFormat(finishIsThisYear ? 'd MMM' : 'd MMM yyyy');
    }

    if (!includeTime) {
        if (start.toSQLDate() === startOfWeekDateTime.toSQLDate() && finish.toSQLDate() === endOfDayDateTime.toSQLDate()) {
            return 'This week';
        }

        if (start.toSQLDate() === startOfMonthDateTime.toSQLDate() && finish.toSQLDate() === endOfMonthDateTime.toSQLDate()) {
            return 'This month';
        }

        if (start.toSQLDate() === startOfYearDateTime.toSQLDate() && finish.toSQLDate() === endOfYearDateTime.toSQLDate()) {
            return 'This year';
        }
    }

    let startFormatString = 'd';

    if (includeTime) {
        startFormatString = `T ${startFormatString}`;
    }

    if (!isSameMonth(start, finish)) {
        startFormatString += ' MMM';
    }

    if (!isSameYear(start, finish)) {
        startFormatString += ' yyyy';
    }

    let finishFormatString = 'd MMM';

    if (includeTime) {
        finishFormatString = `T ${finishFormatString}`;
    }

    if (!finishIsThisYear) {
        finishFormatString += ' yyyy';
    }

    const formattedStart = start.toFormat(startFormatString);
    const formattedFinish = finish.toFormat(finishFormatString);

    return `${formattedStart} - ${formattedFinish}`;
}
