import { Injectable } from '@angular/core';
import type { User } from '@shared/modules/users/user';
import { UserPresenter } from '@shared/modules/users/user.presenter';
import { get } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { UserFetcher } from '../../modules/users/user.fetcher';
import { AppEvents } from '../event-emitter/app-events.service';

@Injectable({ providedIn: 'root' })
export class CurrentUser {
    _currentUser: User;
    user$ = new BehaviorSubject<User>(UserPresenter.attachItem(window.CareLineLive?.user));

    get currentUserId(): string {
        if (this._currentUser) {
            return this._currentUser.id;
        }

        return null;
    }

    get id() {
        return this.currentUserId;
    }

    constructor(
        private eventEmitter: AppEvents,
        private userFetcher: UserFetcher,
    ) {
        this._currentUser = UserPresenter.attachItem(get(window, 'CareLineLive.user'));
        this.user$.next(this._currentUser);

        this.userFetcher.successEvents.subscribe(event => {
            if (event.data.id === this.currentUserId) {
                this._fetchCurrentUser();
            }
        });
    }

    private _fetchCurrentUser() {
        return this.userFetcher.current().subscribe(response => {
            this._currentUser = UserPresenter.attachItem(response.data);
            this.user$.next(this._currentUser);
        });
    }

    get currentUser(): User {
        return this._currentUser;
    }

    get value(): User {
        return this._currentUser;
    }

    refresh(): void {
        this._fetchCurrentUser();
    }
}
