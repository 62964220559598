import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { AuthLogoComponent } from './auth-logo/auth-logo.component';
import { AuthShellBackgroundComponent } from './auth-shell-background/auth-shell-background.component';

@Component({
    selector: 'app-auth-shell',
    templateUrl: './auth-shell.component.html',
    styleUrls: ['./auth-shell.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterOutlet, AuthLogoComponent, AuthShellBackgroundComponent],
})
export class AuthShellComponent {
}
