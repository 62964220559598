import { InjectionToken } from '@angular/core';

export interface CurrencyMaskConfig {
    allowNegative: boolean;
    allowZero: boolean;
    decimal: string;
    precision: number;
    prefix: string;
    suffix: string;
    thousands: string;
    nullable: boolean;
    min?: number;
    max?: number;
    inputMode?: CurrencyMaskInputMode;
}

export enum CurrencyMaskInputMode {
    financial,
    natural,
}

export const CURRENCY_MASK_CONFIG = new InjectionToken<CurrencyMaskConfig>('currency.mask.config');
