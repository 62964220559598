/* eslint-disable @angular-eslint/contextual-lifecycle */
/* eslint-disable @angular-eslint/directive-class-suffix */
import type { AfterViewInit, OnInit } from '@angular/core';
import { Directive, ElementRef, HostBinding, Input, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { MatLegacyFormField as MatFormField } from '@angular/material/legacy-form-field';
import { AbstractInputComponent } from '../abstract-value-accessor/abstract-value';

@Directive()
export abstract class AbstractFormFieldComponent<Value> extends AbstractInputComponent<Value> implements OnInit, AfterViewInit {
    @Input() id: string;
    @Input() name: string;
    @Input() label: string;
    @Input() labelIcon: string;
    @Input() hint: string;
    @Input() placeholder: string;
    @Input() readonly = false;
    @Input() errorMessages: string[];
    @Input() clearable = false;
    @Input() tabIndex: number;

    @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

    constructor(protected element: ElementRef, protected renderer: Renderer2) {
        super();
    }

    ngOnInit(): void {
        this.renderer.addClass(this.element.nativeElement, 'app-form-field');
    }

    ngAfterViewInit(): void {
        this.checkForSubscript();

        this.formFields.changes.subscribe(() => {
            this.checkForSubscript();
        });
    }

    checkForSubscript() {
        const hasSubscript = this.formFields.filter(
            formField => formField._errorChildren.length > 0 || formField._hintChildren.length > 0
        ).length;

        if (hasSubscript > 0) {
            this.renderer.addClass(this.element.nativeElement, 'has-subscript');
        } else {
            this.renderer.removeClass(this.element.nativeElement, 'has-subscript');
        }
    }
}
