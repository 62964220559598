import type { NonProRataRate } from '@shared/modules/rates/non-pro-rata-rate';

export enum HourlyRateRangeDayEnum {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday',

    weekday = 'weekday',
    weekend = 'weekend',

    publicHoliday = 'public_holiday',
}

type HourlyRateRangeDay = HourlyRateRangeDayEnum | { special_day_id: string };

export interface HourlyRateRange {
    day: HourlyRateRangeDay;
    start: string;
    finish: string;
    hourly_rate: number;
    non_pro_rata_rates?: NonProRataRate[];
    pay_element_code?: string;
    description: string;
}
