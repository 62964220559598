<div
    class="action-required-snackbar-icon"
    [ngClass]="{ 'action-required-snackbar-icon--error': data.type === 'error','action-required-snackbar-icon--warning': data.type === 'warning' }">
    <mat-icon>{{ data.type }}</mat-icon>
</div>
<div class="action-required-snackbar-content">
    <div class="action-required-snackbar-title">{{ data.title }}</div>
    <div class="action-required-snackbar-message">{{ data.message }}</div>
    <div
        class="mat-simple-snackbar-action"
        *ngIf="hasAction">
        <button

            colour="secondary"
            (click)="action()">
            {{ data.action }}
        </button>
    </div>
</div>
