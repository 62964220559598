import type { OnChanges } from '@angular/core';
import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseSelectFormFieldComponent } from '@shared/form-fields/select/base-select-form-field.component';

@Component({
    selector: 'app-select-form-field',
    styleUrls: ['./select-form-field.component.scss'],
    templateUrl: './select-form-field.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SelectFormFieldComponent),
        multi: true,
    }],
})
export class SelectFormFieldComponent<T = any> extends BaseSelectFormFieldComponent<T> implements OnChanges {

}
