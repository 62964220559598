import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { DateFilterFn, MatDatepicker } from '@angular/material/datepicker';
 import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { AbstractFormFieldComponent } from '@shared/form-fields/abstract-form-field/abstract-form-field.component';
import { makeProvider } from '@shared/form-fields/make-provider';
import { LuxonDateAdapter } from '@shared/form-fields/luxon-date-adapter';
import { toDateTime } from '@shared/utils/date-time';
import isString from 'lodash-es/isString';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-input-date',
    styleUrls: ['./date-input.component.scss'],
    template: `
        <mat-form-field [appearance]="appearance">
            <mat-label *ngIf="label">
                {{ label }}
            </mat-label>

            <div matPrefix>
                <ng-content select="[appPrefix]"></ng-content>
            </div>

            <input matInput
                   #input
                   [matDatepicker]="datePicker"
                   [matDatepickerFilter]="filter"
                   [max]="maxDate"
                   [min]="minDate"
                   [(ngModel)]="value"
                   [ngModelOptions]="{ standalone: true, updateOn: 'blur' }"
                   [placeholder]="placeholder"
                   [required]="required"
                   [disabled]="disabled"
                   [readonly]="readonly">

            <mat-datepicker #datePicker="matDatepicker" />

            <div matSuffix>
                <ng-content select="[appSuffix]"></ng-content>

                <mat-datepicker-toggle [for]="datePicker" />

                <button class="clear-button"
                        *ngIf="clearable && !disabled && !readonly && value"
                        mat-icon-button
                        (click)="value = null; clear($event)">
                    <mat-icon>clear</mat-icon>
                </button>
            </div>

            <mat-hint *ngIf="hint">
                {{ hint }}
            </mat-hint>

            <mat-error *ngFor="let message of errorMessages">{{ message }}</mat-error>
        </mat-form-field>

        <button mat-icon-button
                *ngIf="buttonOnly"
                (click)="datePicker.open()">
            <mat-icon>event</mat-icon>
        </button>
    `,
    providers: [makeProvider(DateInputComponent)],
})
export class DateInputComponent extends AbstractFormFieldComponent<string | DateTime> {
    @ViewChild('datePicker') matDatePicker: MatDatepicker<DateTime>;

    @ViewChild('input', { static: true }) inputElement: ElementRef<HTMLInputElement>;
    @Input() @HostBinding('class.button-only') buttonOnly = false;

    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() valueType: 'string' | 'datetime' = 'string';
    @Input() maxDate: DateTime;
    @Input() minDate: DateTime;
    @Input() required: boolean;

    @Input() filter: DateFilterFn<string | DateTime | null>;

    @Output() valueChange = new EventEmitter;

    constructor(
        element: ElementRef,
        renderer: Renderer2,
        protected luxonDateAdapter: LuxonDateAdapter
    ) {
        super(element, renderer);
    }

    set value(v: string | DateTime) {
        if (v === this._value) {
            return;
        }

        if (v === null) {
            this._value = v;
            this.onChange(this._value);
            return;
        }

        if (this.valueType === 'string') {
            if (this._value instanceof DateTime && v instanceof DateTime) {
                this._value = this._value.set({
                    year: v.year,
                    month: v.month,
                    day: v.day,
                });
            } else if (isString(v)) {
                this._value = v;
            } else if (v instanceof DateTime) {
                this._value = v.toISODate();
            } else {
                // Do nothing, we don't want to clear the value if the date can't be parsed and null is returned by the date adapter.
            }
        } else if (this.valueType === 'datetime') {
            if (this._value instanceof DateTime && v instanceof DateTime) {
                this._value = this._value.set({
                    year: v.year,
                    month: v.month,
                    day: v.day,
                });
            } else if ((!this._value || isString(this._value)) && v instanceof DateTime) {
                this._value = v;
            } else if (isString(v)) {
                this._value = this.luxonDateAdapter.parse(v);
            } else {
                // Do nothing, we don't want to clear the value if the date can't be parsed and null is returned by the date adapter.
            }
        }

        this.onChange(this._value);
        this.valueChange.emit({ start: this._value, end: this._value });
    }

    get value() {
        return this._value;
    }

    writeValue(value: string | DateTime): void {
        if (typeof value === 'string') {
            value = toDateTime(value).toLocal().toSQLDate();
        }

        this._value = value;

        // warning: comment below if only want to emit on user intervention
        // this.onChange(value);
    }

    open(): void {
        this.matDatePicker.open();
    }
}
