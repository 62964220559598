import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { DateTime } from 'luxon';
import { formatTimestamp, toDateTime } from '../../../utils/date-time';

@Pipe({ name: 'appTimestamp' })
export class TimestampPipe implements PipeTransform {
    transform(value: string | DateTime, format?: string): any {
        const instance = toDateTime(value);

        if (instance?.isValid) {
            return formatTimestamp(instance, true, format);
        }

        return value;
    }
}
