import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractComponent } from '@shared/common/abstract.component';
import type { CarerContractOptionSet } from '@shared/modules/carer-contract-option-sets/carer-contract-option-set';
import type {
    CarerContractTypeOptionSet
} from '@shared/modules/carer-contract-type-option-sets/carer-contract-type-option-set';
import type { LeaveType } from '@shared/modules/leave-types/leave-type';
import { LeaveTypeFetcher } from '@shared/modules/leave-types/leave-type.fetcher';
import type { SpecialDay } from '@shared/modules/special-days/special-day';
import { SpecialDayFetcher } from '@shared/modules/special-days/special-day.fetcher';
import { SpecialDayPresenter } from '@shared/modules/special-days/special-day.presenter';
import { toTitleCase } from '@shared/utils/strings';

interface OptionVisibility {
    hourlyRates: boolean;
    guaranteedPay: boolean;
    nonProRataRates: boolean;
    hourlyRateRanges: boolean;
    fixedRates: boolean;
    travelTime: boolean;
    mileageRates: boolean;
    leave: boolean;
}

@Component({
    selector: 'app-carer-contract-pay-element-codes',
    templateUrl: './carer-contract-pay-element-codes.component.html',
    styleUrls: ['./carer-contract-pay-element-codes.component.scss'],
})
@UntilDestroy()
export class CarerContractPayElementCodesComponent extends AbstractComponent implements OnInit {
    @Input() optionSets: CarerContractTypeOptionSet[];
    @Input() hasOverrides = false;

    specialDays: SpecialDay[];

    ui: {
        show: {
            [prop: string]: OptionVisibility;
        };
    } = {
        show: {},
    };

    leaveTypes: LeaveType[];

    constructor(
        private dialog: MatDialog,
        private leaveTypeFetcher: LeaveTypeFetcher,
        private specialDayFetcher: SpecialDayFetcher,
    ) {
        super();
    }

    ngOnInit() {
        this.fetchSpecialDays();
        this.fetchLeaveTypes();

        this.updateShowSections();
    }

    updateShowSections() {
        this.ui.show = {};

        this.optionSets.forEach(optionSet => {
            this.ui.show[optionSet.id] = {
                hourlyRates: true,
                guaranteedPay: !!optionSet.guaranteed_pay_week,
                nonProRataRates: !!optionSet.non_pro_rata_rates?.length,
                hourlyRateRanges: !!optionSet.hourly_rate_ranges?.length,
                fixedRates: !!optionSet.fixed_rates?.data?.length,
                travelTime: !!(optionSet.mileage_travel_time_rate_per_minute || optionSet.pay_travel_time_using_hourly_rate),
                mileageRates: optionSet.use_custom_mileage_rates,
                leave: true,
            };

            if (this.hasOverrides) {
                const visibility = this.ui.show[optionSet.id];
                const set = optionSet as CarerContractOptionSet;

                this.ui.show[optionSet.id] = {
                    hourlyRates: visibility.hourlyRates
                        && set.override_hourly_rate,

                    guaranteedPay: visibility.guaranteedPay
                        && set.override_guaranteed_pay_week,

                    nonProRataRates: visibility.nonProRataRates
                        && set.override_non_pro_rata_rates,

                    hourlyRateRanges: visibility.hourlyRateRanges
                        && set.override_hourly_rate_ranges,

                    fixedRates: visibility.fixedRates
                        && set.override_fixed_rates,

                    travelTime: visibility.travelTime
                        && (set.override_mileage_travel_time_rate_per_minute || set.override_pay_travel_time_using_hourly_rate),

                    mileageRates: visibility.mileageRates
                        && (set.override_use_custom_mileage_rates || set.override_mileage_rates),

                    leave: visibility.leave
                        && set.override_leave_type_pay_elements
                };
            }
        });
    }

    _trackOptionSetFn(index: number, optionSet: CarerContractTypeOptionSet) {
        return optionSet.id ?? optionSet.applies_from;
    }

    fetchSpecialDays() {
        this.specialDayFetcher.all().subscribe(response => {
            this.specialDays = SpecialDayPresenter.attachCollection(response.data).map(day => ({
                ...day,
                rangeValue: { special_day_id: day.id },
            }));
        });
    }

    fetchLeaveTypes() {
        this.leaveTypeFetcher.all().pipe(untilDestroyed(this)).subscribe(types => {
            this.leaveTypes = types.data;
        });
    }

    getHourlyRateRangeDayName(value) {
        if (typeof value === 'string') {
            return toTitleCase(value.split('_').join(' '));
        }

        if (!this.specialDays || !this.specialDays.length) {
            return '...';
        }

        const specialDay = this.specialDays.filter(day => day.id === value.special_day_id);

        if (specialDay.length < 1 || !specialDay[0].hasOwnProperty('name')) {
            return '...';
        }

        return specialDay[0].name;
    }

    countHourlyRateRangesRows(optionSet: CarerContractTypeOptionSet): number {
        let count = 0;

        optionSet.hourly_rate_ranges?.forEach(range => {
            count++;
            count += range.non_pro_rata_rates?.length;
        });

        return count;
    }

    countFixedRateRows(optionSet: CarerContractTypeOptionSet) {
        let count = 0;

        optionSet.fixed_rates?.data?.forEach(rate => {
            count++;
            count += rate.rules?.length;
        });

        return count;
    }
}
