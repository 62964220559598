import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, Inject } from '@angular/core';
import type { DialogService } from '@carelinelive/ui';
import { ComponentsModule } from '@carelinelive/ui';
import { SendableRequest } from '@mastechnicaegroup/carelinelive-cdk/requests';
import { DialogWidth } from '@shared/dialogs';
import { AppSharedModule } from '@shared/shared.module';
import type { UserAgreementItem } from '../user-agreement-item';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-agreement-view-dialog',
    templateUrl: './agreement-view-dialog.component.html',
    styleUrls: ['./agreement-view-dialog.component.scss'],
    standalone: true,
    imports: [CommonModule, ComponentsModule, AppSharedModule],
})
export class AgreementViewDialogComponent {
    private readonly _http = inject(HttpClient);

    public form = new FormGroup({
        accept: new FormControl(false, Validators.requiredTrue),
    });

    protected readonly acceptRequest = new SendableRequest<void, UserAgreementItem>({
        request: agreement =>
            this._http.post<void>('/auth/agreements', {
                agreements: [agreement.id],
            }),
        onSuccess: () => this.dialogRef.close(true),
    });

    constructor(
        protected readonly dialogRef: DialogRef<boolean>,
        @Inject(DIALOG_DATA) protected readonly data: DialogData,
    ) {

    }

    accept(): void {
        this.acceptRequest.send(this.data.agreement);
    }

    public static show(dialog: DialogService, data: DialogData) {
        return dialog.openDialog<boolean, DialogData>(AgreementViewDialogComponent, {
            data,
            maxWidth: DialogWidth.medium,
        });
    }
}

export interface DialogData {
    agreement: UserAgreementItem;
}
