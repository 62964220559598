import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AbsPipe } from '@shared/common/pipes/abs.pipe';
import { CompactIntegerPipe } from '@shared/common/pipes/compact-integer.pipe';
import { AppDateRangePipe } from '@shared/common/pipes/dates/date-range.pipe';
import { AppDateShortPipe } from '@shared/common/pipes/dates/date-short.pipe';
import { DateWithYearPipe } from '@shared/common/pipes/dates/date-with-year.pipe';
import { AppHasPast } from '@shared/common/pipes/dates/has-past.pipe';
import { AppIsFuture } from '@shared/common/pipes/dates/is-future.pipe';
import { AppIsToday } from '@shared/common/pipes/dates/is-today.pipe';
import { AppIsoDatePipe } from '@shared/common/pipes/dates/iso-date.pipe';
import { AppTimePrecisePipe } from '@shared/common/pipes/dates/time-precise.pipe';
import { AppTimePipe } from '@shared/common/pipes/dates/time.pipe';
import { AppTimestampRange } from '@shared/common/pipes/dates/timestamp-range.pipe';
import { ToDateTimePipe } from '@shared/common/pipes/dates/to-date-time.pipe';
import { DurationPrecisePipe } from '@shared/common/pipes/duration-precise.pipe';
import { HoursPipe } from '@shared/common/pipes/hours.pipe';
import { AppImageFilePreviewPipe } from '@shared/common/pipes/image-file-preview.pipe';
import { IsAnyPresentPipe } from '@shared/common/pipes/is-any-present.pipe';
import { IsPresentPipe } from '@shared/common/pipes/is-present.pipe';
import { MaxDateTimePipe } from '@shared/common/pipes/max-date-time.pipe';
import { AppOrderByPipe } from '@shared/common/pipes/order-by.pipe';
import { ParseErrorMessageModule } from '@shared/common/pipes/parse-error-message/parse-error-message.module';
import { PunctualityPipe } from '@shared/common/pipes/punctuality.pipe';
import { AppRoundPipe } from '@shared/common/pipes/round.pipe';
import { SeverityLevelModule } from '@shared/common/pipes/severity-level/severity-level.module';
import { TimeAgoPipeModule } from '@shared/common/pipes/time-ago/time-ago.pipe';
import { TitlePipe } from '@shared/common/pipes/title.pipe';
import { TruncatePipe } from '@shared/common/pipes/truncate.pipe';
import { CapitalizePipe } from '@shared/common/pipes/upper-first.pipe';
import { AppValuesPipe } from '@shared/common/pipes/values.pipe';
import { ArrayFilterPipe } from '@shared/pipes/array-filter.pipe';
import { HighlightPipe } from '@shared/pipes/highlight.pipe';
import { QueryParamsPipe } from '@shared/pipes/query-params.pipe';
import { CamelToSentencePipe } from './pipes/camelToSentence.pipe';
import { AppDatePipe } from './pipes/dates/date.pipe';
import { TimestampPipe } from './pipes/dates/timestamp.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { ListPipe } from './pipes/list.pipe';
import { Nl2BrPipe } from './pipes/nl2br.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { AppBirthdayDatePipe } from './pipes/dates/birthdayDate.pipe';

const pipes = [
    AppDatePipe,
    AppBirthdayDatePipe,
    CamelToSentencePipe,
    DurationPipe,
    ListPipe,
    Nl2BrPipe,
    PunctualityPipe,
    TimestampPipe,
    TruncatePipe,
    AppTimePipe,
    HighlightPipe,
    CompactIntegerPipe,
    AppDateRangePipe,
    AppTimestampRange,
    ToDateTimePipe,
    QueryParamsPipe,
    TitlePipe,
    AppDateShortPipe,
    ArrayFilterPipe,
    SafePipe,
    AppImageFilePreviewPipe,
    HoursPipe,
    IsPresentPipe,
    IsAnyPresentPipe,
    MaxDateTimePipe,
    AppIsoDatePipe,
    AppHasPast,
    AppIsFuture,
    AppIsToday,
    AppOrderByPipe,
    AppValuesPipe,
    AbsPipe,
    AppRoundPipe,
    AppTimePrecisePipe,
    DurationPrecisePipe,
    DateWithYearPipe,
    CapitalizePipe,
];

@NgModule({
    imports: [
        CommonModule,
        SeverityLevelModule,
        TimeAgoPipeModule,
        ParseErrorMessageModule
    ],
    declarations: pipes,
    exports: [
        ...pipes,
        SeverityLevelModule,
        TimeAgoPipeModule,
        ParseErrorMessageModule
    ]
})
export class AppPipesModule {

}
