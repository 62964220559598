import { Component, Inject, Injectable } from '@angular/core';
import type { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogComponent } from '@shared/dialogs/dialog.component';

export type InputDialogType = 'text' | 'number' | 'duration';

@Injectable({
    providedIn: 'root',
})
@Component({
    template: `
        <app-dialog-header>
            <app-dialog-title>
                {{ options.title }}
            </app-dialog-title>
        </app-dialog-header>

        <mat-dialog-content>
            {{ options.content }}

            <ng-container [ngSwitch]="options.type">
                <app-input-text
                    *ngSwitchCase="'text'"
                    [label]="options.label"
                    [placeholder]="options.placeholder"
                    [(ngModel)]="value" />

                <app-input-number
                    *ngSwitchCase="'number'"
                    [label]="options.label"
                    [placeholder]="options.placeholder"
                    [(ngModel)]="value" />

                <app-input-duration
                    *ngSwitchCase="'duration'"
                    [label]="options.label"
                    [placeholder]="options.placeholder"
                    [(ngModel)]="value" />
            </ng-container>

        </mat-dialog-content>

        <mat-dialog-actions>
            <button mat-button
                    mat-dialog-close
                    (click)="cancel()">
                {{ options.cancel || 'Cancel' }}
            </button>

            <button mat-raised-button
                    color="primary"
                    (click)="okay()">
                {{ options.okay || 'Okay' }}
            </button>
        </mat-dialog-actions>
    `,
    styleUrls: ['./input-dialog.component.scss'],
})
export class InputDialogComponent extends DialogComponent {
    type: InputDialogType = 'text';
    value: any;

    constructor(
        public dialogRef: MatDialogRef<InputDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public options: InputDialogConfig
    ) {
        super();

        this.dialogRef = dialogRef;
        this.options = options;
        this.value = options.initialValue;
    }

    cancel() {
        this.dialogRef.close(null);
    }

    okay() {
        this.dialogRef.close({
            success: true,
            value: this.value
        });
    }

    /**
     * @param dialog
     * @param data
     */
    public static show(dialog: MatDialog, data: InputDialogConfig) {
        return dialog.open<InputDialogComponent, InputDialogConfig, InputDialogResult>(InputDialogComponent, {
            data,
            maxWidth: '320px',
        });
    }
}

export interface InputDialogConfig {
    title: string;
    content?: string;
    cancel?: string;
    okay?: string;
    label?: string;
    placeholder?: string;
    type: InputDialogType;
    initialValue?: any;
}

export interface InputDialogResult {
    success: boolean;
    value: any;
}
