<div
    *ngIf="{
        resetPasswordRequest: resetPasswordRequest$ ? (resetPasswordRequest$ | async) : null
    } as context">
    <app-auth-form-card
        [title]="'Reset your password'"
        [subTitle]="'Enter the email address associated with your account'">
        <form
            *ngIf="formGroup"
            [formGroup]="formGroup"
            (ngSubmit)="submitForm()">
            <cll-form-field label="Confirm your email address">
                <input
                    type="email"
                    [formControl]="formGroup.controls.email"
                    #formInput="cllFormInput"
                    cllFormInput />
            </cll-form-field>

            <div>Choose a new password</div>

            <cll-form-field label="New password">
                <input
                    #password
                    #passwordInput="cllFormInput"
                    [formControl]="formGroup.controls.password"
                    cllFormInput
                    type="password" />

                <cll-password-toggle
                    cllSuffix
                    [input]="password" />
            </cll-form-field>

            <app-expand [expand]="!!formGroup.controls.password.value">
                <app-password-strength-indicator [password]="formGroup.controls.password.value" />
            </app-expand>

            <cll-form-field label="Confirm your new password">
                <input
                    #password_confirmation
                    #passwordInput="cllFormInput"
                    [formControl]="formGroup.controls.password_confirmation"
                    cllFormInput
                    type="password" />

                <cll-password-toggle
                    cllSuffix
                    [input]="password" />
            </cll-form-field>

            <cll-card-footer>
                <button
                    cll-filled-button
                    colour="secondary"
                    class="login-form-card__reset"
                    [disabled]="formGroup.disabled || formGroup.invalid || context.resetPasswordRequest?.fetching"
                    type="submit">
                    <cll-icon>check</cll-icon>
                    Reset your password
                </button>
            </cll-card-footer>
        </form>
    </app-auth-form-card>
</div>

<a
    class="back-to-login"
    [routerLink]="['../../login']">
    <cll-icon [size]="14">arrow_back</cll-icon>Back to login</a
>

