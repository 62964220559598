import { Injectable } from '@angular/core';
import type { LeaveType } from '@shared/modules/leave-types/leave-type';
import { Fetcher } from '../../http/fetcher';

@Injectable({
    providedIn: 'root',
})
export class LeaveTypeFetcher extends Fetcher<LeaveType> {
    readonly modelName: string = 'LeaveType';

    get resourceName(): string {
        return 'leave-types';
    }
}
