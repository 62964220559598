<app-avatar *ngIf="option.has_avatar || option.avatar_url"
            [src]="option.avatar_url"
            size="18px" />

<div>
    {{ option.label }}

    <small class="block mat-option-description"
           *ngIf="option.description && showDescription"
           [innerHTML]="option.description">
    </small>

    <div class="app-form-select-option-meta"
         *ngIf="option.meta?.length && showMeta">
        <div *ngFor="let meta of option.meta"
             [class.has-tooltip]="meta.tooltip | isPresent"
              [matTooltip]="meta.tooltip">
            <mat-icon *ngIf="meta.icon">{{ meta.icon }}</mat-icon>
            {{ meta.title }}
        </div>
    </div>
</div>
