import { distanceUnitSingular } from '../../config';

export const DEFAULT_MILEAGE_RATES = Object.freeze([
    { key: 'rate_car', name: 'Car', default: { amount: 0.45, pay_element_code: null, type: 'distance' } },
    { key: 'rate_motorcycle', name: 'Motorcycle', default: { amount: 0.24, pay_element_code: null, type: 'distance' } },
    { key: 'rate_cycle', name: 'Cycle', default: { amount: 0.20, pay_element_code: null, type: 'distance' } },
    { key: 'rate_public_transport', name: 'Public Transport', default: { amount: 0.00, pay_element_code: null, type: 'distance' } },
    { key: 'rate_walk', name: 'Walking', default: { amount: 0.00, pay_element_code: null, type: 'distance' } },
]);

export const MILEAGE_RATE_TYPE_OPTIONS = Object.freeze([
    {
        label: `Per ${distanceUnitSingular}`,
        value: 'distance'
    },
    {
        label: 'Fixed',
        value: 'fixed'
    },
]);
