import { NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule, CardModule, ComponentsModule, IconModule, PanelModule } from '@carelinelive/ui';
import { AppAlertModule } from '@shared/common/alert/alert.module';
import { ExpandModule } from '@shared/common/animations/expand/expand.module';
import { AppFormFieldsModule } from '@shared/form-fields/index';
import {
    PasswordStrengthIndicatorComponent
} from '@shared/modules/password/password-strength-indicator/password-strength-indicator.component';
import { AppSnackbarModule } from '@shared/services/snackbar/app-snackbar.module';
import { AppSpinnerModule } from '@shared/spinner/spinner.module';

import { AuthRootComponent } from './auth-root.component';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthShellComponent } from './auth-shell/auth-shell.component';
import { CheckEmailComponent } from './check-email/check-email.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './register/registration.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { StartUpMessageComponent } from './login/components/start-up-message/start-up-message.component';
import { AppDialogsModule } from '@shared/dialogs/dialogs.module';
import { AppCountriesModule } from '@shared/common/countries/countries.module';
import { AgreementViewDialogComponent } from './terms/dialogs/agreement-view-dialog.component';
import { UserAgreementsComponent } from "./terms/user-agreements.component";
import { AuthLogoComponent } from './auth-shell/auth-logo/auth-logo.component';
import { AuthFormCardComponent } from './components/login-form-card/auth-form-card.component';

@NgModule({
    declarations: [
        AuthRootComponent,
        LoginComponent,
        ResetPasswordComponent,
        ForgottenPasswordComponent,
        RegistrationComponent,
        CheckEmailComponent,
        StartUpMessageComponent,
        UserAgreementsComponent,
        AuthFormCardComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule.withConfig({
            disableAnimations: window.CareLineLive?.config?.disableAnimations ?? false,
        }),
        AuthRoutingModule,
        MatCardModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        AppAlertModule,
        AppSnackbarModule,
        MatSnackBarModule,
        HttpClientModule,
        AppSpinnerModule,
        MatIconModule,
        CardModule,
        ButtonsModule,
        PanelModule,
        IconModule,
        NgOptimizedImage,
        AppDialogsModule,
        AppCountriesModule,
        ExpandModule,
        PasswordStrengthIndicatorComponent,
        AppFormFieldsModule,
        ComponentsModule,
        AgreementViewDialogComponent,
        AuthShellComponent,
        AuthLogoComponent,
    ],
    providers: [],
    bootstrap: [AuthRootComponent],
})
export class AuthModule {
}
