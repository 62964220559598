import { BelongsToPersonPresenter } from '@shared/modules/people/person.presenter';
import { TeamPresenter } from '@shared/modules/teams/team-presenter';
import type { User } from './user';

export class UserPresenter extends BelongsToPersonPresenter<User> {

    constructor(data) {
        super(data);
        this.hasRole = this.hasRole.bind(this);
    }

    get email() {
        return this._data.email;
    }

    get roles(): string[] {
        return this._get('roles', () => (this.data.roles?.data ?? []).map(role => role.name));
    }

    hasRole(role) {
        return this._get(`hasRole(${role})`, () => this.get('roles.data', []).findIndex(r => r.id === role) > -1);
    }

    get teams(): TeamPresenter[] {
        return this.presentCollection('teams.data', TeamPresenter);
    }

    get teamNames(): string[] {
        return this._get('teamNames', () => (this.teams ?? []).map(team => team.name));
    }
}
