import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export enum AppEventSource {
    local = 'local',
    global = 'global',
}

@Injectable({
    providedIn: 'root',
})
export class AppEvents {
    private localStorage: Storage = window.localStorage;

    private registeredEventTypes: {
        [key: string]: {
            source: AppEventSource;
        };
    } = {};

    public readonly events = new Subject<AppEvent>();

    constructor() {
        this._onStorageMessageReceived = this._onStorageMessageReceived.bind(this);

        if (this.localStorage) {
            window.addEventListener('storage', this._onStorageMessageReceived, false);
        }
    }

    /**
     * Returns true if the event type has been registered.
     *
     * @private
     * @param eventName
     * @returns
     */
    private _isAllowed(eventName: string): boolean {
        return this.registeredEventTypes.hasOwnProperty(eventName);
    }

    /**
     * Register an allowed event type.
     *
     * @param eventName
     * @param global
     */
    public registerEventType(eventName: string, { source = AppEventSource.local } = {}) {
        if (this.registeredEventTypes.hasOwnProperty(eventName)) {
            // Already registered
            return;
        }

        this.registeredEventTypes[eventName] = {
            source,
        };
    }

    name(name: string | string[]): Observable<AppEvent> {
        return this.events.pipe(
            filter(event => {
                if (Array.isArray(name)) {
                    return name.includes(event.name);
                }

                return event.name === name;
            }),
        );
    }

    broadcast(name: string, data?: any, source?: AppEventSource) {
        if (!this._isAllowed(name)) {
            console.warn(`Event '${name} is not registered'`);
        }

        const registration = this.registeredEventTypes[name];

        console.log({ registration });

        this.events.next({
            name,
            data,
            source,
        });

        console.log(name, data, source);

        if (!source && registration) {
            source = registration.source;
        }

        if (source === AppEventSource.global) {
            this._postMessage(name, data);
        }
    }

    /**
     * @param event
     * @private
     */
    protected _onStorageMessageReceived(event) {
        const key = event.key;

        if (!key || key.substr(0, 6) !== 'event:') {
            return;
        }

        const eventName = key.substr(6);
        const data = event.newValue ? JSON.parse(event.newValue) : event.newValue;

        console.log({ eventName, data });

        this.events.next({
            name: eventName,
            data,
            source: AppEventSource.global,
        });
    }

    /**
     * Post the event to other browser tabs through localstorage.
     *
     * @param eventName
     * @param data
     * @private
     */
    protected _postMessage(eventName, data) {
        if (this.localStorage) {
            console.debug('Posting message via localStorage');

            try {
                this.localStorage.setItem('event:' + eventName, data ? JSON.stringify(data) : null);
            } catch (e) {
                if (e.message.includes('quota')) {
                    this.clearEvents();
                    this.localStorage.setItem('event:' + eventName, data ? JSON.stringify(data) : null);
                }
            }
        }
    }

    private clearEvents() {
        // Remove all old event data
        for (const k in this.localStorage) {
            if (k.startsWith('event:')) {
                this.localStorage.removeItem(k);
            }
        }
    }
}

export interface AppEvent<Data = any> {
    name: string;
    data?: Data;
    source?: AppEventSource;
}
