import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-auth-form-card',
    templateUrl: './auth-form-card.component.html',
    styleUrls: ['./auth-form-card.component.scss'],
    animations: [
        trigger('fade', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateX(8px)' }),
                animate(200, style({ opacity: 1, transform: 'translateX(0px)' }))
            ]),

            transition(':leave', [
                style({ opacity: 1, transform: 'translateX(0px)' }),
                animate(0, style({ opacity: 0, transform: 'translateX(-8px)' }))
            ])
        ])
    ]
})
export class AuthFormCardComponent {
    @Input() title: string;
    @Input() subTitle: string;
    @Input() showHelpContact = true;
}
