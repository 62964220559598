import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';

export class RefreshToken extends BehaviorSubject<DateTime> {
    constructor() {
        super(null);
    }

    /**
     * Trigger a refresh
     */
    public refresh(): void {
        this.next(DateTime.now());
    }
}
