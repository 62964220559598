import { Component, HostBinding, Input } from '@angular/core';
import { expandAnimation } from '@shared/common/animations/expandAnimation';
import { PreferencesService } from '@shared/services/preferences/preferences.service';

type AlertType = 'info' | 'white' | 'hint' | 'success' | 'warn' | 'error' | 'danger' | 'purple' | 'empty';

/**
 * @deprecated Use `cll-panel` instead
 */
@Component({
    selector: 'app-alert',
    styleUrls: ['./alert.component.scss'],
    template: `
        <div class="alert-container">
            <mat-icon class="alert-icon" *ngIf="icon">{{ icon }}</mat-icon>
            <mat-icon class="alert-icon" *ngIf="!icon && iconSvg" svgIcon="{{ iconSvg }}" />

            <div class="alert-content">
                <ng-content></ng-content>
            </div>
        </div>

        <div class="alert-action">
            <ng-content select="[app-alert-action],[appAlertAction]"></ng-content>
        </div>

        <div class="alert-dismiss"
             *ngIf="dismissible">
            <button mat-icon-button
                    (click)="dismiss()"
                    matTooltip="Dismiss">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    `,
    animations: [
        expandAnimation('0.5s')
    ]
})
export class AlertComponent {
    private _dismissPreferenceKey: string;

    @Input() icon: string;
    @Input() iconSvg: string;

    @Input() dismissible = false;

    @Input() set dismissPreferenceKey(value: string) {
        this._dismissPreferenceKey = value;

        this.checkIfDismissed();
    }

    @HostBinding('@.disabled') @Input() public disableAnimations = false;
    @HostBinding('@expand') readonly _showing = true;
    @HostBinding('class.alert-dismissed') dismissed = false;
    @HostBinding('class.vertical') @Input() vertical = false;

    constructor(
        private preferences: PreferencesService
    ) {
    }

    checkIfDismissed() {
        const key = this._dismissPreferenceKey;

        if (key && this.preferences.hasBeenDismissed(key)) {
            this.dismissed = true;
        }
    }

    dismiss() {
        if (this._dismissPreferenceKey) {
            this.preferences.setDismissed(this._dismissPreferenceKey);
            this.dismissed = true;
        }
    }
}
