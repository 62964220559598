/* eslint-disable @typescript-eslint/naming-convention */
export enum Preferences {
    NavigationShowing = 'navigation:showing',

    ShowBookingGhost = 'day-grid:show-booking-ghost',
    UseNewRoster = 'roster:use-ng',
    DisableStickyRows = 'roster:disable-sticky-rows',
    DisableTravel = 'roster:disable-travel',
    ShowUnallocatedRowsForSelectedTeams = 'roster:show-unallocated-rows-for-selected-teams',
    OnlyShowAvailableWorkers = 'roster:only-available-workers',
    ShowCancelledBookings = 'roster:show-cancelled-bookings',
    showClientScheduleEntries = 'roster:show-client-schedule-entries',
    AutoRefresh = 'roster:auto-refresh',
    RosterZoomLevel = 'roster:zoom-level',
    ShowClientPriority = 'roster:show-client-priority',
    RosterParallelFetching = 'roster:parallel-fetching',

    ProfilesCollapseTabs = 'profiles:collapse-tabs',
    AlertsCollapseNav = 'alerts:collapse-nav',
    CapacityCollapseNav = 'capacity:collapse-nav',
    CarePackageSummaryCustomise = 'care-package-summary:customise',
    CarePackageSummaryVisibility = 'care-package-summary:visibility',
    // Monitoring Dashboard
    IncludeUpcomingVisits = 'monitoring-dashboard:include-upcoming-visits',

    // Finance charts
    FinanceChartType = 'finance:chart-type',

    // Day grids

    PreferenceClientAgendaLayoutList = 'client-agenda:list-layout',
    PreferenceClientScheduleLayoutList = 'client-schedule:list-layout',
    PreferenceCarerAgendaLayoutList = 'carer-agenda:list-layout',
    PreferenceCarerScheduleLayoutList = 'carer-schedule:list-layout',

    CarersIndexShowActivity = 'carers-index:show-activity',
}
