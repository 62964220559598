import type { ActivatedRoute, Router } from '@angular/router';
import type { ApiParams } from '@shared/http/api-params';
import type { ApiResponse } from '@shared/http/responses/api-response';
import type { AppErrorHandler } from '@shared/services/error-handler/app-error-handler.service';
import type { Observable, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

export interface PaginatorApiParams extends ApiParams {
    page?: number;
    per_page?: number;
    order_by?: string;
    direction?: 'asc' | 'desc';
}

type FetchPageCallback<T> = (params: PaginatorApiParams) => Observable<ApiResponse<T[]>>;

export interface PaginatorConfig<T> {
    fetchPageCallback: FetchPageCallback<T>;
    perPage?: number;
    router?: Router;
    currentRoute?: ActivatedRoute;
}

export class Paginator<TModel> {
    private _fetchPageCallback: FetchPageCallback<TModel>;

    private currentParams: any = null;
    private currentCount: any = null;

    public total: number;
    public currentPage = 1;

    public hasUpdatedQueryParams = false;

    // Page index is 0-based
    public get pageIndex() {
        return this.currentPage - 1;
    }

    public perPage = 20;
    private totalPages = 1;

    private request: Subscription;

    private searchQuery: string;

    public pageItems = new BehaviorSubject<TModel[]>(null);

    private readonly router: Router;
    private readonly currentRoute: ActivatedRoute;

    constructor(
        config: PaginatorConfig<TModel>,
        private errorHandler?: AppErrorHandler
    ) {
        this._fetchPageCallback = config.fetchPageCallback || undefined;
        this.router = config.router;
        this.currentRoute = config.currentRoute;

        this.perPage = config.perPage || 20;

        if (this.currentRoute) {
            // Load state from currentRoute
            const snapshot = this.currentRoute.snapshot;

            const snapshotPage = snapshot.queryParams.page;

            if (snapshotPage) {
                this.currentPage = Number(snapshotPage);
            }

            this.currentRoute.queryParams.subscribe(params => {
                let page = params.page;

                if (page) {
                    page = Number(page);
                }

                if (page !== undefined && page !== this.currentPage) {
                    this.currentPage = page;
                    this.fetch();
                }
            });
        }
    }

    get loading() {
        return (this.request && !this.request.closed);
    }

    get fetching() {
        return this.loading;
    }

    /**
     * Cancel any requests that are currently in-flight.
     */
    cancelCurrentRequest() {
        if (this.request) {
            this.request.unsubscribe();
        }
    }

    fetchIfNotAlready() {
        if (!this.fetching) {
            this.fetch();
        }
    }

    fetch(pageNumber = this.currentPage, params: PaginatorApiParams = {}) {
        params.page = pageNumber;
        params.per_page = this.perPage;

        if (this.searchQuery) {
            params.q = this.searchQuery;
        }

        this.cancelCurrentRequest();

        // Reset the current items
        this.pageItems.next(null);

        this.request = this._fetchPageCallback(params)
            .subscribe(response => {
                const pagination = response.meta.pagination;

                this.currentParams = params;
                this.currentPage = pagination.current_page;
                this.currentCount = pagination.count;
                this.perPage = pagination.per_page;

                this.totalPages = pagination.total_pages;
                this.total = pagination.total;

                this._updateQueryParameter('page', this.currentPage);

                this.pageItems.next(response.data);

                this.request.unsubscribe();
            }, error => {
                if (this.errorHandler) {
                    this.errorHandler.handle(error);
                }
            });
    }

    onPageEvent(pageIndex: number) {
        this.fetch(pageIndex + 1);
    }

    onPageSizeEvent(perPage: number) {
        this.perPage = perPage;
        this.fetch(1);
    }

    /**
     * Refresh the current page.
     */
    refresh(): void {
        this.fetch(this.currentPage);
    }

    search(searchQuery) {
        this.searchQuery = searchQuery;

        this._updateQueryParameter('q', this.searchQuery);
    }

    clearSearch() {
        this.searchQuery = null;

        this._updateQueryParameter('q', null);
    }

    _updateQueryParameter(key: string, value: any) {
        const currentRoute = this.currentRoute;
        const router = this.router;

        if (!router || !currentRoute) {
            return;
        }

        const params = {};
        params[key] = value;

        this.router.navigate([], {
            relativeTo: this.currentRoute,
            queryParams: params,
            queryParamsHandling: 'merge',
            replaceUrl: !this.hasUpdatedQueryParams
        });

        this.hasUpdatedQueryParams = true;
    }
}
