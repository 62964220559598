import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { AppCommonModule } from '@shared/common/common.module';
import {
    MinimumContractValueComponent
} from '@shared/modules/contracts/common/minimum-contract-value/minimum-contract-value.component';
import {
    MinimumContractValuePeriodSelectComponent
} from '@shared/modules/contracts/common/minimum-contract-value/select/minimum-contract-value-period-select.component';

import { OverridePanelModule } from '@shared/modules/contracts/common/override-panel/override-panel.module';
import { AppFixedRatesModule } from '@shared/modules/fixed-rates/fixed-rates.module';
import { CallOutChargesComponent } from '@shared/modules/rates/call-out-charges/call-out-charges.component';
import { SpecialDaySelectComponent } from '@shared/modules/special-days/select/special-day-select.component';
import { TaxTypeSelectModule } from '@shared/modules/tax-types/select/tax-type-select.module';
import {
    XeroContractOptionsComponent
} from '@shared/modules/xero/xero-contact-options/xero-contract-options.component';
import { ContractRulesListComponent } from '../../contract-rules/list/contract-rules-list.component';
import { ContractRuleComponent } from '../../contract-rules/rule/contract-rule.component';
import { DurationRoundingComponent } from '../../duration-rounding/duration-rounding.component';
import {
    DurationRoundingStyleSelectComponent
} from '../../duration-rounding/style/select/duration-rounding-style-select.component';
import { FixedRatesCardComponent } from '../../fixed-rates/card/fixed-rates-card.component';
import { FixedRatesComponent } from '../../fixed-rates/fixed-rates.component';
import { HourlyRateRangesComponent } from '../../rates/hourly-rate-ranges/hourly-rate-ranges.component';
import { NonProRataRatesComponent } from '../../rates/non-pro-rata-rates/non-pro-rata-rates.component';

const components = [
    DurationRoundingComponent,
    DurationRoundingStyleSelectComponent,
    HourlyRateRangesComponent,
    NonProRataRatesComponent,
    CallOutChargesComponent,
    FixedRatesCardComponent,
    FixedRatesComponent,
    ContractRulesListComponent,
    ContractRuleComponent,
    MinimumContractValueComponent,
    MinimumContractValuePeriodSelectComponent,
    XeroContractOptionsComponent,
    SpecialDaySelectComponent,
];

@NgModule({
    imports: [
        CommonModule,
        AppCommonModule,
        MatExpansionModule,
        MatSlideToggleModule,
        AppFixedRatesModule,
        OverridePanelModule,
        TaxTypeSelectModule
    ],
    declarations: [
        ...components,
    ],

    exports: [
        ...components,
        OverridePanelModule,
        MatExpansionModule,
        TaxTypeSelectModule
    ],
})
export class ContractsCommonModule {

}
