import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ComponentsModule } from '@carelinelive/ui';
import { MathCeilPipe } from '../pipes/math-ceil.pipe';
import { AppPaginatorComponent } from './paginator.component';

@NgModule({
    declarations: [
        AppPaginatorComponent,
        MathCeilPipe
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatMenuModule,
        MatSelectModule,
        ComponentsModule
    ],
    exports: [
        AppPaginatorComponent
    ]
})
export class AppPaginatorModule {
}
