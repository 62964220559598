import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { secondsToReadable } from '@shared/utils/date-time';
import { isPresent } from '@shared/utils/helpers';

@Pipe({ name: 'appDuration' })
export class DurationPipe implements PipeTransform {
    transform(value: number, defaultString?: string): any {
        if (!isPresent(value)) {
            return value;
        }

        return secondsToReadable(value, defaultString);
    }
}
