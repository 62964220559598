import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppSnackBar } from '@shared/services/snackbar/app-snackbar.service';
import { tap } from 'rxjs/operators';
import type { ApiObservable } from '../../../../../shared/models/api-data';
import type { RegisterBody } from '../models';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
    protected readonly email = window.CareLineLive.registration.email;
    private readonly token = window.CareLineLive.registration.token;
    protected readonly type = ({
        user: 'user',
        care_circle_member: 'Care Circle',
    })[window.CareLineLive.registration.type];

    private readonly errors: string[] = Object.values(window.CareLineLive.registration.errors);

    formGroup = new FormGroup({
        email: new FormControl<string>({
            value: this.email,
            disabled: !!this.email
        }, {
            validators: [Validators.required, Validators.email]
        }),
        password: new FormControl<string>(null, Validators.minLength(8)),
        password_confirmation: new FormControl<string>(null),
    }, {
        validators: [this.auth.passwordConfirmationValidator('password', 'password_confirmation')]
    });

    registrationRequest$: ApiObservable;

    constructor(
        private auth: AuthService,
        private snackBar: AppSnackBar
    ) {
    }

    ngOnInit() {
        if (this.errors.length) {
            this.errors.forEach(error => this.snackBar.showError(error));
        }
    }

    submitForm() {
        const body: RegisterBody = {
            ...this.formGroup.getRawValue(),
            token: this.token
        };

        this.registrationRequest$ = this.auth.register(body).pipe(
            tap(response => {
                if (response.successful) {
                    this.auth.navigateToApplication();
                }
            })
        );
    }
}
