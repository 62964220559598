import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AppStorageService {
    private storage: Storage = window.localStorage;

    set(key, value) {
        try {
            this.storage.setItem(key, value);
        } catch (e) {
            if (e.message.includes('quota')) {
                this.clearEvents();
                this.set(key, value);
            }
        }

        return value;
    }

    private clearEvents() {
        // Remove all old event data
        for (const k in this.storage) {
            if (k.startsWith('event:')) {
                this.storage.removeItem(k);
            }
        }
    }

    get(key, defaultValue) {
        return this.storage.getItem(key) || defaultValue;
    }

    /**
     *
     * @param key
     * @param defaultValue
     */
    getBoolean(key, defaultValue) {
        const value = this.get(key, defaultValue);

        if (value === 'false') {
            return false;
        }

        return Boolean(value);
    }

    remove(key) {
        return this.storage.removeItem(key);
    }

    clear() {
        this.storage.clear();
    }
}
