<app-auth-form-card
    [title]="(passwordResetRequired$ | async) ? 'Sign in' : 'Forgotten your password?'"
    [subTitle]="'Enter your email address below to be sent a link to reset your password'">
    <cll-form-field label="Email Address ">
        <input
            type="email"
            [formControl]="form.controls.email"
            data-testid="input:send-password-reset-email"
            #formInput="cllFormInput"
            (keyup.enter)="sendPasswordReset()"
            cllFormInput/>
    </cll-form-field>

    <button
        cll-filled-button
        colour="secondary"
        [disabled]="sendingPasswordReset || form.invalid"
        data-testid="button:send-password-reset-link"
        (click)="sendPasswordReset()">
        Send password reset link
    </button>
</app-auth-form-card>

<a
    *ngIf="!isFromApp()"
    class="back-to-login"
    [routerLink]="['../login']">
    <cll-icon [size]="14">arrow_back</cll-icon>
    Back to login
</a>
