import type { Observable } from 'rxjs';
import { map, pairwise } from 'rxjs/operators';
import type { ApiError } from '../http/errors/api-error';
import type { ApiResponseMeta } from '../http/responses/api-response';
import { toApiError } from '../services/error-handler/parse';

export type ApiObservable<T = any, Config = ApiRequestStatusConfig> = Observable<ApiRequestStatus<T, Config>>;

export interface ApiDataObject<T> {
    data: T;
}

export interface IApiRequestStatus<T = any> {
    fetching?: boolean;
    data: T;
    error?: any;
}

export type ApiRequestStatusConfig = Record<string, any>;

export class ApiRequestStatus<T = any, Config extends ApiRequestStatusConfig = ApiRequestStatusConfig> implements IApiRequestStatus<T> {
    apiError: ApiError | null;

    protected constructor(
        public readonly data: T,
        public readonly meta: ApiResponseMeta = null,
        public readonly fetching = false,
        public readonly error?: any,
        public readonly config?: Config,
        public readonly success?: boolean
    ) {
        this.apiError = toApiError(error);
    }

    transform<R>(callback: (data: T) => R): ApiRequestStatus<R, Config> {
        if (this.success) {
            return ApiRequestStatus.withData<R, Config>(callback(this.data), this.config);
        }

        if (this.error) {
            return ApiRequestStatus.error<R, Config>(this.error, this.config);
        }

        return ApiRequestStatus.fetching<R, Config>(this.fetching, this.config);
    }

    mergeWith(other: ApiRequestStatus<T, Config>): ApiRequestStatus<T, Config> {
        if (!other) {
            return this;
        }

        return new ApiRequestStatus<T, Config>(
            this.data ?? other.data,
            this.meta ?? other.meta,
            this.fetching,
            this.error,
            this.config,
            this.success
        );
    }

    get successful() {
        return this.success;
    }

    get hasData(): boolean {
        return !!this.data;
    }

    static withData<E, Config = ApiRequestStatusConfig>(data: E, config?: Config, meta?: ApiResponseMeta): ApiRequestStatus<E, Config> {
        return new ApiRequestStatus<E, Config>(data, meta, false, null, config, true);
    }

    static fetching<E, Config = ApiRequestStatusConfig>(fetching = true, config?: Config): ApiRequestStatus<E, Config> {
        return new ApiRequestStatus<E, Config>(null, null, fetching, null, config, false);
    }

    static error<E, Config = ApiRequestStatusConfig>(error: any, config?: Config): ApiRequestStatus<E, Config> {
        return new ApiRequestStatus<E, Config>(null, null, false, error, config, false);
    }

    static init<E, Config = ApiRequestStatusConfig>(config?: Config): ApiRequestStatus<E, Config> {
        return new ApiRequestStatus<E, Config>(null, null, false, null, config, false);
    }

    static keepPreviousData<E>() {
        return (source: Observable<ApiRequestStatus<E>>): Observable<ApiRequestStatus<E>> => source.pipe(
            pairwise(),
            map(([previous, current]) => current.mergeWith(previous))
        );
    }

    static keepPreviousDataIf<E>(condition: boolean) {
        if (condition) {
            return (source: Observable<ApiRequestStatus<E>>): Observable<ApiRequestStatus<E>> => source.pipe(
                pairwise(),
                map(([previous, current]) => current.mergeWith(previous))
            );
        }

        return (source: Observable<ApiRequestStatus<E>>): Observable<ApiRequestStatus<E>> => source;
    }
}
