<mat-form-field [appearance]="appearance" data-testid="select:dropdown">
    <mat-label *ngIf="label">
        {{ label }}
    </mat-label>

    <div matPrefix>
        <ng-content select="[appPrefix]"></ng-content>
    </div>

    <mat-select
        [(ngModel)]="selectedOption"
        [ngModelOptions]="{ standalone: true }"
        [placeholder]="placeholder"
        [required]="required"
        [disabled]="isDisabled"
        panelClass="app-select-form-field-panel"
        (opened)="_focusSearchInput()"
        (selectionChange)="_onOptionSelected($event)"
        [compareWith]="_compareWith">
        <mat-select-trigger>
            <app-select-option *ngIf="selectedOption"
                               [showDescription]="showTriggerDescription"
                               [showMeta]="showTriggerDescription"
                               [option]="selectedOption" />
        </mat-select-trigger>

        <div *ngIf="searchEnabled"
             class="app-form-select-search-container">
            <mat-icon>search</mat-icon>

            <input type="text"
                   #searchInput
                   [formControl]="searchCtrl"
                   [placeholder]="searchPlaceholder"
                   data-testid="input:dropdown-search"
                   (keydown)="$event.stopPropagation()"
                   autofocus>

            <button mat-icon-button
                    *ngIf="searchCtrl.value?.length"
                    (click)="searchCtrl.setValue(null)">
                <mat-icon>clear</mat-icon>
            </button>
        </div>

        <ng-container *ngFor="let option of filteredOptions$ | async">
            <mat-option
                *ngIf="isNotExcluded(option)"
                [value]="option"
                [disabled]="option.disabled"
                [class.has-avatar]="option.has_avatar || option.avatar_url"
                [attr.data-testid]="option.label">
                <app-select-option [option]="option" />
            </mat-option>
        </ng-container>

        <mat-option *ngIf="!(filteredOptions$ | async)?.length"
                    [disabled]="true">
            No options available
        </mat-option>
    </mat-select>

    <div matSuffix>
        <ng-content select="[appSuffix]"></ng-content>

        <mat-spinner [diameter]="20"
                     *ngIf="fetching"
                     matTooltip="Fetching data" />
    </div>

    <button matSuffix
            class="clear-button"
            *ngIf="!disabled && clearable && selectedOption"
            mat-icon-button
            (click)="clear($event)"
            type="button">
        <mat-icon>clear</mat-icon>
    </button>

    <mat-hint *ngIf="hint">
        {{ hint }}
    </mat-hint>

    <mat-error *ngFor="let message of errorMessages">
        {{ message }}
    </mat-error>
</mat-form-field>
