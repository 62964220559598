import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@carelinelive/ui';
import { HelpDeskService } from '@shared/modules/help-desk/help-desk.service';
import { IntercomService } from '@shared/modules/help-desk/intercom.service';
import { HelpArticleDirective } from './help-article.directive';
import { HelpButtonComponent } from './help-button/help-button.component';


@NgModule({
    declarations: [
        HelpArticleDirective,
        HelpButtonComponent
    ],
    imports: [
        CommonModule,
        ComponentsModule
    ],
    exports: [
        HelpArticleDirective,
        HelpButtonComponent
    ],
    providers: [IntercomService, HelpDeskService]
})
export class AppHelpDeskModule {
}
