import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import type { ApiError } from "@shared/http/errors/api-error";
import { toApiError } from "@shared/services/error-handler/parse";

@Component({
    selector: 'app-error-messages',
    templateUrl: './error-messages.component.html',
    styleUrls: ['./error-messages.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class ErrorMessagesComponent {
    protected _error: any;
    protected _apiError: ApiError;

    @Input() set error(value: any) {
        this._error = value;
        this._apiError = toApiError(value);
    }
}
