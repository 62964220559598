import { Component, Input } from '@angular/core';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { AbstractFormFieldComponent } from '@shared/form-fields/abstract-form-field/abstract-form-field.component';
import { makeProvider } from '@shared/form-fields/make-provider';

@Component({
    selector: 'app-input-number',
    styleUrls: ['./number-input.component.scss'],
    template: `
        <mat-form-field [appearance]="appearance">
            <mat-label *ngIf="label">
                {{ label }}
            </mat-label>

            <div matPrefix>
                <ng-content select="[appPrefix]"></ng-content>
            </div>

            <input
                type="number"
                matInput
                [id]="id"
                [autofocus]="focus"
                [(ngModel)]="value"
                [ngModelOptions]="{ standalone: true }"
                [name]="name"
                [placeholder]="placeholder"
                [disabled]="disabled"
                [readonly]="readonly"
                [required]="required"
                [min]="min"
                [max]="max"
                [step]="step"
                [pattern]="pattern"
                (blur)="_onTouch()"
                (mousewheel)="$event.preventDefault()"
            />

            <div matSuffix>
                <ng-content select="[appSuffix]"></ng-content>

                <button class="clear-button"
                        *ngIf="clearable && valuePresent"
                        mat-icon-button
                        (click)="clear($event)">
                    <mat-icon>clear</mat-icon>
                </button>
            </div>

            <mat-hint *ngIf="hint">
                {{ hint }}
            </mat-hint>

            <mat-error *ngFor="let message of errorMessages">{{ message }}</mat-error>
        </mat-form-field>
    `,
    providers: [makeProvider(NumberInputComponent)],
})
export class NumberInputComponent extends AbstractFormFieldComponent<number> {
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() min: number;
    @Input() max: number;
    @Input() step: number;
    @Input() focus: boolean;
    @Input() pattern: string;
}
