import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    SidebarPageLayoutComponent
} from '@shared/common/layout/index-page-layout/page-layout-sidebar/sidebar-page-layout.component';
import { PageSideNavComponent } from '@shared/common/layout/index-page-layout/page-sidenav/page-side-nav.component';
import { PageHeaderModule } from '@shared/common/layout/page-header/page-header.module';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { PageSidebarComponent } from './page-sidebar/page-sidebar.component';


@NgModule({
    declarations: [
        PageLayoutComponent,
        PageSidebarComponent,
        SidebarPageLayoutComponent,
        PageSideNavComponent
    ],
    imports: [
        CommonModule,
        PageHeaderModule,
        ObserversModule,
    ],
    exports: [
        PageLayoutComponent,
        PageSidebarComponent,
        PageHeaderModule,
        SidebarPageLayoutComponent,
        PageSideNavComponent
    ]
})
export class PageLayoutModule {
}
