import type { HttpResponse } from '@angular/common/http';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, filter, map, shareReplay, startWith, tap } from 'rxjs/operators';
import type { ApiResponse } from '../../http/responses/api-response';
import type { ApiObservable, ApiRequestStatusConfig } from '../api-data';
import { ApiRequestStatus } from '../api-data';

export function ifResult<T = any>(callback: (error: ApiRequestStatus<T>) => void): (source: ApiObservable<T>) => ApiObservable<T> {
    return (source: ApiObservable<T>): ApiObservable<T> => source.pipe(
        tap(status => {
            if (status.error || status.successful) {
                callback(status);
            }
        })
    );
}

export function ifError<T = any>(callback: (error: any) => void): (source: ApiObservable<T>) => ApiObservable<T> {
    return (source: ApiObservable<T>): ApiObservable<T> => source.pipe(
        tap(status => {
            if (status.error) {
                callback(status.error);
            }
        })
    );
}

export function ifSuccessful<T, C>(callback: (data: T) => void): (source: ApiObservable<T, C>) => ApiObservable<T, C> {
    return (source: ApiObservable<T, C>): ApiObservable<T, C> => source.pipe(
        tap(status => {
            if (status.successful) {
                callback(status.data);
            }
        })
    );
}

export function ifFetching<T>(callback: () => void): (source: ApiObservable<T>) => ApiObservable<T> {
    return (source: ApiObservable<T>): ApiObservable<T> => source.pipe(
        tap(status => {
            if (status.fetching) {
                callback();
            }
        })
    );
}

export function successfulResult<T>(): (source: ApiObservable<T>) => Observable<T> {
    return (source: ApiObservable<T>): Observable<T> => source.pipe(
        filter(response => response.successful),
        map(response => response.data)
    );
}

export function fetching<T>(): (source: ApiObservable<T>) => Observable<boolean> {
    return (source: ApiObservable<T>): Observable<boolean> => source.pipe(
        map(response => response.fetching),
        shareReplay(1),
    );
}

export function transformResult<T, R>(callback: (data: T) => R): (source: ApiObservable<T>) => ApiObservable<R> {
    return (source: ApiObservable<T>): ApiObservable<R> => source.pipe(
        map((response: ApiRequestStatus<T>) => response.transform(callback))
    );
}

export function toApiObservable<T = any, Config extends ApiRequestStatusConfig = ApiRequestStatusConfig>(
    config?: Config
): (source: Observable<ApiResponse<T>>) => ApiObservable<T, Config> {
    return (source: Observable<ApiResponse<T>>): ApiObservable<T, Config> => source.pipe(
        map((response: ApiResponse<T>): ApiRequestStatus<T, Config> => ApiRequestStatus.withData<T, Config>(response.data, config, response.meta)),
        catchError((error: any): Observable<ApiRequestStatus<T, Config>> => of(ApiRequestStatus.error<T, Config>(error, config))),
        startWith(ApiRequestStatus.fetching<T, Config>(true, config)),
    );
}

export function toHttpResponseSuspense<T = any>(): (source: Observable<HttpResponse<T>>) => ApiObservable<HttpResponse<T>> {
    return (source: Observable<HttpResponse<T>>): ApiObservable<HttpResponse<T>> => source.pipe(
        map((response: HttpResponse<T>): ApiRequestStatus<HttpResponse<T>> => ApiRequestStatus.withData<HttpResponse<T>>(response)),
        catchError((error: any): Observable<ApiRequestStatus<HttpResponse<T>>> => of(ApiRequestStatus.error<HttpResponse<T>>(error))),
        startWith(ApiRequestStatus.fetching<HttpResponse<T>>(true)),
    );
}

export function toVoidSuspense<T = any>(): (source: Observable<void>) => ApiObservable<void> {
    return (source: Observable<void>): ApiObservable<void> => source.pipe(
        map((response: void): ApiRequestStatus<void> => ApiRequestStatus.withData<void>(response)),
        catchError((error: any): Observable<ApiRequestStatus<void>> => of(ApiRequestStatus.error<void>(error))),
        startWith(ApiRequestStatus.fetching<void>(true)),
    );
}

export function toTextSuspense(): (source: Observable<string>) => ApiObservable<string> {
    return (source: Observable<string>): ApiObservable<string> => source.pipe(
        map((response: string): ApiRequestStatus<string> => ApiRequestStatus.withData<string>(response)),
        catchError((error: any): Observable<ApiRequestStatus<string>> => of(ApiRequestStatus.error<string>(error))),
        startWith(ApiRequestStatus.fetching<string>(true)),
    );
}

export function toRawApiObservable<T, Config = ApiRequestStatusConfig>(
    config?: Config
): (source: Observable<T>) => ApiObservable<T, Config> {
    return (source: Observable<T>): ApiObservable<T, Config> => source.pipe(
        map((response: T): ApiRequestStatus<T, Config> => ApiRequestStatus.withData<T, Config>(response, config)),
        catchError((error: any): Observable<ApiRequestStatus<T, Config>> => of(ApiRequestStatus.error<T, Config>(error, config))),
        startWith(ApiRequestStatus.fetching<T, Config>(true, config)),
    );
}

export function combineRequestStatuses<T, R>(combine: (statuses: any[]) => R) {
    return (source: Observable<ApiRequestStatus<T>[]>): ApiObservable<R> => source.pipe(
        map((responses: ApiRequestStatus<T>[]): ApiRequestStatus<R> => {
            const anyFetching = responses.some(response => response.fetching);

            if (anyFetching) {
                return ApiRequestStatus.fetching<R>(anyFetching);
            }

            const errored = responses.find(response => response.error);

            if (errored) {
                return ApiRequestStatus.error<R>(errored.error);
            }

            return ApiRequestStatus.withData<R>(
                combine(responses.map(response => response.data))
            );
        })
    );
}
