/* eslint-disable @angular-eslint/directive-class-suffix */
import type { OnDestroy } from '@angular/core';
import { Directive } from '@angular/core';
import { trackByProperty, trackById } from '@shared/utils';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class AbstractComponent implements OnDestroy {
    protected unsubscribe$ = new Subject<void>();

    public trackBy = trackByProperty;
    public trackById = trackById;

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    takeUntilDestroyed() {
        return <T>(source: Observable<T>) => source.pipe(takeUntil(this.unsubscribe$));
    }
}
