import { Component, Input } from '@angular/core';
import { AppCommonModule } from '@shared/common/common.module';
import { PopoverModule } from '@shared/common/popover/popover.module';
import { AppSharedModule } from '@shared/shared.module';
import {
    numberOfLowerCaseLetters,
    numberOfUpperCaseLetters,
    numberOfSymbols,
    numberOfNumbers,
} from '@shared/utils/strings';
import { IndicatorComponent } from './components/indicator/indicator.component';
import { PasswordStrengthPopoverComponent } from './components/password-strength-popover/password-strength-popover.component';

@Component({
    selector: 'app-password-strength-indicator',
    templateUrl: './password-strength-indicator.component.html',
    styleUrls: ['./password-strength-indicator.component.scss'],
    standalone: true,
    imports: [PopoverModule, AppCommonModule, AppSharedModule, IndicatorComponent, PasswordStrengthPopoverComponent],
})
export class PasswordStrengthIndicatorComponent {
    @Input() set password(input: string) {
        this.updatePasswordStrength(input);
    }

    public ui = {
        fulfilLength: false,
        hasBothCases: false,
        hasSymbol: false,
        hasNumber: false,
    };

    public points = 0;
    public index;
    public label = 'Password strength';

    public indicatorColors = [
        { at: 1, color: '#d12f2d' },
        { at: 2, color: '#fca821' },
        { at: 3, color: '#06885c' },
        { at: 4, color: '#06885c' },
    ];

    private updatePasswordStrength(password: string) {
        this.points = this.passwordStrengthIndex(password);
        this.onPasswordChanged(password, this.points);
    }

    private passwordStrengthIndex(password: string): number {
        let point = 0;
        const length = password?.length || 0;

        if (password) {
            point += this.checkPasswordLengthPoints(password);
            point += this.checkStringCase(password);
            point += this.checkSymbols(password);
            point += this.checkNumbers(password);
        }

        if (!password || length < 8) {
            return 0;
        }

        return point > 10 ? 10 : point;
    }

    // Length of password :
    // < 8 : 0 points,
    // >= 8 < 10 : 1 point,
    // >= 10 : 2 points,
    // >= 16 : 4 points

    private checkPasswordLengthPoints(password: string): number {
        const length = password?.length || 0;
        this.ui.fulfilLength = length >= 8;

        if (!password || length < 8) {
            return 0;
        }

        if (length < 10) {
            return 1;
        }

        if (length < 16) {
            return 2;
        }

        return 4;
    }

    // Mixed case:
    // Same case throughout = 0 points
    // >= 1 && password <=2 character different case = 1 point
    // > 2 characters with a different case = 2 points

    private checkStringCase(password: string): number {
        let points = 0;
        this.ui.hasBothCases =
            !!password && numberOfLowerCaseLetters(password) > 0 && numberOfUpperCaseLetters(password) > 0;
        if (!password || password.toLowerCase() === password || password.toUpperCase() === password) {
            return points;
        } else {
            points += 1;
            if (Math.min(numberOfLowerCaseLetters(password), numberOfUpperCaseLetters(password)) > 2) {
                points += 1;
            }
        }

        return points;
    }

    // Symbols:  No symbols : 0 points
    // 1 symbol :  1 point
    // >= 2 symbols : 2 points

    private checkSymbols(password: string): number {
        let points = 0;

        if (numberOfSymbols(password) > 0) {
            points += 1;
            if (numberOfSymbols(password) > 1) {
                points += 1;
            }
        }
        this.ui.hasSymbol = !!password && points > 0;
        return points;
    }

    // Numbers: No numbers : 0 points
    // 1 number : 1 point
    // >= 2 numbers : 2 points
    private checkNumbers(password: string): number {
        let points = 0;

        if (numberOfNumbers(password) > 0) {
            points += 1;
            if (numberOfNumbers(password) > 1) {
                points += 1;
            }
        }
        this.ui.hasNumber = !!password && points > 0;

        return points;
    }

    public formatLabel(value: number) {
        return value;
    }

    //< 2 very weak
    //< 6 weak
    //< 10 good
    //>= 10 strong
    onPasswordChanged(password: string, points: number) {
        this.label = 'Password strength';
        this.index = 0;
        if (!!password) {
            if (points < 2) {
                this.label = 'Very Weak';
                this.index = 1;
            } else if (points < 6) {
                this.label = 'Weak';
                this.index = 2;
            } else if (points < 10) {
                this.label = 'Good';
                this.index = 3;
            } else {
                this.label = 'Strong';
                this.index = 4;
            }
        }
    }
}
