import { HttpClient } from '@angular/common/http';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { ResultObservable } from '@mastechnicaegroup/carelinelive-cdk/rx';
import { mapResult, successfulResult, toResult } from '@mastechnicaegroup/carelinelive-cdk/rx';
import { DialogService } from '@mastechnicaegroup/carelinelive-ui';
import type { ApiResponse } from '@shared/http/responses/api-response';
import { RefreshToken } from '@shared/rx/refresh-token';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { AgreementViewDialogComponent } from './dialogs/agreement-view-dialog.component';
import type { UserAgreementItem } from './user-agreement-item';

interface UiState {
    agreements: UserAgreementItem[];
    finished: boolean;
}

@Component({
    selector: 'app-user-agreements',
    templateUrl: './user-agreements.component.html',
    styleUrls: ['./user-agreements.component.scss'],
})
export class UserAgreementsComponent {
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _dialog = inject(DialogService);
    private readonly _http = inject(HttpClient);

    private readonly _refreshToken$ = new RefreshToken();

    agreementsRequest$: ResultObservable<UiState> = this._refreshToken$.pipe(
        switchMap(() => this._http.get<ApiResponse<UserAgreementItem[]>>('/auth/agreements', {
                responseType: 'json',
            }).pipe(
                map(response => response.data),
                toResult(),
                mapResult(agreements => agreements.sort((a, b) => a.accepted ? 1 : -1)),
                mapResult(agreements => ({
                    agreements,
                    finished: agreements.every(agreement => agreement.accepted)
                }))
            )
        ),
        shareReplay(1),
    );

    uiState$ = this.agreementsRequest$.pipe(
        successfulResult(),
    );

    showAgreement($event: MouseEvent, agreement: UserAgreementItem): void {
        $event.preventDefault();
        $event.stopPropagation();

        AgreementViewDialogComponent.show(this._dialog, {
            agreement
        }).closed.pipe(
            takeUntilDestroyed(this._destroyRef),
            filter(accepted => accepted),
        ).subscribe(() => {
            this._refreshToken$.refresh();
        });
    }
}
