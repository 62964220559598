<cll-card
    @fade
    appearance="elevated"
    class="auth-form-card">
    <div class="card-content">
        <div class="auth-card__title"
             data-testid="title:auth-card">
            {{ title }}
        </div>

        <div class="auth-card__sub-title"
             data-testid="subtitle:auth-card">
            {{ subTitle }}
        </div>

        <ng-content />

        <div class="contact-information-container" *ngIf="showHelpContact">
            <h3 class="text-body-medium">
                Having trouble logging in?
            </h3>

            <div class="contact-information">
                <a href="tel:+4403300885767">
                    <cll-icon>phone</cll-icon>
                    +44 (0)3300 885 767
                </a>
                <a href="mailto:support@carelinelive.com">
                    <cll-icon>mail</cll-icon>
                    support&#64;carelinelive.com
                </a>
            </div>
        </div>
    </div>
</cll-card>
