import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { AbstractFormFieldComponent } from '@shared/form-fields/abstract-form-field/abstract-form-field.component';
import { makeProvider } from '@shared/form-fields/make-provider';
import { LuxonDateAdapter } from '@shared/form-fields/luxon-date-adapter';
import { toDateTime } from '@shared/utils/date-time';
import isString from 'lodash-es/isString';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-input-time',
    styleUrls: ['./time-input.component.scss'],
    template: `
        <mat-form-field [appearance]="appearance">
            <mat-label *ngIf="label">
                {{ label }}
            </mat-label>

            <div matPrefix>
                <ng-content select="[appPrefix]"></ng-content>
            </div>

            <input
                    matInput
                    #timeInput
                    type="time"
                    [(ngModel)]="time"
                    [ngModelOptions]="{ standalone: true, updateOn: 'blur' }"
                    [placeholder]="placeholder || 'HH:mm'"
                    [disabled]="disabled"
                    [required]="required"
                    [min]="min"
                    [max]="max"
                    [readonly]="readonly"/>

            <div matSuffix>
                <ng-content select="[appSuffix]"></ng-content>

                <button mat-icon-button
                        *ngIf="clearable"
                        (click)="clear($event)"
                        matTooltip="Clearable">
                    <mat-icon>clear</mat-icon>
                </button>
            </div>

            <mat-hint *ngIf="hint">
                {{ hint }}
            </mat-hint>

            <mat-error *ngFor="let message of errorMessages">{{ message }}</mat-error>
        </mat-form-field>
    `,
    providers: [makeProvider(TimeInputComponent)],
})
export class TimeInputComponent extends AbstractFormFieldComponent<string | DateTime> {
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() valueType: 'string' | 'datetime' = 'string';

    @Input() min: string;
    @Input() max: string;

    @ViewChild('timeInput', { static: true }) timeInput: ElementRef<HTMLInputElement>;


    _time: string;

    get time() {
        return this._time;
    }

    set time(time: string) {
        this._time = time;

        if (this.valueType === 'datetime' || this._value instanceof DateTime) {
            const dateTime = DateTime.fromFormat(time, 'HH:mm');

            if (!(this._value instanceof DateTime)) {
                this._value = this.luxonDateAdapter.parse(this._value) || DateTime.local();
            }

            if (dateTime.isValid) {
                this.value = this._value.set({
                    hour: dateTime.hour,
                    minute: dateTime.minute,
                    second: dateTime.second,
                    millisecond: 0,
                });
            } else {
                this.value = this._value.set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                });
            }
        } else {
            this.value = time;
        }
    }

    constructor(
        element: ElementRef<any>,
        renderer: Renderer2,
        protected luxonDateAdapter: LuxonDateAdapter
    ) {
        super(element, renderer);
    }

    writeValue(value: string | DateTime) {
        if (value instanceof DateTime) {
            this._time = value.toFormat('HH:mm');
        } else if (isString(value)) {
            if (value.length === 'HH:mm'.length || value.length === 'HH:mm:ss'.length) {
                this._time = value;
            } else {
                this._time = toDateTime(value).toFormat('HH:mm');
            }
        }

        this._value = value;

        if (!this._value) {
            this.timeInput.nativeElement.value = null;
        }

        // this.onChange(value);
    }
}
