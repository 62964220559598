import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-dialog-header',
    styleUrls: ['./header.component.scss'],
    template: `
        <ng-content select="app-dialog-icon"></ng-content>
        <ng-content class="app-dialog-avatar" select="[appDialogAvatar]"></ng-content>
        <ng-content class="app-dialog-icon" select="[appDialogIcon]"></ng-content>

        <h2 mat-dialog-title>
            <ng-content select="app-dialog-title"></ng-content>

            <small>
                <ng-content select="app-dialog-subtitle"></ng-content>
            </small>
        </h2>

        <nav>
            <ng-content select="nav,[appDialogNav]"></ng-content>

            <button *ngIf="showCloseIcon"
                    mat-icon-button
                    data-testid="icon:close"
                    [matDialogClose]="closeResult">
                <mat-icon>close</mat-icon>
            </button>
        </nav>
    `,
})
export class DialogHeaderComponent {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() showCloseIcon = true;
    @Input() closeResult: any;
}

@Component({
    selector: 'app-dialog-title',
    template: `
        <ng-content></ng-content>
    `,
})
export class DialogTitleComponent {
}

@Component({
    selector: 'app-dialog-subtitle',
    styles: [
        ':host { display: block }'
    ],
    template: `
        <ng-content></ng-content>
    `,
})
export class DialogSubtitleComponent {
}

@Component({
    selector: 'app-dialog-icon',
    template: `
        <ng-content></ng-content>
    `,
})
export class DialogIconComponent {
}
