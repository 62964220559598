import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PopoverDirective } from '@shared/common/popover/popover.directive';


@NgModule({
    declarations: [
        PopoverDirective,
    ],
    imports: [
        CommonModule
    ],
    exports: [
        PopoverDirective
    ]
})
export class PopoverModule {
}
