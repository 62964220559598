<mat-tab-group #optionSetTabs>
    <mat-tab *ngFor="let optionSet of optionSets; trackBy: _trackOptionSetFn">
        <ng-template matTabLabel>
            {{ (optionSet.applies_from | date) || 'Default' }}
        </ng-template>

        <ng-template matTabContent>
            <mat-card>
                <mat-card-content>
                    <table class="pay-elements-table">
                        <colgroup>
                            <col style="width: 196px;">
                            <col>
                            <col>
                            <col style="width: 192px">
                        </colgroup>

                        <thead>
                        <tr>
                            <th>Rate</th>
                            <th colspan="2">Value</th>
                            <th class="code-cell">Pay Element Code</th>
                        </tr>
                        </thead>

                        <tbody *ngIf="ui.show[optionSet.id]?.hourlyRates">
                        <tr>
                            <th>Hourly Rate</th>
                            <td colspan="2" class="value-cell">{{ optionSet.hourly_rate / 100 | currency }}</td>
                            <td class="code-cell">
                                <input type="text"
                                       placeholder="Enter pay element code"
                                       [(ngModel)]="optionSet.pay_element_code_hourly_rate">
                            </td>
                        </tr>

                        </tbody>
                        <tbody>

                        <tbody *ngIf="ui.show[optionSet.id].guaranteedPay">
                        <tr>
                            <th>Guaranteed Weekly Pay</th>
                            <td colspan="2" class="value-cell">{{ optionSet.guaranteed_pay_week / 100 | currency }}</td>
                            <td class="code-cell">
                                <input type="text"
                                       placeholder="Enter pay element code"
                                       [(ngModel)]="optionSet.pay_element_code_guaranteed_pay">
                            </td>
                        </tr>
                        </tbody>

                        <tbody *ngIf="ui.show[optionSet.id].nonProRataRates">
                        <tr>
                            <th [rowSpan]="optionSet.non_pro_rata_rates?.length + 1">Non-pro-rata rates</th>
                        </tr>

                        <tr *ngFor="let rate of optionSet.non_pro_rata_rates">
                            <td colspan="2" class="value-cell">
                                {{ rate.duration | appDuration }}
                                <small class="hint">({{ rate.amount / 100 | currency }})</small>
                            </td>

                            <td class="code-cell">
                                <input type="text"
                                       placeholder="Enter pay element code"
                                       [(ngModel)]="rate.pay_element_code">
                            </td>
                        </tr>

                        </tbody>

                        <tbody *ngIf="ui.show[optionSet.id].hourlyRateRanges">
                        <tr>
                            <th [rowSpan]="countHourlyRateRangesRows(optionSet) + 1">Unsociable Hours</th>
                        </tr>

                        <ng-container *ngFor="let range of optionSet.hourly_rate_ranges">
                            <tr>
                                <td class="value-cell" [rowSpan]="range.non_pro_rata_rates?.length + 1">
                                    {{ this.getHourlyRateRangeDayName(range.day) }}

                                    <div class="hint">({{ range.start }} - {{ range.finish }})</div>
                                </td>

                                <td class="value-cell">
                                    Hourly <small class="hint">({{ range.hourly_rate / 100 | currency }})</small>
                                </td>

                                <td class="code-cell">
                                    <input type="text"
                                           placeholder="Enter pay element code"
                                           [(ngModel)]="range.pay_element_code">
                                </td>
                            </tr>

                            <tr *ngFor="let rate of range.non_pro_rata_rates">
                                <td class="value-cell">
                                    {{ rate.duration | appDuration }}
                                    <small class="hint">({{ rate.amount / 100 | currency }})</small>
                                </td>

                                <td class="code-cell">
                                    <input type="text"
                                           placeholder="Enter pay element code"
                                           [(ngModel)]="rate.pay_element_code">
                                </td>
                            </tr>
                        </ng-container>

                        </tbody>

                        <tbody *ngIf="ui.show[optionSet.id].fixedRates">
                        <tr>
                            <th [rowSpan]="countFixedRateRows(optionSet) + 1">
                                Fixed Rates
                            </th>
                        </tr>

                        <ng-container *ngFor="let rate of optionSet.fixed_rates.data">
                            <tr>
                                <td class="value-cell" [rowSpan]="rate.rules?.length + 1">
                                    {{ rate.name }}
                                </td>

                                <td class="value-cell">
                                    {{ rate.amount / 100 | currency }}
                                </td>

                                <td class="code-cell">
                                    <input type="text"
                                           placeholder="Enter pay element code"
                                           [(ngModel)]="rate.pay_element_code">
                                </td>
                            </tr>

                            <tr *ngFor="let rule of rate.rules">
                                <td class="value-cell">
                                    Rule: {{ rule.name }}
                                </td>

                                <td class="code-cell">
                                    <input type="text"
                                           placeholder="Enter pay element code"
                                           [(ngModel)]="rule.pay_element_code">
                                </td>
                            </tr>
                        </ng-container>
                        </tbody>

                        <tbody *ngIf="ui.show[optionSet.id].travelTime">
                        <tr>
                            <th>Travel time</th>
                            <td class="value-cell" colspan="2">
                                <ng-container *ngIf="!optionSet.pay_travel_time_using_hourly_rate">
                                    {{ optionSet.mileage_travel_time_rate_per_minute / 100 | currency }} per minute
                                </ng-container>

                                <ng-container *ngIf="optionSet.pay_travel_time_using_hourly_rate">
                                    <ng-container *ngIf="optionSet.mileage_travel_time_rate_per_hour as
                                    travelTimeHourlyRate">
                                        Paid at {{ travelTimeHourlyRate / 100 | currency }} per hour
                                    </ng-container>

                                    <ng-container *ngIf="!optionSet.mileage_travel_time_rate_per_hour">
                                        Paid at hourly rate
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td class="code-cell">
                                <input type="text"
                                       placeholder="Enter pay element code"
                                       [(ngModel)]="optionSet.pay_element_code_travel_time">
                            </td>
                        </tr>
                        </tbody>

                        <tbody *ngIf="ui.show[optionSet.id].mileageRates">
                        <tr>
                            <th>Mileage Rates</th>
                            <td colspan="3">
                                <app-mileage-bands
                                    [(bands)]="optionSet.mileage_rates"
                                    [editingPayElementCodes]="true" />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>

            <app-override-panel *ngIf="hasOverrides"
                                [(override)]="optionSet.override_leave_type_pay_elements">
                <app-panel-title>Leave</app-panel-title>
                <ng-container *ngTemplateOutlet="leaveTypesTable" />
            </app-override-panel>

            <mat-card *ngIf="!hasOverrides">
                <mat-card-header>
                    <mat-card-title>Leave</mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    <ng-container *ngTemplateOutlet="leaveTypesTable" />
                </mat-card-content>
            </mat-card>

            <ng-template #leaveTypesTable>
                <table class="pay-elements-table">
                    <colgroup>
                        <col style="width: 196px;">
                        <col>
                        <col style="width: 192px">
                    </colgroup>

                    <thead>
                    <tr>
                        <th>Rate</th>
                        <th>Default pay element code</th>
                        <th class="code-cell">Pay Element Code</th>
                    </tr>
                    </thead>

                    <tbody *ngIf="ui.show[optionSet.id]?.leave">
                    <tr *ngFor="let type of leaveTypes">
                        <td>{{ type.name }}</td>
                        <td>
                            {{ type.pay_element_code }}
                        </td>

                        <td class="code-cell">
                            <input type="text"
                                   placeholder="Enter pay element code"
                                   [(ngModel)]="optionSet.leave_type_pay_elements[type.id]">
                        </td>
                    </tr>
                    </tbody>
                </table>
            </ng-template>

        </ng-template>
    </mat-tab>
</mat-tab-group>
