import { Component, ElementRef, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'app-snackbar-progress',
    template: `
        <div>
            <mat-spinner [diameter]="16" />

            <span>{{ data.message }}</span>
        </div>

        <div class="mat-simple-snackbar-action" *ngIf="hasAction">
            <button mat-button (click)="action()">{{ data.action }}</button>
        </div>
    `,
    styleUrls: ['./app-progress-snackbar.component.scss'],
})
export class AppProgressSnackbarComponent {
    /** Data that was injected into the snack bar. */
    data: { message: string; action: string };

    public icon: string;

    constructor(
        private elementRef: ElementRef,
        public snackBarRef: MatSnackBarRef<AppProgressSnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) data: any) {
        this.data = data;
    }

    /** Performs the action on the snack bar. */
    action(): void {
        this.snackBarRef.dismissWithAction();
    }

    /** If the action button should be shown. */
    get hasAction(): boolean {
        return !!this.data.action;
    }
}
