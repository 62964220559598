<cll-panel colour="error">
    <cll-icon cllPanelIcon>error_outline</cll-icon>

    <ng-content #message></ng-content>

    <ng-container *ngIf="!message">
        <ng-container *ngIf="_apiError?.is4xx()">
            <strong>Error</strong>
        </ng-container>

        <ng-container *ngIf="_apiError?.is5xx()">
            <strong>An unexpected error occurred</strong>
        </ng-container>

        <app-error-messages [error]="_error"/>


        <p class="text-body-small" *ngIf="_apiError?.suggestion as suggestion">
            {{ suggestion }}
        </p>
    </ng-container>
</cll-panel>
