<cll-dialog-layout>
    <cll-dialog-header>
        <cll-dialog-title>{{ data.agreement.name }}</cll-dialog-title>
    </cll-dialog-header>

    <cll-panel
        colour="info"
        *ngIf="data.agreement.accepted">
        <cll-icon cllPanelIcon>check</cll-icon>

        You've already accepted this agreement.
    </cll-panel>

    <div
        class="agreement-content"
        [innerHtml]="data.agreement.content | safe"></div>

    <app-alert-api-error
        *ngIf="acceptRequest.error$ | async as error"
        [error]="error" />

    <cll-dialog-footer>
        <cll-checkbox
            data-testid="checkbox:acknowledge"
            *ngIf="!data.agreement.accepted"
            [formControl]="form.controls.accept"
            >I have read and agreed to the Privacy Policy</cll-checkbox
        >

        <button
            cll-filled-button
            class="accept-button"
            colour="secondary"
            *ngIf="!data.agreement.accepted"
            [disabled]="(acceptRequest.fetching$ | async) || form.invalid"
            data-testid="button:accept"
            (click)="accept()">
            <app-loading-label [loading]="acceptRequest.fetching$ | async">
                <cll-icon>check</cll-icon>
                Accept
            </app-loading-label>
        </button>
    </cll-dialog-footer>
</cll-dialog-layout>
