import { Component, ContentChild, Input, ViewChild } from '@angular/core';
import type { ApiError } from '@shared/http/errors/api-error';
import { toApiError } from '@shared/services/error-handler/parse';

@Component({
    selector: 'app-alert-api-error',
    templateUrl: './api-error-alert.component.html',
    styleUrls: ['./api-error-alert.component.scss']
})
export class ApiErrorAlertComponent {
    @ContentChild('message') message: any;

    public _error: any;
    public _apiError: ApiError;

    @Input() set error(value: any) {
        this._error = value;
        this._apiError = toApiError(value);
    }
}
