<ng-container *ngIf="agreementsRequest$ | async as request">
    <app-spinner *ngIf="request.isLoading"/>

    <app-alert-api-error
        *ngIf="request.error as error"
        [error]="error"/>

    <ng-container *ngIf="request.value as state">
        <app-auth-form-card
            [title]="'Policies'"
            [subTitle]="'Please view and accept the following policies before continuing with CareLineLive'">
            <div class="agreements-list">
                <div
                    *ngFor="let agreement of state.agreements"
                    class="agreement-item"
                    [class.agreement-item--accepted]="agreement.accepted">
                    <div>
                        {{ agreement.name }}
                    </div>

                    <button
                        (click)="showAgreement($event, agreement)"
                        *ngIf="!agreement.accepted"
                        cll-outline-button
                        colour="secondary"
                        data-testid="button:view-and-accept">
                        View and accept
                    </button>

                    <button
                        (click)="showAgreement($event, agreement)"
                        *ngIf="agreement.accepted"
                        cll-button
                        colour="variant">
                        View
                    </button>
                </div>
            </div>

            <ng-container *ngIf="!state.finished">
                <cll-panel colour="warning">
                    <cll-icon cllPanelIcon>warning</cll-icon>

                    Please accept all policies before continuing.
                </cll-panel>
            </ng-container>

            <div style="text-align: right">
                <a [disabled]="!state.finished"
                   class="continue-button"
                   cll-filled-button
                   colour="secondary"
                   data-testid="button:continue"
                   href="/"
                   target="_self">
                    <cll-icon cllButtonIcon>check</cll-icon>
                    Continue
                </a>
            </div>
        </app-auth-form-card>


        <a class="logout-button"
           href="/auth/logout"
           target="_self"
           *ngIf="!state.finished"
           cllCardFooterStart>
            Logout
        </a>
    </ng-container>
</ng-container>
