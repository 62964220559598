import { Component } from '@angular/core';
import { NgOptimizedImage } from "@angular/common";

@Component({
    selector: 'app-auth-logo',
    templateUrl: './auth-logo.component.html',
    styleUrls: ['./auth-logo.component.scss'],
    standalone: true,
    imports: [NgOptimizedImage]
})
export class AuthLogoComponent {

}
