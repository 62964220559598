import { MapMarkerType } from './map-marker-type.enum';
import Icon = google.maps.Icon;
import Point = google.maps.Point;
import Size = google.maps.Size;

export const mapMarkerIcons: { [type: string]: Icon } = {
    [MapMarkerType.home]: {
        url: '/img/maps/marker_home.png',
        scaledSize: new Size(48, 48),
        anchor: new Point(24, 24)
    },
    [MapMarkerType.company]: {
        url: '/img/maps/marker_company.png',
        scaledSize: new Size(48, 48),
        anchor: new Point(24, 24)
    },
    [MapMarkerType.carerMale]: {
        url: '/img/maps/marker_carer_male.png',
        scaledSize: new Size(48, 48),
        anchor: new Point(24, 24)
    },
    [MapMarkerType.carerFemale]: {
        url: '/img/maps/marker_carer_female.png',
        scaledSize: new Size(48, 48),
        anchor: new Point(24, 24)
    },
    [MapMarkerType.clientMale]: {
        url: '/img/maps/marker_client_male.png',
        scaledSize: new Size(48, 48),
        anchor: new Point(24, 24)
    },
    [MapMarkerType.clientFemale]: {
        url: '/img/maps/marker_client_female.png',
        scaledSize: new Size(48, 48),
        anchor: new Point(24, 24)
    },
    [MapMarkerType.checkIn]: {
        url: '/img/maps/marker_check_in.png',
        scaledSize: new Size(48, 48),
        anchor: new Point(24, 24)
    },
    [MapMarkerType.checkOutUnconfirmed]: {
        url: '/img/maps/marker_check_out_unconfirmed.png',
        scaledSize: new Size(48, 48),
        anchor: new Point(24, 24)
    },
    [MapMarkerType.checkOutConfirmed]: {
        url: '/img/maps/marker_check_out_confirmed.png',
        scaledSize: new Size(48, 48),
        anchor: new Point(24, 24)
    },
};

export function mapMarkerForPerson(personType: 'carer' | 'client', gender: 'm' | 'f' | string = 'm'): Icon {
    const map = {
        carer: {
            m: MapMarkerType.carerMale,
            f: MapMarkerType.carerFemale,
            other: MapMarkerType.carerFemale
        },
        client: {
            m: MapMarkerType.clientMale,
            f: MapMarkerType.clientFemale,
            other: MapMarkerType.clientFemale,
        }
    };

    return mapMarkerIcons[map[personType][gender ?? 'other']];
}
