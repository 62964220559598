import { Injectable } from '@angular/core';
import type { ChartType } from 'chart.js';
import { CarePackageVisibility } from '../../modules/clients/care-package-visibility';
import { AppStorageService } from '../storage/storage.service';
import { Preferences } from './preferences';

@Injectable({
    providedIn: 'root',
})
export class PreferencesService {
    private cache = new Map<string, any>();

    constructor(private storage: AppStorageService) {
    }

    get rosterParallelFetching(): boolean {
        return this.storage.getBoolean(Preferences.RosterParallelFetching, true);
    }

    set rosterParallelFetching(value: boolean) {
        this.set(Preferences.RosterParallelFetching, value);
    }

    get shouldShowBookingGhost(): boolean {
        return this.storage.getBoolean(Preferences.ShowBookingGhost, false);
    }

    get shouldUseNewRoster(): boolean {
        return this.getBoolean(Preferences.UseNewRoster, false);
    }

    set shouldUseNewRoster(value: boolean) {
        this.set(Preferences.UseNewRoster, value);
    }

    set disableStickyRows(value: boolean) {
        this.set(Preferences.DisableStickyRows, value);
    }

    get disableStickyRows(): boolean {
        return this.getBoolean(Preferences.DisableStickyRows, false);
    }

    set autoRefresh(value: boolean) {
        this.set(Preferences.AutoRefresh, value);
    }

    get autoRefresh(): boolean {
        return this.getBoolean(Preferences.AutoRefresh, false);
    }

    set onlyShowAvailableWorkers(value: boolean) {
        this.set(Preferences.OnlyShowAvailableWorkers, value);
    }

    get onlyShowAvailableWorkers(): boolean {
        return this.getBoolean(Preferences.OnlyShowAvailableWorkers, false);
    }

    set showCancelledBookings(value: boolean) {
        this.set(Preferences.ShowCancelledBookings, value);
    }

    get showCancelledBookings(): boolean {
        return this.getBoolean(Preferences.ShowCancelledBookings, true);
    }

    set showClientScheduleEntries(value: boolean) {
        this.set(Preferences.showClientScheduleEntries, value);
    }

    get showClientScheduleEntries(): boolean {
        return this.getBoolean(Preferences.showClientScheduleEntries, true);
    }

    set disableTravel(value: boolean) {
        this.set(Preferences.DisableTravel, value);
    }

    get disableTravel(): boolean {
        return this.getBoolean(Preferences.DisableTravel, false);
    }

    set showUnallocatedRowsForSelectedTeams(value: boolean) {
        this.set(Preferences.ShowUnallocatedRowsForSelectedTeams, value);
    }

    get showUnallocatedRowsForSelectedTeams(): boolean {
        return this.getBoolean(Preferences.ShowUnallocatedRowsForSelectedTeams, false);
    }

    set rosterZoomLevel(value: number) {
        this.set(Preferences.RosterZoomLevel, value);
    }

    get rosterZoomLevel(): number {
        return this.getNumber(Preferences.RosterZoomLevel, 0);
    }

    set collapseProfileTabs(value: boolean) {
        this.set(Preferences.ProfilesCollapseTabs, value);
    }

    get collapseProfileTabs(): boolean {
        return this.getBoolean(Preferences.ProfilesCollapseTabs, false);
    }

    set collapseAlertNav(value: boolean) {
        this.set(Preferences.AlertsCollapseNav, value);
    }

    get collapseAlertNav(): boolean {
        return this.getBoolean(Preferences.AlertsCollapseNav, false);
    }

    set collapseCapacityNav(value: boolean) {
        this.set(Preferences.CapacityCollapseNav, value);
    }

    get collapseCapacityNav(): boolean {
        return this.getBoolean(Preferences.CapacityCollapseNav, false);
    }

    set customiseCarePackageSummary(value: boolean) {
        this.set(Preferences.CarePackageSummaryCustomise, value);
    }

    get customiseCarePackageSummary(): boolean {
        return this.getBoolean(Preferences.CarePackageSummaryCustomise, false);
    }

    set carePackageSummaryVisibility(value: CarePackageVisibility[]) {
        this.set(Preferences.CarePackageSummaryVisibility, value?.join('||'));
    }

    get carePackageSummaryVisibility(): CarePackageVisibility[] {
        const value: string = this.get(Preferences.CarePackageSummaryVisibility, null);

        return value?.split('||')
            ?.map(key => CarePackageVisibility[key])
            ?.filter(Boolean);
    }

    set financeChartType(value: ChartType) {
        this.set(Preferences.FinanceChartType, value);
    }

    get financeChartType(): ChartType {
        return this.get(Preferences.FinanceChartType, null);
    }

    set navigationShowing(value: boolean) {
        this.set(Preferences.NavigationShowing, value ? 'true' : 'false');
    }

    get navigationShowing(): boolean {
        return this.getBoolean(Preferences.NavigationShowing, false);
    }

    set includeUpcomingVisits(value: boolean) {
        this.set(Preferences.IncludeUpcomingVisits, value);
    }

    get includeUpcomingVisits(): boolean {
        return this.getBoolean(Preferences.IncludeUpcomingVisits, false);
    }

    set showClientPriority(value: boolean) {
        this.set(Preferences.ShowClientPriority, value);
    }

    get showClientPriority(): boolean {
        return this.getBoolean(Preferences.ShowClientPriority, false);
    }

    set clientAgendaLayoutList(value: boolean) {
        this.set(Preferences.PreferenceClientAgendaLayoutList, value);
    }

    get clientAgendaLayoutList(): boolean {
        return this.getBoolean(Preferences.PreferenceClientAgendaLayoutList, false);
    }

    set carerAgendaLayoutList(value: boolean) {
        this.set(Preferences.PreferenceCarerAgendaLayoutList, value);
    }

    get carerAgendaLayoutList(): boolean {
        return this.getBoolean(Preferences.PreferenceCarerAgendaLayoutList, false);
    }

    set carersIndexShowActivity(value: boolean) {
        this.set(Preferences.CarersIndexShowActivity, value);
    }

    get carersIndexShowActivity(): boolean {
        return this.getBoolean(Preferences.CarersIndexShowActivity, false);
    }

    private get(key: string, defaultValue: any) {
        if (this.cache.has(key)) {
            return this.cache.get(key);
        }

        const value = this.storage.get(key, defaultValue);

        this.cache.set(key, value);

        return value;
    }

    private getBoolean(key: string, defaultValue: boolean): boolean {
        const value = this.get(key, defaultValue);

        if (value === 'false') {
            return false;
        }

        return Boolean(value);
    }

    private getNumber(key: string, defaultValue: number): number {
        const value = Number(this.get(key, defaultValue));

        if (isNaN(value)) {
            return defaultValue;
        }

        return value;
    }

    private set(key: string, value: any) {
        this.storage.set(key, value);
        this.cache.set(key, value);
    }

    hasBeenDismissed(key: string) {
        return this.storage.getBoolean(`dismissed:${key}`, false);
    }

    setDismissed(key: string) {
        this.storage.set(`dismissed:${key}`, true);
    }
}
