import { Component, Input } from '@angular/core';
import { LegacyFloatLabelType as FloatLabelType, MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { AbstractFormFieldComponent } from '@shared/form-fields/abstract-form-field/abstract-form-field.component';
import { makeProvider } from '@shared/form-fields/make-provider';
import { isPresent } from '@shared/utils/helpers';
import { round } from 'lodash-es';

@Component({
    selector: 'app-input-currency',
    styleUrls: ['./currency-input.component.scss'],
    template: `
        <mat-form-field [appearance]="appearance">
            <mat-label *ngIf="label">
                {{ label }}
            </mat-label>

            <div matPrefix>
                <ng-content select="[appPrefix]"></ng-content>
            </div>

            <input
                matInput
                type="text"
                currencyMask
                [id]="id"
                [(ngModel)]="currencyValue"
                [ngModelOptions]="{ standalone: true }"
                [name]="name"
                placeholder="{{ placeholder || (0 | currency) }}"
                [disabled]="disabled"
                [required]="required"
                [readonly]="readonly"
                [min]="min"
                [max]="max"
                (blur)="_onTouch()"
            />

            <div matSuffix>
                <ng-content select="[appSuffix]"></ng-content>

                <button mat-icon-button *ngIf="clearable" (click)="clear($event)">
                    <mat-icon>clear</mat-icon>
                </button>
            </div>

            <mat-hint *ngIf="hint">
                {{ hint }}
            </mat-hint>

            <mat-error *ngFor="let message of errorMessages">{{ message }}</mat-error>
        </mat-form-field>
    `,
    providers: [makeProvider(CurrencyInputComponent)],
})
export class CurrencyInputComponent extends AbstractFormFieldComponent<number> {
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() floatLabel: FloatLabelType = 'auto';
    @Input() min: number;
    @Input() max: number;

    set currencyValue(value: number) {
        if (isNaN(value) || !isPresent(value)) {
            this.value = value;
        } else {
            this.value = round(value * 100, 2);
        }
    }

    get currencyValue(): number {
        const value = this.value;

        if (isNaN(value) || !isPresent(value)) {
            return value;
        }

        return round(value / 100, 2);
    }

    protected clearValue() {
        this.currencyValue = null;
    }
}
