import { Directive, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';
import { equals, isPresent } from '@shared/utils/helpers';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class AbstractInputComponent<TValue> implements ControlValueAccessor {
    _value: TValue;

    @HostBinding('attr.data-disabled')
    protected _disabled: boolean;

    @Input() required = false;
    @Output() touched = new EventEmitter();
    @Output() valueChanged = new EventEmitter();

    get disabled() {
        return this._disabled;
    }

    @Input()
    set disabled(value) {
        this._disabled = value;
    }

    public _hasBeenTouched = false;

    get hasBeenTouched(): boolean {
        return this._hasBeenTouched;
    }

    get value(): TValue {
        return this._value;
    }

    set value(v: TValue) {
        v = this.transformValue(v);

        if (v !== this._value && (!equals(v, this._value) || typeof v !== typeof this._value)) {
            this._value = v;

            this.dispatchChange();
        }
    }

    get valuePresent(): boolean {
        return isPresent(this._value);
    }

    protected transformValue(v: TValue): TValue {
        return v;
    }

    writeValue(value: TValue) {
        this._value = value;

        // warning: comment below if only want to emit on user intervention
        this.dispatchChange();
    }

    _onTouch() {
        this._hasBeenTouched = true;
        this.touched.emit();

        this.onTouched();
    }

    onChange = _ => {
    };

    onTouched = () => {
    };

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    dispatchChange() {
        this.onChange(this._value);
        this.onValueChanged(this._value);
    }

    onValueChanged(value: TValue) {
        this.valueChanged.emit(value);
    }

    clear($event?: MouseEvent) {
        if ($event) {
            $event.preventDefault();
            $event.stopImmediatePropagation();
        }

        if (isPresent(this.value)) {
            this.clearValue();
        }
    }

    protected clearValue() {
        this.value = undefined;
    }

    // Helpers
    isPresent(...elements: any[]) {
        return isPresent(...elements);
    }
}

