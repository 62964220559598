import { animate, group, style, transition, trigger } from '@angular/animations';

export function scaleInAnimation(duration: string = '0.2s') {
    return trigger(
        'scaleIn',
        [
            transition(
                ':enter',
                [
                    style({
                        transform: 'scale(0.2)',
                        opacity: 0
                    }),
                    group([
                        animate(`${duration} cubic-bezier(0.4, 0, 0.2, 1)`, style({
                            transform: 'scale(1)',
                        })),
                        animate(`${duration} cubic-bezier(0.8, 0, 0.2, 1)`, style({ opacity: 1 })),
                    ])
                ]
            ),
            transition(
                ':leave',
                [
                    style({
                        opacity: 1,
                        transform: 'translateY(0)',
                    }),
                    animate(`${duration} cubic-bezier(0.4, 0, 0.2, 1)`, style({
                        opacity: 0,
                        transform: 'translateY(16px)',
                    }))
                ]
            )
        ]
    );
}
