import { DateTime } from 'luxon';

export function parse(value: any, parseFormat?: any): DateTime | null {
    if (value && typeof value === 'number') {
        const fromTimestamp = DateTime.fromMillis(value);

        if (fromTimestamp.isValid) {
            return fromTimestamp;
        }
    }

    if (value && typeof value === 'string') {
        // first try to parse an ISO date
        const aDateTime = DateTime.fromISO(value);

        if (aDateTime.isValid) {
            return aDateTime;
        }

        // Otherwise try to parse according to specified format (useful for user entered values?).
        return DateTime.fromFormat(value, parseFormat);
    }

    return value;
}
