import { animate, AUTO_STYLE, group, style, transition, trigger } from '@angular/animations';

export function expandAnimation(duration: string = '0.5s') {
    return trigger(
        'expand',
        [
            transition(
                ':enter',
                [
                    style({ height: 0, opacity: 0 }),
                    group([
                        animate(`${duration} cubic-bezier(0.4, 0, 0.2, 1)`, style({ height: AUTO_STYLE })),
                        animate(`${duration} cubic-bezier(0.8, 0, 0.2, 1)`, style({ opacity: 1 })),
                    ])
                ]
            ),
            transition(
                ':leave',
                [
                    style({ height: AUTO_STYLE, opacity: 1 }),
                    animate(`${duration} cubic-bezier(0.4, 0, 0.2, 1)`, style({ height: 0, opacity: 0 }))
                ]
            )
        ]
    );
}

