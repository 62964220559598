import { NgModule } from '@angular/core';
import { SeverityLevelColourPipe } from '@shared/common/pipes/severity-level/severity-level-colour.pipe';
import { SeverityLevelRagPipe } from '@shared/common/pipes/severity-level/severity-level-rag.pipe';
import { SeverityLevelPipe } from '@shared/common/pipes/severity-level/severity-level.pipe';

const pipes = [
    SeverityLevelPipe,
    SeverityLevelColourPipe,
    SeverityLevelRagPipe
];

@NgModule({
    declarations: [
        ...pipes
    ],
    exports: [
        ...pipes
    ]
})
export class SeverityLevelModule {

}
