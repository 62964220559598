/**
 * Presenter
 *
 * @author Dec Norton <decnorton@gmail.com>
 */

import type { PresenterStatic } from '@shared/presenters/presenter.interface';
import { readableDateRange } from '@shared/utils/dates/readable-date-range';
import { get, has, isNumber, isObject } from 'lodash-es';
import { DateTime } from 'luxon';
import {
    dateTimeReadable,
    formatDate,
    formatDateShort,
    formatDateWithYear,
    formatReadableDate,
    formatTime,
    formatTimestamp,
    secondsToReadable,
    secondsToShortReadable,
    toDateTime
} from '../utils/date-time';

let id = 0;

interface PresenterSubject {
    [prop: string]: any;

    presenter?: Presenter<any>;
}

/**
 * @abstract
 */
export class Presenter<M extends PresenterSubject> {

    protected static _cache = {};
    private readonly $$id: number;

    protected _data: M;

    constructor(data: M) {
        this.data = data;
        this.$$id = id++;
    }

    get data(): M {
        return this._data;
    }

    set data(data) {
        if (!data) {
            throw new Error('[Presenter] Missing data');
        }

        this._data = { ...data };
        delete this._data['presenter'];

        this.invalidateCache();
    }

    get id(): string {
        return this._data['id'];
    }

    get optionLabel(): string {
        return this._get('optionLabel', () => this['title'] ?? null);
    }

    get createdAtTimestamp(): string {
        return this._get('createdAtTimestamp', () => this.formatTimestamp(this.createdAt));
    }

    get createdAtDate(): string {
        return this._get('createdAtDate', () => this.formatDate(this.createdAt));
    }

    get createdAtDateWithYear(): string {
        return this._get('createdAtDate', () => this.formatDateWithYear(this.createdAt));
    }

    get createdAtTime(): string {
        return this._get('createdAtTime', () => this.formatTime(this.createdAt));
    }

    get createdAt(): DateTime {
        return this.toDateTime('created_at', true);
    }

    get updatedAtTimestamp(): string {
        return this._get('updatedAtTimestamp', () => this.formatTimestamp(this.updatedAt));
    }

    get updatedAt(): DateTime {
        return this.toDateTime('updated_at', true);
    }

    get deletedAtTimestamp(): string {
        return this._get('deletedAtTimestamp', () => this.formatTimestamp(this.deletedAt));
    }

    get deletedAt(): DateTime {
        return this.toDateTime('deleted_at', true);
    }

    get isDeleted(): boolean {
        return !!this._data['deleted_at'];
    }

    /**
     * @param key
     * @param isUtc
     * @return
     */
    toDateTime(key: string, isUtc = true): DateTime {
        return this._get(`${key}_date${isUtc ? '_utc' : ''}`, () => {
            const value = this.get(key);

            if (value) {
                return toDateTime(value, isUtc);
            }

            return value;
        });
    }

    protected present<C extends PresenterSubject>(key: string, presenter: PresenterStatic<C>) {
        return this._get(key, () => {
            const value = this.get(key);

            if (Array.isArray(value)) {
                return value.map(v => presenter.create(v));
            }

            return presenter.create(value);
        });
    }

    protected presentCollection<C extends PresenterSubject, P extends Presenter<C>>(key: string, presenter: PresenterStatic<C>): P[] {
        return this._get(key, () => {
            const value = this.get(key, []) as C[];

            return value.map(v => presenter.create(v));
        });
    }

    protected presentItem<C extends PresenterSubject, P extends Presenter<C>>(key: string, presenter: PresenterStatic<C>): P {
        return this._get(key, () => {
            const value = this.get(key, null) as C;

            return presenter.create(value);
        });
    }

    protected presentAttach<C extends PresenterSubject>(key: string, presenter: PresenterStatic<C>): C | C[] {
        return this._get(key, () => {
            const value = this.get(key);

            if (Array.isArray(value)) {
                return value.map(v => presenter.attach(v));
            }

            return presenter.attach(value);
        });
    }

    invalidateCache() {
        Presenter.clearInstanceCache(this.$$id);
    }

    _has(path) {
        return has(this.data, path);
    }

    has(path) {
        return this._has(path);
    }

    get(path, defaultValue?: any) {
        return get(this.data, path, defaultValue);
    }

    _get<T = any>(key: string | number, getter: () => T) {
        Presenter._cache[this.$$id] = isObject(Presenter._cache[this.$$id]) ? Presenter._cache[this.$$id] : {};

        const instanceCache = Presenter._cache[this.$$id];

        if (instanceCache.hasOwnProperty(key)) {
            return instanceCache[key];
        }

        const value = getter();

        instanceCache[key] = value;

        return value;
    }

    formatTimestamp(value: DateTime, local = true) {
        return formatTimestamp(value, local);
    }

    formatDateTimeReadable(value: DateTime, local = true) {
        if (!value) {
            return null;
        }

        if (local) {
            value = value.toLocal();
        }

        return dateTimeReadable(value);
    }

    formatDuration(seconds: number): string {
        if (isNumber(seconds)) {
            return secondsToReadable(seconds);
        }

        return null;
    }

    formatDurationShort(seconds: number): string {
        if (isNumber(seconds)) {
            return secondsToShortReadable(seconds);
        }

        return null;
    }

    formatDateRange(start: DateTime, finish: DateTime): string {
        return readableDateRange(start, finish, false);
    }

    formatDateTimeRange(start: DateTime, finish: DateTime): string {
        return readableDateRange(start, finish, true);
    }

    formatDateWithYear(value: DateTime, local = true) {
        return formatDateWithYear(value, local);
    }

    /**
     * @param value
     * @param local
     * @return
     */
    formatTime(value: DateTime, local = true) {
        return formatTime(value, local);
    }

    /**
     * @param value
     * @param local
     * @return
     */
    formatDate(value: DateTime, local = true, format?: string): string {
        return formatDate(value, local, format);
    }

    formatDateReadable(value: DateTime, reference?: DateTime): string {
        if (!(value instanceof DateTime)) {
            return value;
        }

        return formatReadableDate(value, reference);
    }

    /**
     * @param value
     * @param local
     * @return
     */
    formatDateShort(value: DateTime, local = true) {
        return formatDateShort(value, local);
    }

    /**
     * Custom JSON serialisation.
     *
     * @return
     */
    toJSON() {
        // We don't want to serialize anything in this class as we might get cyclical references.
        return undefined;
    }

    /**
     * @deprecated Use #attachItem or attachCollection instead
     */
    static attach<E extends PresenterSubject>(model: E | E[], updateData = true): any {
        if (!model) {
            return model;
        }

        if (Array.isArray(model)) {
            return this.attachCollection(model, updateData);
        }

        return this.attachItem(model, updateData);
    }

    static attachCollection<E extends PresenterSubject>(collection: E[], updateData = true): E[] {
        if (!collection) {
            return collection;
        }

        return collection.map(m => this.attachItem(m, updateData));
    }

    static attachItem<E extends PresenterSubject>(model: E, updateData = true): E {
        if (!model) {
            return model;
        }

        if (model.presenter === null || !(model.presenter instanceof this)) {
            // Doesn't have a presenter yet
            model.presenter = new this(model);
        } else if (updateData) {
            model.presenter.data = model as E;
        }

        return model;
    }

    /**
     * @param model
     * @return
     */
    static create(model) {
        model = this.attach(model);

        if (Array.isArray(model)) {
            return model.map(m => m.presenter);
        }

        return model ? model.presenter : undefined;
    }

    static clearInstanceCache(key: number) {
        Presenter._cache[key] = null;
    }

    static clearCache() {
        Presenter._cache = {};
    }
}
