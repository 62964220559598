import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { expandAnimation } from '@shared/common/animations/expandAnimation';

@Component({
    selector: 'app-expand',
    templateUrl: './expand.component.html',
    styleUrls: ['./expand.component.scss'],
    animations: [expandAnimation()],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandComponent {
    @HostBinding('class.expanded') @Input() expand = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    toggle() {
        this.expand = !this.expand;
        this.changeDetectorRef.markForCheck();
    }
}
