import type { Model } from '@shared/models/model';
import { AddressPresenter } from '@shared/modules/addresses/address.presenter';
import type { Team } from '@shared/modules/teams/team';
import { TeamPresenter } from '@shared/modules/teams/team-presenter';
import { niceList } from '@shared/utils/strings';
import { get } from 'lodash-es';
import { DateTime } from 'luxon';
import { Presenter } from '../../presenters/presenter';
import type { Person } from './person';

export class PersonPresenter extends Presenter<Person> {
    get avatar(): string {
        return this._get('avatar', () => this.data.avatar_url || '/img/avatar_placeholder_filled.svg');
    }

    get email(): string {
        return this.data.email;
    }

    get emailWork(): string {
        return this.data.email_work;
    }

    get firstInitial(): string {
        return this._get('firstInitial', () => {
            if (this.data.first_name && this.data.first_name.length > 0) {
                return this.data.first_name[0];
            }
        });
    }

    get firstName() {
        return this.data.first_name;
    }

    get dateOfBirth(): string {
        return this._get('dateOfBirth', () => this.formatDate(
            this.toDateTime('date_of_birth', false)
        ));
    }

    get age() {
        return this.data.age ?? this._get('age', () => {
            const years = this.toDateTime('date_of_birth', false)?.diffNow('years').years;
            return years ? Math.floor(Math.abs(years)) : null;
        });
    }

    get hasBirthdayToday(): boolean {
        const now = DateTime.local();

        return this._get(`hasBirthdayToday:${now.toISODate()}`, () => {
            const birthday = this.toDateTime('date_of_birth', false);

            if (birthday) {
                return birthday.month === now.month
                    && birthday.day === now.day;
            }

            return false;
        });
    }

    get gender() {
        return this._get('gender', () => {
            switch (this.data.gender) {
                case 'm':
                    return 'Male';

                case 'f':
                    return 'Female';

                case 'other':
                    return this.data.gender_other;
            }
        });
    }

    get preferredName() {
        return this.data.preferred_name;
    }

    get pronouns() {
        return this.data.pronouns;
    }

    get fullName() {
        return [this.data.title, this.data.first_name, this.data.initials, this.data.last_name]
            .filter(Boolean)
            .join(' ');
    }

    get fullNameLastFirst() {
        const lastName = this.data.last_name ? this.data.last_name + ', ' : undefined;

        return [lastName, this.data.title, this.data.first_name, this.data.initials].filter(value => !!value).join(' ');
    }

    get homeNumber() {
        return this.data.tel_home;
    }

    get initials() {
        const firstInitial = this.data.first_name.substring(0, 1);
        const lastInitial = this.data.last_name.substring(0, 1);

        return firstInitial + lastInitial;
    }

    get initialsLastFirst() {
        const firstInitial = this.data.first_name.substring(0, 1);
        const lastInitial = this.data.last_name.substring(0, 1);

        return lastInitial + ', ' + this.data.title + ' ' + firstInitial;
    }

    get lastName() {
        return this.data.last_name;
    }

    get mobileNumber() {
        return this.data.tel_mobile;
    }

    get faxNumber() {
        return this.data.tel_fax;
    }

    get name() {
        return this.nameNormal;
    }

    get nameShort() {
        return this.nameNormal;
    }

    get nameLastFirst() {
        return `${this.data.last_name}, ${this.data.first_name}`;
    }

    get nameNormal() {
        return `${this.data.first_name} ${this.data.last_name}`;
    }

    get workNumber() {
        return this.data.tel_work;
    }

    get religion() {
        return this.data.religion;
    }

    get ethnicity() {
        return this.data.ethnicity;
    }

    get nationality() {
        return this.data.nationality;
    }

    get language() {
        return this.data.language;
    }

    get comments() {
        return this.data.comments;
    }

    get powersOfAttorney() {
        return this._data.relationship.data.power_of_attorney;
    }

    get preferredAddress(): AddressPresenter {
        return this.present('preferred_address.data', AddressPresenter);
    }

    get relations(): PersonPresenter[] {
        return this.present('relations.data', PersonPresenter);
    }

    get isNextOfKin(): boolean {
        return this._get('isNextOfKin', () => this._data?.relationship?.data?.next_of_kin);
    }

    get isGP(): boolean {
        return this._get('isGP', () => this._data?.relationship?.data?.gp);
    }

    get isPoa(): boolean {
        return this._get('isPoa', () => this._data?.relationship?.data?.power_of_attorney?.length > 0);
    }

    get relationshipType(): string {
        return this._get('relationshipType', () => this._data?.relationship_type?.data?.description);
    }

    get teams(): Team[] {
        return this.presentAttach('teams.data', TeamPresenter);
    }

    get teamNames() {
        return this._get('teamNames', () => niceList((this.teams || []).map(team => team.description)));
    }
}

export class BelongsToPersonPresenter<T extends Model> extends Presenter<T> {
    get person(): PersonPresenter {
        return this.present('person.data', PersonPresenter);
    }

    get fullNameLastFirst() {
        return this._get('fullNameLastFirst', () => {
            if (this.person) {
                return this.person.fullNameLastFirst;
            }
        });
    }

    get firstInitial() {
        return this._get('firstInitial', () => {
            if (this.person) {
                return this.person.firstInitial;
            }
        });
    }

    get firstName() {
        return this._get('firstName', () => {
            if (this.person) {
                return this.person.firstName;
            }
        });
    }

    get lastName() {
        return this._get('lastName', () => {
            if (this.person) {
                return this.person.lastName;
            }
        });
    }

    get name() {
        return get(this.person, 'name');
    }

    get nameShort() {
        return get(this.person, 'nameShort');
    }

    get fullName() {
        return get(this.person, 'fullName');
    }

}
