import type { OnInit } from '@angular/core';
import { Component, ElementRef, Inject } from '@angular/core';
import {
    MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
    MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'app-action-required-snackbar',
    templateUrl: './app-action-required-snackbar.component.html',
    styleUrls: ['./app-action-required-snackbar.component.scss'],
})
export class AppActionRequiredSnackbarComponent implements OnInit {
    /** Data that was injected into the snack bar. */
   public data: { title: string; message: string; action: string; status: string; type: 'warning' | 'error' };

    public icon: string = 'warning';

    constructor(
        private elementRef: ElementRef,
        public snackBarRef: MatSnackBarRef<AppActionRequiredSnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) data: any,
    ) {
        this.data = data;
    }

    ngOnInit(): void {
        if (this.data.status) {
            this.elementRef.nativeElement.classList.add(this.data.status);
        }
    }

    /** Performs the action on the snack bar. */
    action(): void {
        this.snackBarRef.dismissWithAction();
    }

    /** If the action button should be shown. */
    get hasAction(): boolean {
        return !!this.data.action;
    }
}
