export function snakeToCamel(s: string) {
    return s.replace(/(_\w)/g, m => m[1].toUpperCase());
}

export function camelToSentenceCase(s: string) {
    const result = s.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export function capitalize(input) {
    return input
        ? input.replace(/([^\W_]+[^\s-]*) */g, text => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase())
        : '';
}

export function lowerCaseFirstLetter(value: string): string {
    return value.charAt(0).toLowerCase() + value.slice(1);
}

export function toTitleCase(input: string): string {
    return input.length === 0
        ? ''
        : input.replace(/\w\S*/g, text => text[0].toUpperCase() + text.substr(1).toLowerCase());
}

export function niceList(listArray) {
    if (!listArray || listArray.length === 0) {
        return '';
    }

    const clone = listArray.slice(0);

    // eslint-disable-next-line prefer-arrow-callback
    return (function build() {
        if (clone.length === 1) {
            return clone[0];
        }

        if (clone.length === 2) {
            return clone[0] + ' and ' + clone[1];
        }

        return clone.shift() + ', ' + build();
    })();
}

/**
 * Pad the given value.
 *
 * @param value
 * @param [width]
 * @param [char]
 * @param limit
 * @returns
 */
export function pad(value, width: number = 2, char: string = '0', limit?: number) {
    value = value + '';

    if (limit !== undefined) {
        value = value.substr(0, limit);
    }

    return value.length >= width ? value : new Array(width - value.length + 1).join(char) + value;
}

/**
 * Trim character from the start of a string.
 *
 * @param value
 * @param [char]
 * @returns
 */
export function trimLeft(value, char = '\\s') {
    return String(value).replace(`/^${char}+/`, '');
}

/**
 * Trim character from the end of a string.
 *
 * @param value
 * @param [char]
 * @returns
 */
export function trimRight(value, char = '\\s') {
    return String(value).replace(`/${char}+$/`, '');
}

/**
 * Make first character uppercase
 *
 * @param string
 * @return
 */
export function uppercaseFirst(string) {
    // Cast to string
    string += '';

    return string.charAt(0).toUpperCase() + string.substr(1);
}

export function truncate(value: string, length: number, end: string) {
    if (!value || length === -1) {
        return value;
    }

    value = String(value);

    if (isNaN(length)) {
        length = 10;
    }

    if (value.length <= length || value.length - end.length <= length) {
        return value;
    } else {
        return String(value).substring(0, length - end.length) + end;
    }
}

export function compactInteger(input: any, decimals = 0): string {
    decimals = Math.max(decimals, 0);
    const number = parseInt(input, 10);
    const signString = number < 0 ? '-' : '';
    const unsignedNumber = Math.abs(number);
    const unsignedNumberString = String(unsignedNumber);
    const numberLength = unsignedNumberString.length;
    const numberLengths = [13, 10, 7, 4];
    const bigNumPrefixes = ['T', 'B', 'M', 'k'];

    // small numbers
    if (unsignedNumber < 1000) {
        return `${signString}${unsignedNumberString}`;
    }

    // really big numbers
    if (numberLength > numberLengths[0] + 3) {
        return number.toExponential(decimals).replace('e+', 'x10^');
    }

    // 999 < unsignedNumber < 999,999,999,999,999
    let length;

    for (const len of numberLengths) {
        if (numberLength >= len) {
            length = len;
            break;
        }
    }

    const decimalIndex = numberLength - length + 1;
    const unsignedNumberCharacterArray = unsignedNumberString.split('');

    const wholePartArray = unsignedNumberCharacterArray.slice(0, decimalIndex);
    const decimalPartArray = unsignedNumberCharacterArray.slice(decimalIndex, decimalIndex + decimals + 1);

    const wholePart = wholePartArray.join('');

    // pad decimalPart if necessary
    let decimalPart = decimalPartArray.join('');
    if (decimalPart.length < decimals) {
        decimalPart += `${Array(decimals - decimalPart.length + 1).join('0')}`;
    }

    let output;
    if (decimals === 0) {
        output = `${signString}${wholePart}${bigNumPrefixes[numberLengths.indexOf(length)]}`;
    } else {
        const outputNumber = Number(`${wholePart}.${decimalPart}`).toFixed(decimals);
        output = `${signString}${outputNumber}${bigNumPrefixes[numberLengths.indexOf(length)]}`;
    }

    return output;
}

export function unslugify(slug: string, separator = '_') {
    return slug.split(separator).join(' ');
}

export function numberOfUpperCaseLetters(input: string): number {
    const upperCaseLetters = /[A-Z]/g;
    return input?.match(upperCaseLetters)?.length || 0;
}

export function numberOfLowerCaseLetters(input: string): number {
    const lowerCaseLetters = /[a-z]/g;
    return input?.match(lowerCaseLetters)?.length || 0;
}

export function numberOfSymbols(input: string): number {
    const symbols = /[!@#$£%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    return input?.match(symbols)?.length || 0;
}

export function numberOfNumbers(input: string): number {
    const numbers = /\d/g;
    return input?.match(numbers)?.length || 0;
}

export function hasTwoConsecutiveSpaces(input: string): boolean {
    return input?.includes('  ') || false;
}
