import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { AbstractFormFieldComponent } from '@shared/form-fields/abstract-form-field/abstract-form-field.component';
import { makeProvider } from '@shared/form-fields/make-provider';
import Fuse from 'fuse.js';

@Component({
    selector: 'app-input-text',
    styleUrls: ['./text-input.component.scss'],
    template: `
        <mat-form-field [appearance]="appearance" [ngClass]="{ 'mat-form-field-invalid': invalid }">
            <mat-label *ngIf="label">
                {{ label }}
            </mat-label>

            <div matPrefix>
                <ng-content select="[appPrefix]"></ng-content>
            </div>

            <input
                    [type]="type"
                    #input
                    matInput
                    [(ngModel)]="value"
                    (ngModelChange)="_filterOptions()"
                    [ngModelOptions]="{ standalone: true }"
                    [placeholder]="placeholder"
                    [disabled]="disabled"
                    [readonly]="readonly"
                    [minlength]="minlength"
                    [maxlength]="maxlength"
                    [required]="required"
                    [tabIndex]="tabIndex"
                    (keydown)="_onKeyDown($event)"
                    (blur)="_onBlur()"
                    [matAutocomplete]="matAutocomplete || auto">

            <div matSuffix>
                <ng-content select="[appSuffix]"></ng-content>
            </div>

            <mat-hint *ngIf="hint">
                {{ hint }}
            </mat-hint>

            <mat-error *ngFor="let message of errorMessages">{{ message }}</mat-error>

            <mat-autocomplete #auto="matAutocomplete">
                <mat-optgroup label="Suggestions"
                              *ngIf="_filteredOptions?.length">
                    <mat-option *ngFor="let option of _filteredOptions; let i = index"
                                [value]="option"
                                [attr.data-testid]="'suggestion:' + option">
                        {{ option }}
                    </mat-option>
                </mat-optgroup>
            </mat-autocomplete>
        </mat-form-field>
    `,
    providers: [makeProvider(TextInputComponent)],
})
export class TextInputComponent extends AbstractFormFieldComponent<string> {
    @ViewChild('input') input: ElementRef<HTMLInputElement>;

    @Input() type: 'text' | 'email' = 'text';

    @Input() set options(value: string[] | readonly string[]) {
        this._options = value;
        this._filterOptions();
    }

    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() minlength: number;
    @Input() maxlength: number;
    @Input() matAutocomplete: MatAutocomplete;
    @Input() invalid: boolean = false;

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() blur = new EventEmitter<void>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onEnter = new EventEmitter<KeyboardEvent>();

    @ViewChild('auto', { read: MatAutocomplete }) autocomplete: MatAutocomplete;

    _options: string[] | readonly string[];
    _filteredOptions: string[];

    _onBlur() {
        this._onTouch();

        this.blur.emit();
    }

    _filterOptions() {
        if (this._options && this.value) {
            const fuse = new Fuse(this._options, {});

            const results = fuse.search<string>(this.value);

            this._filteredOptions = results.map(result => result['item']);
        } else {
            this._filteredOptions = [...(this._options ?? [])];
        }
    }

    blurInput() {
        this.input?.nativeElement?.blur();
    }

    _onKeyDown($event: KeyboardEvent) {
        if ($event.key === 'Enter') {
            this.onEnter.emit($event);
        }
    }
}
