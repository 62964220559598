import { NgModule } from '@angular/core';
import { IfHasPermissionDirective } from '@shared/common/permissions/if-has-permission/if-has-permission.directive';
import { PermissionsService } from '@shared/common/permissions/permissions.service';

const components = [];

const directives = [IfHasPermissionDirective];

@NgModule({
    declarations: [...components, ...directives],
    exports: [...components, ...directives],
    providers: [PermissionsService],
})
export class AppPermissionsModule {}
