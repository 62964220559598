import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppFormFieldsModule } from '@shared/form-fields';
import { TaxTypeSelectComponent } from './tax-type-select.component';

@NgModule({
    declarations: [
        TaxTypeSelectComponent
    ],
    imports: [
        CommonModule,
        AppFormFieldsModule
    ],
    exports: [
        TaxTypeSelectComponent
    ]
})
export class TaxTypeSelectModule {
}
