import type { HttpResponse } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import type { MatLegacySnackBarRef as MatSnackBarRef, LegacySimpleSnackBar as SimpleSnackBar } from '@angular/material/legacy-snack-bar';
import {
    ifError,
    toApiObservable,
    toHttpResponseSuspense,
    toTextSuspense,
    toVoidSuspense
} from '@shared/models/api-state/operators';
import { getErrorMessage } from '@shared/services/error-handler/helpers/get-error-message';
import { parseHttpErrorResponse } from '@shared/services/error-handler/parse';
import type { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import type { ApiResponse } from '../../http/responses/api-response';
import type { ApiRequestStatusConfig } from '../../models/api-data';
import type { ApiObservable } from '../../models/api-data';
import { AppSnackBar } from '../snackbar/app-snackbar.service';

interface ResponseHandlerOptions<Config extends ApiRequestStatusConfig = ApiRequestStatusConfig> {
    notify?: boolean;
    requestConfig?: Config;
}

@Injectable({
    providedIn: 'root',
})
export class AppErrorHandler {
    constructor(private snackBar: AppSnackBar) {
        this.handle = this.handle.bind(this);
        this.showErrorMessage = this.showErrorMessage.bind(this);
    }

    handle(error: string | HttpErrorResponse | unknown, notify = true) {
        console.error(error);

        if (!notify) {
            return;
        }

        if (error instanceof HttpErrorResponse) {
            if (error.status === 404) {
                this.snackBar.showError('Not found');
                return;
            }

            const response = parseHttpErrorResponse(error);

            this.snackBar.showError(getErrorMessage(response));
        } else if (typeof error === 'string') {
            this.snackBar.showError(error);
        }
    }

    showErrorMessage(message: string, action?: string): MatSnackBarRef<SimpleSnackBar> {
        return this.snackBar.showError(message, action);
    }

    catchError() {
        return <T>(source: Observable<T>) => source.pipe(
            catchError(error => {
                this.handle(error);

                return throwError(error);
            }),
        );
    }

    dataResponseHandler<T = any>(options?: ResponseHandlerOptions): (source: Observable<T>) => ApiObservable<T> {
        return (source: Observable<T>) => source.pipe(
            map(data => ({ data })),
            this.rawResponseHandler(options)
        );
    }

    rawResponseHandler<T = any>(options?: ResponseHandlerOptions): (source: Observable<ApiResponse<T>>) => ApiObservable<T> {
        return (source: Observable<ApiResponse<T>>): ApiObservable<T> => source.pipe(
            toApiObservable(options?.requestConfig),
            ifError(error => {
                this.handle(error, options?.notify ?? true);
            }),
        );
    }

    httpResponseSuspense<T = any>(options?: ResponseHandlerOptions): (source: Observable<HttpResponse<T>>) => ApiObservable<HttpResponse<T>> {
        return (source: Observable<HttpResponse<T>>): ApiObservable<HttpResponse<T>> => source.pipe(
            toHttpResponseSuspense(),
            ifError(error => {
                this.handle(error, options?.notify ?? true);
            }),
        );
    }

    textSuspense(options?: ResponseHandlerOptions): (source: Observable<string>) => ApiObservable<string> {
        return (source: Observable<string>): ApiObservable<string> => source.pipe(
            toTextSuspense(),
            ifError(error => {
                this.handle(error, options?.notify ?? true);
            }),
        );
    }

    voidResponseSuspense<T = any>(options?: ResponseHandlerOptions): (source: Observable<void>) => ApiObservable<void> {
        return (source: Observable<void>): ApiObservable<void> => source.pipe(
            toVoidSuspense(),
            ifError(error => {
                this.handle(error, options?.notify ?? true);
            }),
        );
    }
}

export function injectErrorHandler(): AppErrorHandler {
    return inject(AppErrorHandler);
}

