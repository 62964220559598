import { Injectable } from '@angular/core';
import type { RecoveryCodes, RegenerateRecoveryCodesPayload } from '@shared/modules/multi-factor-auth/recovery-codes';
import type { Role } from '@shared/modules/roles/role';
import { AppEventSource } from '@shared/services/event-emitter/app-events.service';
import { EVENT_SELECTED_TEAMS_CHANGED } from '@shared/services/selected-teams/constants';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { ApiParams } from '../../http/api-params';
import { buildHeaders, Fetcher } from '../../http/fetcher';
import type { ApiResponse } from '../../http/responses/api-response';
import type { Team } from '../teams/team';
import type { User } from './user';

interface UserRestrictions {
    update_password: boolean;
    update_email: boolean;
    force_password_reset: boolean;
    update_roles: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class UserFetcher extends Fetcher<User> {
    readonly modelName = 'User';

    get resourceName(): string {
        return 'users';
    }

    current(params: ApiParams = {}) {
        return this._get(this._getUrl(`current`), {
            include: ['roles', 'teams:fields(simple)'],
            ...params,
        });
    }

    restrictions(id: string): Observable<ApiResponse<UserRestrictions>> {
        return this._get<UserRestrictions>(this._getUrl(id, 'restrictions'));
    }

    setTeams(userId: string, payload: {
        teams: Team[]
    }, params: ApiParams = {}) {
        return this._post(`${this._getUrl(userId)}/teams`, {
            teams: {
                data: payload.teams.map(({ id }) => ({ id }))
            },
        }, params);
    }

    setRoles(userId: string, payload: {
        roles: Role[]
    }, params: ApiParams = {}) {
        return this._put(`${this._getUrl(userId)}/roles`, {
            roles: payload.roles.map(({ id }) => ({ id }))
        }, params);
    }

    /**
     * @param teams
     * @param params
     */
    updateSelectedTeams(teams: Pick<Team, 'id'>[], params: ApiParams = {}) {
        return this._post<Team[]>(
            this._getUrl('current/team'),
            { teams: { data: teams }, },
            params
        )
            .pipe(map(response => {
                this.events.broadcast(EVENT_SELECTED_TEAMS_CHANGED, {
                    team: response ? response.data : null,
                }, AppEventSource.global);

                return response;
            }));
    }

    getSelectedTeams(params: ApiParams = {}): Observable<ApiResponse<Team[]>> {
        return this._get<Team[]>(this._getUrl('current/team'), params);
    }

    logoutSessions(userId: string, password: string) {
        return this._post(this._getUrl(userId, 'sessions/logout'), { password });
    }

    mfaUpdate(userId: string, payload: {
        password: string;
        mfa_otp_mobile_number: string
    }) {
        return this._put(this._getUrl(userId, 'mfa/mobile-number'), payload);
    }

    updatePassword(userId: string, payload: {
        current_password: string;
        password: string;
        password_confirmation: string;
    }) {
        return this.http.patch<void>(this._getUrl(userId, 'password'), payload, {
            headers: buildHeaders(),
        });
    }

    updateEmail(userId: string, payload: {
        password: string;
        email: string;
        email_confirmation: string;
    }) {
        return this.http.patch<void>(this._getUrl(userId, 'email'), payload, {
            headers: buildHeaders(),
        });
    }

    mfaDisable(userId: string, password: string) {
        return this._post(this._getUrl(userId, 'mfa'), {
            _method: 'DELETE',
            password
        });
    }

    mfaRegenerateCodes(userId: string, payload: RegenerateRecoveryCodesPayload) {
        return this._post<{
            mfa_recovery_codes: RecoveryCodes
        }>(this._getUrl(userId, 'mfa/recovery-codes'), payload);
    }

    mfaRequestOtp(userId: string) {
        return this._post(this._getUrl(userId, 'mfa/otp/request'), {});
    }

    mfaVerifyTotp(userId: string, code: string) {
        return this._post<{
            status: string
        }>(this._getUrl(userId, 'mfa/totp/verify'), { totp_code: code });
    }

    rolesMatrix(): Observable<ApiResponse<UserRoleAssignment[]>> {
        return this._get<UserRoleAssignment[]>(this._getUrl('roles', 'matrix'), { all: true });
    }
}

export interface UserRoleAssignment {
    user_id: string;
    user_name: string;
    user_email: string;
    roles: {
        [role_id: string]: {
            created_at: string;
            deleted_at?: string
        } | null;
    };
}
