import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import type { MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import { MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/legacy-snack-bar';
import type { MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions } from '@angular/material/legacy-tooltip';
import { MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS, MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

const materialModules = [
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatMenuModule
];

const tooltipDefaultOptions: MatTooltipDefaultOptions = {
    showDelay: 300,
    hideDelay: 50,
    touchendHideDelay: 500,
    disableTooltipInteractivity: true
};

const snackbarDefaultOptions: MatSnackBarConfig = {
    horizontalPosition: 'right',
    verticalPosition: 'bottom'
};

@NgModule({
    imports: materialModules,
    exports: materialModules,
    providers: [
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: tooltipDefaultOptions
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: snackbarDefaultOptions
        },
    ]
})
export class AppMaterialModule {
}
