/* eslint-disable @angular-eslint/directive-selector */
import { getCurrencySymbol } from '@angular/common';
import type {
    DoCheck,
    KeyValueDiffer,
    OnInit } from '@angular/core';
import {
    DEFAULT_CURRENCY_CODE,
    Directive,
    ElementRef,
    forwardRef,
    HostListener,
    Inject,
    Input,
    KeyValueDiffers,
    LOCALE_ID,
    Optional
} from '@angular/core';

import type { ControlValueAccessor } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig } from './currency-mask.config';
import type { OnModelChange, OnModelTouched } from './input.handler';
import { InputHandler } from './input.handler';

export const CURRENCYMASKDIRECTIVE_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CurrencyMaskDirective),
    multi: true,
};

@Directive({
    selector: '[currencyMask]',
    providers: [CURRENCYMASKDIRECTIVE_VALUE_ACCESSOR]
})
export class CurrencyMaskDirective implements ControlValueAccessor, DoCheck, OnInit {
    @Input() options: Partial<CurrencyMaskConfig> = {};

    public inputHandler: InputHandler;
    public keyValueDiffer: KeyValueDiffer<any, any>;

    public optionsTemplate: CurrencyMaskConfig = {
        allowNegative: true,
        allowZero: true,
        decimal: '.',
        precision: 2,
        prefix: '£ ',
        suffix: '',
        thousands: ',',
        nullable: true
    };

    constructor(@Optional() @Inject(CURRENCY_MASK_CONFIG) private currencyMaskConfig: CurrencyMaskConfig,
                private elementRef: ElementRef,
                private keyValueDiffers: KeyValueDiffers,
                @Inject(LOCALE_ID) private _locale: string,
                @Inject(DEFAULT_CURRENCY_CODE) private _defaultCurrencyCode: string = 'gbp') {
        if (currencyMaskConfig) {
            this.optionsTemplate = currencyMaskConfig;
        }

        this.keyValueDiffer = keyValueDiffers.find({}).create();

        this.options.prefix = getCurrencySymbol(_defaultCurrencyCode.toUpperCase(), 'narrow');
    }

    ngDoCheck() {
        if (this.keyValueDiffer.diff(this.options)) {
            this.inputHandler.updateOptions({
                ...this.optionsTemplate,
                ...this.options
            });
        }
    }

    ngOnInit() {
        this.inputHandler = new InputHandler(this.elementRef.nativeElement, {
            ...this.optionsTemplate,
            ...this.options
        });
    }

    @HostListener('blur', ['$event'])
    handleBlur(event: any) {
        this.inputHandler.getOnModelTouched().apply(event);
    }

    @HostListener('cut', ['$event'])
    handleCut(event: any) {
        if (!this.isChromeAndroid()) {
            if (!this.isReadOnly) {
                this.inputHandler.handleCut(event);
            }
        }
    }

    @HostListener('input', ['$event'])
    handleInput(event: any) {
        if (this.isChromeAndroid()) {
            if (!this.isReadOnly) {
                this.inputHandler.handleInput(event);
            }
        }
    }

    @HostListener('keydown', ['$event'])
    handleKeydown(event: KeyboardEvent) {
        if (!this.isChromeAndroid()) {
            if (!this.isReadOnly) {
                this.inputHandler.handleKeydown(event);
            }
        }
    }

    @HostListener('keypress', ['$event'])
    handleKeypress(event: any) {
        if (!this.isChromeAndroid()) {
            if (!this.isReadOnly) {
                this.inputHandler.handleKeypress(event);
            }
        }
    }

    @HostListener('paste', ['$event'])
    handlePaste(event: any) {
        if (!this.isChromeAndroid()) {
            if (!this.isReadOnly) {
                this.inputHandler.handlePaste(event);
            }
        }
    }

    @HostListener('drop', ['$event'])
    handleDrop(event: any) {
        if (!this.isChromeAndroid()) {
            event.preventDefault();
        }
    }

    isChromeAndroid(): boolean {
        return /chrome/i.test(navigator.userAgent) && /android/i.test(navigator.userAgent);
    }

    get isReadOnly(): boolean {
        return this.elementRef.nativeElement.hasAttribute('readonly');
    }

    registerOnChange(callbackFunction: OnModelChange): void {
        this.inputHandler.setOnModelChange(callbackFunction);
    }

    registerOnTouched(callbackFunction: OnModelTouched): void {
        this.inputHandler.setOnModelTouched(callbackFunction);
    }

    setDisabledState(value: boolean): void {
        this.elementRef.nativeElement.disabled = value;
    }

    writeValue(value: number): void {
        this.inputHandler.setValue(value);
    }
}
