// Decimal round
import isNumber from 'lodash-es/isNumber';

export function isBetween(value: number, a: number, b: number): boolean {
    const min = Math.min.apply(Math, [a, b]);
    const max = Math.max.apply(Math, [a, b]);

    return value > min && value < max;
}

export function round(value: number, numberOfDecimalPlaces: number) {
    return parseFloat((Math.round((value * 1000) / 10) / 100).toFixed(numberOfDecimalPlaces));
}

export function roundToNearest(value: number, roundTo: number) {
    roundTo = 1 / roundTo;

    return parseFloat((Math.round(value * roundTo) / roundTo).toFixed(decimalPlaces(1 / roundTo)));
}

// Decimal floor
export function floor(value, numberOfDecimalPlaces) {
    return parseFloat((Math.floor((value * 1000) / 10) / 100).toFixed(numberOfDecimalPlaces));
}

export function floorToNearest(value, floorTo) {
    floorTo = 1 / floorTo;

    return parseFloat((Math.floor(value * floorTo) / floorTo).toFixed(decimalPlaces(1 / floorTo)));
}

// Decimal ceil
export function ceil(value, numberOfDecimalPlaces) {
    return parseFloat((Math.ceil((value * 1000) / 10) / 100).toFixed(numberOfDecimalPlaces));
}

/**
 * Calculate lcm for two numbers
 *
 * @param a
 * @param b
 * @returns Returns the least common multiple of a and b
 * @private
 */
export function leastCommonMultiple(a, b) {
    if (!isNumber(a) || !isNumber(b)) {
        return null;
    }

    if (a === 0 || b === 0) {
        return 0;
    }

    // http://en.wikipedia.org/wiki/Euclidean_algorithm
    // evaluate lcm here inline to reduce overhead
    let t;
    const prod = a * b;
    while (b !== 0) {
        t = b;
        b = a % t;
        a = t;
    }

    return Math.abs(prod / a);
}

function decimalPlaces(number) {
    const match = String(number).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);

    if (!match) {
        return 0;
    }

    return Math.max(
        0,
        // Number of digits right of decimal point.
        (match[1] ? match[1].length : 0) -
        // Adjust for scientific notation.
        (match[2] ? +match[2] : 0),
    );
}
