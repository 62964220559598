import { Component, HostBinding, Input } from '@angular/core';
import type { Person } from '@shared/modules/people/person';
import { PersonPresenter } from '@shared/modules/people/person.presenter';

const placeholderUrl = '/img/avatar_placeholder_filled.svg';

@Component({
    selector: 'app-avatar',
    styleUrls: ['./avatar.component.scss'],
    template: `
        <img [src]="resolveUrl"
             [alt]="alt ?? person?.name ?? 'Profile photo'"
             (error)="_onImgError($event)"
             loading="lazy">
    `,
})
export class AvatarComponent {
    @Input() src: string;
    @Input() size: string;
    @Input() person: Person | PersonPresenter;
    @Input() alt: string | undefined;

    overrideSrc: string;

    get resolveUrl() {
        if (this.overrideSrc) {
            return this.overrideSrc;
        }

        if (this.src) {
            return this.src;
        }

        if (this.person instanceof PersonPresenter) {
            return this.person.avatar;
        }

        return this.person?.avatar_url || placeholderUrl;
    }

    @HostBinding('style.width')
    get width() {
        return this.size;
    }

    @HostBinding('style.height')
    get height() {
        return this.size;
    }

    public style() {
        return {
            backgroundImage: `url(${this.resolveUrl})`,
        };
    }

    _onImgError($event: ErrorEvent) {
        this.overrideSrc = placeholderUrl;
    }
}
