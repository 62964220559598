import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { LoadingLabelComponent } from '@shared/common/loading-label/loading-label.component';


@NgModule({
    declarations: [LoadingLabelComponent],
    imports: [
        CommonModule,
        MatProgressSpinnerModule
    ],
    exports: [LoadingLabelComponent]
})
export class LoadingLabelModule {
}
