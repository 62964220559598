import { Component, Input } from '@angular/core';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';

import { makeProvider } from '@shared/form-fields/make-provider';
import { AbstractFormFieldComponent } from '../abstract-form-field/abstract-form-field.component';

@Component({
    selector: 'app-input-password',
    styleUrls: ['./password-input.component.scss'],
    template: `
        <mat-form-field [appearance]="appearance">
            <mat-label *ngIf="label">
                {{ label }}
            </mat-label>

            <input
                [type]="inputType"
                matInput
                [(ngModel)]="value"
                [ngModelOptions]="{ standalone: true }"
                [placeholder]="placeholder"
                [disabled]="disabled"
                [readonly]="readonly"
                [minlength]="minlength"
                [maxlength]="maxlength"
                [required]="required"
                [tabIndex]="tabIndex"
                (blur)="_onTouch()"
                autocomplete="off">

            <button mat-icon-button
                    matSuffix
                    type="button"
                    (click)="showPlainText = !showPlainText">
                <mat-icon>{{ showPlainText ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>

            <mat-hint *ngIf="hint">
                {{ hint }}
            </mat-hint>

            <mat-error *ngFor="let message of errorMessages">{{ message }}</mat-error>
        </mat-form-field>
    `,
    providers: [makeProvider(PasswordInputComponent)],
})
export class PasswordInputComponent extends AbstractFormFieldComponent<string> {
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() minlength: number;
    @Input() maxlength: number;
    showPlainText: boolean = false;

    get inputType() {
        return this.showPlainText ? 'text' : 'password';
    }
}
