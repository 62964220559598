import { head, isObject } from 'lodash-es';

export function getValidationMessages(response: any): string[] {
    // Check we can see which fields failed
    if (!isObject(response) || !isObject(response['fields'])) {
        return;
    }

    const fields = response['fields'];

    return Object.keys(fields)
        .map(key => {
            const messages = fields[key];

            return head(messages) as string;
        })
        .filter(Boolean);
}
