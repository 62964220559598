import { Presenter } from '../../presenters/presenter';
import type { Team } from './team';

export class TeamPresenter extends Presenter<Team> {
    get description() {
        return this.data.description;
    }

    get name() {
        return this.data.description;
    }
}
