<app-indicator
    [indicatorColors]="indicatorColors"
    [points]="index" />
<div class="password-strength-indicator_info">
    <span class="hint">
        {{ label }}
        <cll-icon
            [cllTooltip]="strengthPasswordPopover"
            [cllTooltipDelay]="0"
            [size]="20"
            >info</cll-icon
        >
    </span>
</div>

<ng-template #strengthPasswordPopover>
    <cll-card appearance="outlined">
        <cll-card-header>
            <cll-card-title>
                A strong password is one that is difficult to guess or crack through brute-force attacks.
            </cll-card-title>
        </cll-card-header>
        <p>
            - At least 8 characters long but 16 or more is better.<br />
            - A combination of uppercase letters, lowercase letters, numbers, and symbols.<br />
        </p>
        <app-password-strength-popover
            [fulfilLength]="ui.fulfilLength"
            [hasBothCases]="ui.hasBothCases"
            [hasSymbol]="ui.hasSymbol"
            [hasNumber]="ui.hasNumber" />
    </cll-card>
</ng-template>
