import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { AppCommonModule } from '@shared/common/common.module';
import { OverridePanelComponent } from '@shared/modules/contracts/common/override-panel/override-panel.component';
import { PanelInheritedComponent } from '@shared/modules/contracts/common/override-panel/panel-inherited.component';
import { PanelTitleComponent } from '@shared/modules/contracts/common/override-panel/panel-title.component';


@NgModule({
    declarations: [
        OverridePanelComponent,
        PanelTitleComponent,
        PanelInheritedComponent
    ],
    imports: [
        CommonModule,
        AppCommonModule,
        MatExpansionModule,
        MatSlideToggleModule,
    ],
    exports: [
        MatExpansionModule,
        OverridePanelComponent,
        PanelTitleComponent,
        PanelInheritedComponent
    ],
})
export class OverridePanelModule {
}
