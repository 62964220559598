import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ParseErrorMessagePipe } from '@shared/common/pipes/parse-error-message/parse-error-message.pipe';

@NgModule({
    declarations: [ParseErrorMessagePipe],
    imports: [CommonModule],
    exports: [ParseErrorMessagePipe]
})
export class ParseErrorMessageModule {
}
