import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import type { PathMatch } from '@shared/common/route/path-match';
import { AuthShellComponent } from './auth-shell/auth-shell.component';
import { CheckEmailComponent } from './check-email/check-email.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './register/registration.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UserAgreementsComponent } from "./terms/user-agreements.component";

const routes: Routes = [
    {
        path: 'auth/one-time',
        loadChildren: () => import('./one-time/one-time.module').then(m => m.OneTimeModule),
    },

    {
        path: 'auth/support',
        component: AuthShellComponent,
        loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
    },

    {
        path: 'auth',
        component: AuthShellComponent,
        children: [
            { path: '', redirectTo: 'login', pathMatch: 'full' as PathMatch },
            { path: 'login', component: LoginComponent },
            { path: 'forgot-password', component: ForgottenPasswordComponent },
            { path: 'reset/:token', component: ResetPasswordComponent },
            { path: 'register', component: RegistrationComponent },
            { path: 'check-email', component: CheckEmailComponent },
            { path: 'mfa', loadChildren: () => import('./mfa/mfa.module').then(m => m.MfaModule) },
            { path: 'agreements', component: UserAgreementsComponent },
        ],
    },

    {
        path: '',
        redirectTo: 'auth/login',
        pathMatch: 'full' as PathMatch,
    },

    {
        path: '**',
        redirectTo: '/auth/login',
        pathMatch: 'full' as PathMatch,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {}
