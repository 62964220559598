import { Component, HostBinding, Input } from '@angular/core';
import { AbstractFormFieldComponent } from '@shared/form-fields/abstract-form-field/abstract-form-field.component';
import { makeProvider } from '@shared/form-fields/make-provider';

@Component({
    selector: 'app-input-checkbox',
    styleUrls: ['./checkbox-input.component.scss'],
    template: `
        <div class="app-input-checkbox-container">
            <mat-checkbox [(ngModel)]="value"
                          (change)="_onTouch()"
                          [ngModelOptions]="{ standalone: true }"
                          [name]="name"
                          [class.vertical]="vertical"
                          [disabled]="disabled">
                {{ label }}

                <mat-hint *ngIf="_hint">
                    {{ _hint }}
                </mat-hint>
            </mat-checkbox>

            <ng-content select="[appSuffix]"></ng-content>
        </div>

        <mat-error *ngFor="let message of errorMessages">{{ message }}</mat-error>
    `,
    providers: [makeProvider(CheckboxInputComponent)],
})
export class CheckboxInputComponent extends AbstractFormFieldComponent<boolean> {
    @Input() vertical = false;
    @Input() hintTrue: string;
    @Input() hintFalse: string;

    @HostBinding('attr.data-checked') get _dataChecked(): boolean {
        return this.value;
    }

    get _hint(): string {
        if (this.value) {
            return this.hintTrue || this.hint;
        }

        return this.hintFalse || this.hint;
    }
}
