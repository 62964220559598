
<mat-expansion-panel [(expanded)]="override"
                     #panel
                     (expandedChange)="overrideChange.emit($event)"
                     hideToggle>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-slide-toggle [(ngModel)]="panel.expanded"
                              (click)="$event.stopImmediatePropagation()"
                              matTooltip="Override option" />

            <ng-content select="app-panel-title"></ng-content>
        </mat-panel-title>

        <mat-panel-description>
            <div class="float-right">
                <div class="current-value-badge"
                     *ngIf="_currentInherited?.html && !override"
                     matTooltip="Currently active value">
                    <span class="badge" [innerHTML]="_currentInherited.html"></span>
                </div>

                <span class="badge"
                      [class.badge-purple]="override"
                      [matTooltip]="override ? 'Overriding' : 'Inherited from contract type'">
                        {{ override ? 'Option Overridden' : 'Inherited' }}
                    </span>
            </div>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
        <ng-content></ng-content>

        <ng-container *ngIf="showInheritedContainer">
            <hr class="app-separator">
            <h4 class="form-section-header">
                Inherited values

                <mat-icon style="margin-left: 4px; cursor: pointer" class="size-16"
                          matTooltip="Values that will be overridden from the contract type">
                    help
                </mat-icon>
            </h4>
        </ng-container>

        <div class="inherited-container" #inheritedContainer>
            <ng-content select="app-panel-inherited"></ng-content>

            <ng-container *ngIf="_inherited">
                <div class="inherited-value" *ngFor="let i of _inherited">
                    <strong>{{ (i.date | date) || 'Default' }} &rarr;</strong>
                    <span [innerHTML]="i.html"></span>
                </div>
            </ng-container>
        </div>
    </ng-template>
</mat-expansion-panel>
