import { inject, Injectable } from '@angular/core';
import type { MatLegacySnackBarConfig as MatSnackBarConfig, MatLegacySnackBarRef as MatSnackBarRef, LegacySimpleSnackBar as SimpleSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppErrorSnackbarComponent } from './error/app-error-snackbar.component';
import { AppProgressSnackbarComponent } from './progress/app-progress-snackbar.component';
import { AppSuccessSnackbarComponent } from './success/app-success-snackbar.component';
import { AppActionRequiredSnackbarComponent } from './action-required/app-action-required-snackbar.component';

@Injectable({ providedIn: 'root' })
export class AppSnackBar {
    constructor(private snackBar: MatSnackBar
    ) {
    }

    show(message: string, options: MatSnackBarConfig = {}, action: string = null): MatSnackBarRef<SimpleSnackBar> {
        return this.snackBar.open(message, action, {
            duration: 3000,
            ...options,
        });
    }

    showWarningWithAction(
        title: string,
        message: string,
        action?: string,
    ): MatSnackBarRef<AppActionRequiredSnackbarComponent> {
        return this.snackBar.openFromComponent(AppActionRequiredSnackbarComponent, {
            panelClass: 'app-snackbar-action-required-warning',
            data: {
                action: action ?? 'View Details',
                title,
                message,
                type: 'warning',
            },
        });
    }

    showErrorWithAction(
        title: string,
        message: string,
        action?: string,
    ): MatSnackBarRef<AppActionRequiredSnackbarComponent> {
        return this.snackBar.openFromComponent(AppActionRequiredSnackbarComponent, {
            panelClass: 'app-snackbar-action-required-error',
            data: {
                action: action ?? 'View Details',
                title,
                message,
                type: 'error',
            },
        });
    }

    showSuccess(message: string, action?: string): MatSnackBarRef<AppSuccessSnackbarComponent> {
        return this.snackBar.openFromComponent(AppSuccessSnackbarComponent, {
            duration: 3000,
            panelClass: 'app-snackbar-success',
            data: {
                status: 'success',
                action: action ?? 'Dismiss',
                message
            },
        });
    }

    showError(message: string, action: string = 'Dismiss'): MatSnackBarRef<SimpleSnackBar> {
        return this.snackBar.openFromComponent(AppErrorSnackbarComponent, {
            duration: 5000,
            panelClass: 'app-snackbar-error',
            data: {
                action,
                message
            },
        });
    }

    showProgress(message: string): MatSnackBarRef<AppProgressSnackbarComponent> {
        return this.snackBar.openFromComponent(AppProgressSnackbarComponent, {
            duration: 0,
            panelClass: 'app-snackbar-progress',
            data: {
                message
            },
        });
    }

    showUndo(message: string): MatSnackBarRef<SimpleSnackBar> {
        return this.snackBar.openFromComponent(AppSuccessSnackbarComponent, {
            duration: 5000,
            panelClass: 'app-snackbar-undo',
            data: {
                action: 'Undo',
                message
            },
        });
    }
}

export function injectSnackBar(): AppSnackBar {
    return inject(AppSnackBar);
}
