import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BadgeComponent } from './badge.component';
import { BadgeCollectionComponent } from './app-badge-collection/badge-collection.component';


@NgModule({
    declarations: [
        BadgeComponent,
        BadgeCollectionComponent
    ],
    exports: [
        BadgeComponent,
        BadgeCollectionComponent
    ],
    imports: [
        CommonModule,
        MatIconModule
    ]
})
export class AppBadgeModule { }
