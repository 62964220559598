<mat-tab-group #mileageBandTabs animationDuration="0ms">
    <mat-tab *ngFor="let band of _bands; trackBy: trackBy('max_distance'); let bandIndex = index;">
        <ng-template matTabLabel>
            {{ band.max_distance > 0 ? 'Up to ' + band.max_distance + ' ' + distanceUnit : 'Default' }}
        </ng-template>

        <ng-template matTabContent>
            <mat-card>
                <mat-card-content>
                    <ng-container *ngIf="!editingPayElementCodes">
                        <app-input-number label="Distances up to ({{ distanceUnit }})"
                                          *ngIf="band.max_distance !== null; else defaultBand"
                                          [(ngModel)]="band.max_distance"
                                          (ngModelChange)="onDistanceChanged(band)"
                                          [ngModelOptions]="{ updateOn: 'blur' }"
                                          [disabled]="editingPayElementCodes"
                                          [required]="true"
                                          [min]="1"
                                          [step]="1" />

                        <ng-template #defaultBand>
                            <p class="hint">
                                Distance for the default band cannot be changed.
                            </p>
                        </ng-template>

                        <hr class="app-separator">
                    </ng-container>

                    <table>
                        <colgroup>
                            <col style="width: 25%">
                            <col style="width: 25%">
                            <col style="width: 25%">
                            <col style="width: 25%">
                        </colgroup>
                        <tbody>
                        <tr *ngFor="let rate of defaultMileageRates">
                            <td>
                                <label [for]="rate.key">
                                    {{ rate.name }}
                                </label>

                                <small class="block hint">
                                    Default: {{ rate.default.amount | currency }}/{{ distanceUnitSingular }}
                                </small>
                            </td>

                            <td>
                                <app-select-form-field label="Type"
                                                       appearance="standard"
                                                       [options]="mileageRateTypeOptions"
                                                       [(ngModel)]="band.rates[rate.key].type"
                                                       (ngModelChange)="_updateModel()"
                                                       [disabled]="editingPayElementCodes"
                                                       [required]="true" />
                            </td>

                            <td>
                                <app-input-currency
                                    appearance="standard"
                                    label="{{ (band.rates[rate.key].type === 'fixed') ? 'Fixed amount' : 'Per ' + distanceUnitSingular }}"
                                    [id]="rate.key"
                                    [(ngModel)]="band.rates[rate.key].amount"
                                    (ngModelChange)="_updateModel()"
                                    [disabled]="editingPayElementCodes"
                                    [min]="0"
                                    [max]="1000"
                                    [required]="true" />
                            </td>

                            <td>
                                <app-input-text label="Pay element code"
                                                appearance="standard"
                                                *ngIf="editingPayElementCodes"
                                                [(ngModel)]="band.rates[rate.key].pay_element_code"
                                                (ngModelChange)="_updateModel()" />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </mat-card-content>

                <mat-card-actions>
                    <button mat-icon-button
                            color="warn"
                            (click)="deleteMileageBand(bandIndex)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-card-actions>
            </mat-card>
        </ng-template>
    </mat-tab>

    <mat-tab *ngIf="!editingPayElementCodes">
        <ng-template matTabLabel>
            <div matTooltip="Add mileage band">
                <mat-icon>add</mat-icon>
                Add mileage band
            </div>
        </ng-template>

        <ng-template matTabContent>
            <div class="add-band-content">
                <mat-card>
                    <mat-card-content>
                        <p>
                            Enter a maximum journey distance for this mileage band
                        </p>

                        <app-input-number
                            label="Up to {{ distanceUnit }}"
                            [min]="1"
                            [step]="1"
                            [(ngModel)]="_newMileageBand.max_distance"
                            [required]="true" />
                    </mat-card-content>

                    <mat-card-actions>
                        <button mat-raised-button
                                [disabled]="!_newMileageBand.max_distance"
                                (click)="addNewBand()">
                            Add mileage band
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </ng-template>
    </mat-tab>

</mat-tab-group>
