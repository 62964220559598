import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-forgotten-password',
    templateUrl: './forgotten-password.component.html',
    styleUrls: ['./forgotten-password.component.scss'],
})
export class ForgottenPasswordComponent {
    sendingPasswordReset = false;
    form = new FormGroup({
        email: new FormControl(null, [Validators.email, Validators.required]),
    });

    passwordResetRequired$ = this.route.queryParams.pipe(map(params => params['password_reset_required'] === 'true'));

    protected readonly isFromApp = toSignal(this.route.queryParams.pipe(map(params => params['from-app'] === 'true')));

    constructor(
        private auth: AuthService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
    }

    sendPasswordReset() {
        const { email } = this.form.value;
        this.sendingPasswordReset = true;
        this.auth
            .sendPasswordReset(email)
            .pipe(
                finalize(() => {
                    this.sendingPasswordReset = false;
                }),
            )
            .subscribe(response => {
                if (response.successful) {
                    this.router.navigate(['/', 'auth', 'check-email']);
                }
            });
    }
}
