// Morning 05.00-11.00
// Lunch 11.00-14.30
// Tea 15.00-18.00
// Evening 18.00-23.00
import { decimalHoursToHoursMinutes, hoursMinutesToDecimal } from '@shared/utils/date-time';

export const MORNING = [5, 11];
export const LUNCH = [11, 15];
export const TEA = [15, 18];
export const EVENING = [18, 23];

export enum TimeOfDay {
    morning = 'morning',
    lunch = 'lunch',
    tea = 'tea',
    evening = 'evening',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TimesOfDay = Object.freeze([
    TimeOfDay.morning,
    TimeOfDay.lunch,
    TimeOfDay.tea,
    TimeOfDay.evening,
]);

export enum TimeOfDayTitle {
    morning = 'Morning',
    lunch = 'Lunch',
    tea = 'Tea',
    evening = 'Evening',
}

export function startTimeFor(time: TimeOfDay) {
    switch (time) {
        case TimeOfDay.morning:
            return decimalHoursToHoursMinutes(MORNING[0]);

        case TimeOfDay.lunch:
            return decimalHoursToHoursMinutes(LUNCH[0]);

        case TimeOfDay.tea:
            return decimalHoursToHoursMinutes(TEA[0]);

        case TimeOfDay.evening:
            return decimalHoursToHoursMinutes(EVENING[0]);
    }
}

export function finishTimeFor(time: TimeOfDay) {
    switch (time) {
        case TimeOfDay.morning:
            return decimalHoursToHoursMinutes(MORNING[1]);

        case TimeOfDay.lunch:
            return decimalHoursToHoursMinutes(LUNCH[1]);

        case TimeOfDay.tea:
            return decimalHoursToHoursMinutes(TEA[1]);

        case TimeOfDay.evening:
            return decimalHoursToHoursMinutes(EVENING[1]);
    }
}

export function guessTimeOfDay(start: string | number): TimeOfDay {
    if (typeof start === 'string') {
        start = hoursMinutesToDecimal(start);
    }

    if (isNaN(start)) {
        return undefined;
    }

    if (start >= MORNING[0] && start < MORNING[1]) {
        return TimeOfDay.morning;
    }

    if (start >= LUNCH[0] && start < LUNCH[1]) {
        return TimeOfDay.lunch;
    }

    if (start >= TEA[0] && start < TEA[1]) {
        return TimeOfDay.tea;
    }

    if (start >= EVENING[0] && start < EVENING[1]) {
        return TimeOfDay.evening;
    }
}

export function guessTimeOfDayTitle(start: string | number): string {
    const timeOfDay = guessTimeOfDay(start);

    return TimeOfDayTitle[timeOfDay] ?? null;
}
