import { Component, Input } from '@angular/core';
import type { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { AbstractFormFieldComponent } from '@shared/form-fields/abstract-form-field/abstract-form-field.component';

import { makeProvider } from '@shared/form-fields/make-provider';
import {
    decimalHoursToSeconds,
    hoursMinutesSecondsToSeconds,
    hoursMinutesToSeconds,
    secondsToHoursMinutes,
    secondsToHoursMinutesSeconds,
    secondsToReadablePrecise
} from '@shared/utils/date-time';

@Component({
    selector: 'app-input-duration',
    styleUrls: ['./duration-input.component.scss'],
    template: `
        <mat-form-field [appearance]="appearance">
            <mat-label *ngIf="label">
                {{ label }}
            </mat-label>

            <div matPrefix>
                <ng-content select="[appPrefix]"></ng-content>
            </div>

            <input
                matInput
                #timeInput
                type="text"
                [(ngModel)]="duration"
                [ngModelOptions]="{ standalone: true, updateOn: 'blur' }"
                [placeholder]="placeholder"
                [disabled]="disabled"
                [readonly]="readonly"
                (blur)="onInputBlur()"
                (keypress)="onInputKeyPress($event)"
                [matAutocomplete]="auto">

            <div matSuffix class="suffix" style="top: 0">
                <app-badge *ngIf="showDurationHint && _value !== null && _value !== undefined">
                    {{ durationHint }}
                </app-badge>

                <ng-content select="[appSuffix]"></ng-content>

                <button mat-icon-button *ngIf="clearable" (click)="clear($event)">
                    <mat-icon>clear</mat-icon>
                </button>
            </div>

            <mat-hint *ngIf="hint">
                {{ hint }}
            </mat-hint>

            <mat-error *ngFor="let message of errorMessages">{{ message }}</mat-error>
        </mat-form-field>

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="presetSelected($event)">
            <mat-option *ngFor="let option of autocompleteOptions"
                [value]="option.value"
                [disabled]="option.value === value">
                {{ option.label }}
            </mat-option>
        </mat-autocomplete>
    `,
    providers: [makeProvider(DurationInputComponent)],
    styles: [
        `:host {
                display: block
        }`,
        `.mat-form-field {
                width: 100%
        }`
    ]
})
export class DurationInputComponent extends AbstractFormFieldComponent<number> {
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() showDurationHint = true;
    @Input() allowSeconds: boolean = false;

    autocompleteOptions: AutocompleteOption[] = [
        { label: '10m', value: 10 * 60 },
        { label: '15m', value: 15 * 60 },
        { label: '20m', value: 20 * 60 },
        { label: '30m', value: 30 * 60 },
        { label: '45m', value: 45 * 60 },
        { label: '1h', value: 60 * 60 },
        { label: '1h 15m', value: 75 * 60 },
        { label: '1h 30m', value: 90 * 60 },
        { label: '1h 45m', value: 105 * 60 },
        { label: '2h', value: 120 * 60 },
    ];

    _duration: string;

    get durationHint() {
        return secondsToReadablePrecise(this.value);
    }

    get duration() {
        return this._duration;
    }

    set duration(duration: string) {
        this._duration = duration;

        this.value = parseInput(duration, this.allowSeconds);
    }

    onInputBlur() {
        if (this.allowSeconds) {
            this._duration = secondsToHoursMinutesSeconds(this._value);
        } else {
            this._duration = secondsToHoursMinutes(this._value);
        }
    }

    onInputKeyPress($event: KeyboardEvent) {
        if ($event.code === 'Escape') {
            $event.preventDefault();

            ($event.target as HTMLInputElement).blur();
        }
    }

    onValueChanged(value: number) {
        if (this.allowSeconds) {
            this._duration = secondsToHoursMinutesSeconds(value);
        } else {
            this._duration = secondsToHoursMinutes(value);
        }
    }

    presetSelected($event: MatAutocompleteSelectedEvent) {
        this.value = $event.option.value;
    }
}

interface AutocompleteOption {
    label: string;
    value: number;
}

function parseInput(input: string, allowSeconds: boolean): number {
    if (input?.length) {
        // Is it a decimal
        if (isDecimalHoursString(input)) {
            return decimalHoursToSeconds(input);
        } else if (allowSeconds && isHoursMinutesSecondsString(input)) {
            return hoursMinutesSecondsToSeconds(input);
        } else if (isHoursMinuteString(input)) {
            return hoursMinutesToSeconds(input);
        } else if (isNaturalLanguage(input)) {
            return naturalLanguageToSeconds(input);
        }
    }

    return null;
}

const REGEX_HOURS_MINUTE = /^(?:\d*:)?\d{0,2}$/;
const REGEX_HOURS_MINUTES_SECONDS = /^\d+:\d{0,2}:\d{0,2}$/;
const REGEX_DECIMAL_HOURS = /^(?:\d*\.)?\d*$/;
const REGEX_NATURAL_LANGUAGE = /^(?:(\d+)\s*(?:hours|hour|h))?(?:\s*(?:and)?\s*)?(?:(\d{0,2})\s*(?:m|min|mins|minute|minutes))?$/i;

function isDecimalHoursString(input) {
    return REGEX_DECIMAL_HOURS.test(String(input));
}

function isNaturalLanguage(input) {
    return REGEX_NATURAL_LANGUAGE.test(String(input));
}

function isHoursMinuteString(input) {
    return REGEX_HOURS_MINUTE.test(String(input));
}

function isHoursMinutesSecondsString(input) {
    return REGEX_HOURS_MINUTES_SECONDS.test(String(input));
}

function naturalLanguageToSeconds(input): number {
    const matches = String(input).match(REGEX_NATURAL_LANGUAGE);

    if (!matches) {
        return null;
    }

    const hours = parseInt(matches[1], 10) || 0;
    const minutes = parseInt(matches[2], 10) || 0;

    return Math.floor(hours * 3600 + minutes * 60);
}
