import { Component, Input } from '@angular/core';
import { AppCommonModule } from '@shared/common/common.module';
import { PopoverModule } from '@shared/common/popover/popover.module';
import { AppSharedModule } from '@shared/shared.module';

interface Indicator {
    at: number;
    color: string;
}

@Component({
    selector: 'app-indicator',
    templateUrl: './indicator.component.html',
    styleUrls: ['./indicator.component.scss'],
    standalone: true,
    imports: [PopoverModule, AppCommonModule],
})
export class IndicatorComponent {
    @Input() points: number = 0;
    @Input() indicatorColors: Indicator[] = [];
}
