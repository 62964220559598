import { Injectable } from '@angular/core';
import get from 'lodash-es/get';

@Injectable()
export class PermissionsService {
    private readonly permissions: string[];

    private readonly _cache = new Map<string, boolean>();

    constructor() {
        this.permissions = get(window, 'CareLineLive.user.permissions', []);
    }

    can(permissionsQuery: string): boolean {
        if (!permissionsQuery) {
            return false;
        }

        if (this._cache.has(permissionsQuery)) {
            return this._cache.get(permissionsQuery);
        }

        const operators = ['&&', '||', '!', '(', ')'];

        // Check whether the permissions contains any operators
        const containsOperator = operators.find(operator => permissionsQuery.includes(operator));

        if (!containsOperator) {
            return this.hasPermission(permissionsQuery);
        }

        const permissionsArray = permissionsQuery
            .split(/(\|\||&&|!|\(|\))/g)
            .map(part => part.trim())
            .filter(part => !!part)
            .map(part => {
                if (operators.indexOf(part) > -1) {
                    return part;
                }

                // If it's not an operator, we want to wrap it in our function call
                return `this.hasPermission('${part}')`;
            });

        // Using eval so we can use JS style operations
        // eslint-disable-next-line no-eval
        const result = eval(permissionsArray.join(' '));

        this._cache.set(permissionsQuery, result);

        return result;
    }

    hasPermission(permission: string): boolean {
        if (/\s/g.test(permission)) {
            console.warn('hasPermission() received ' + permission);

            throw new Error('hasPermission() cannot accept an expression');
        }

        if (this._cache.has(permission)) {
            return this._cache.get(permission);
        }

        return this.permissions.indexOf(permission) > -1;
    }

    doesNotHavePermission(permission: string): boolean {
        return !this.hasPermission(permission);
    }
}
