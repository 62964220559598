import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { isPresent } from '@shared/utils/helpers';

@Pipe({ name: 'isPresent' })
export class IsPresentPipe implements PipeTransform {
    transform(value: any | any[]): any {
        if (Array.isArray(value)) {
            return isPresent(...value);
        }

        return isPresent(value);
    }
}
