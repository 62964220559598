import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { IconModule, PanelModule } from '@carelinelive/ui';
import { AlertComponent } from './alert/alert.component';
import { ApiErrorAlertComponent } from './api-error-alert/api-error-alert.component';
import { ErrorMessagesComponent } from "@shared/common/errors/error-messages.component";

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        PanelModule,
        IconModule,
        ErrorMessagesComponent
    ],
    declarations: [AlertComponent, ApiErrorAlertComponent],
    exports: [AlertComponent, ApiErrorAlertComponent]
})
export class AppAlertModule {

}
