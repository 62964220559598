import type { Model } from '@shared/models/model';
import type { HourlyRateRange } from '@shared/modules/rates/hourly-rate-range';
import type { NonProRataRate } from '@shared/modules/rates/non-pro-rata-rate';
import { toDateTime } from '@shared/utils/date-time';
import { orderBy } from 'lodash-es';
import { DateTime } from 'luxon';
import type { IDurationRounding } from '../contracts/common/duration-rounding/duration-rounding';

export type RateStrategy = 'fixed' | 'hourly';

export interface HasDefaultRateStrategy {
    default_rate_strategy: RateStrategy;
}

export interface ContractOptionSetState<T extends ContractOptionSet> {
    value: T;
}

export interface ContractOptionSet extends Model, HasDefaultRateStrategy {
    applies_from: string;

    hourly_rate: number;
    hourly_rate_ranges?: HourlyRateRange[];
    non_pro_rata_rates?: NonProRataRate[];

    uses_actual_times_for_calculation: boolean;
    use_first_range: boolean;

    duration_rounding: IDurationRounding;

    pay_element_code_hourly_rate?: string;
    pay_element_code_travel_time?: string;
    pay_element_code_guaranteed_pay?: string;

    override_leave_type_pay_elements?: boolean;
    leave_type_pay_elements?: {
        [prop: string]: string;
    };
}

export function sortOptionSets<T extends ContractOptionSet>(sets: T[]): T[] {
    return orderBy(sets, set => set.applies_from || '', ['asc']);
}

export function sortOptionSetStates<T extends ContractOptionSetState<any>>(sets: T[]): T[] {
    return orderBy(sets, set => set.value.applies_from || '', ['asc']);
}

export function getOptionSetIndex(sets: ContractOptionSet[], set: ContractOptionSet): number {
    if (!sets || !sets.length) {
        return -1;
    }

    if (set.id) {
        return sets.findIndex(s => s.id === set.id);
    } else {
        return sets.findIndex(s => s.applies_from === set.applies_from);
    }
}

export function currentOptionSet(optionSets: ContractOptionSet[]): ContractOptionSet {
    const now = DateTime.local();

    return optionSets.slice().reverse().find(set => toDateTime(set.applies_from, false) <= now);
}

export function currentOptionSetByDate(dates: string[]): string {
    if (dates.length === 1) {
        return dates[0];
    }

    const now = DateTime.local();

    dates = [...dates];

    return dates
        .filter(Boolean)
        .sort()
        .find(date => toDateTime(date, false) <= now);
}

export function replaceOptionSet(sets: ContractOptionSet[], oldSet: ContractOptionSet, newSet: ContractOptionSet) {
    const index = getOptionSetIndex(sets, oldSet);

    if (index > -1) {
        sets[index] = newSet;
    } else {
        sets.push(newSet);
    }
}

export function replaceOptionSetState(
    sets: ContractOptionSetState<ContractOptionSet>[],
    oldSet: ContractOptionSetState<ContractOptionSet>,
    newSet: ContractOptionSetState<ContractOptionSet>
) {
    const index = getOptionSetIndex(sets.map(state => state.value), oldSet.value);

    if (index > -1) {
        sets[index] = newSet;
    } else {
        sets.push(newSet);
    }
}

export function removeOptionSet(sets: ContractOptionSet[], set: ContractOptionSet) {
    sets.splice(getOptionSetIndex(sets, set), 1);
}

export function removeOptionSetFromState(sets: ContractOptionSetState<ContractOptionSet>[], set: ContractOptionSet) {
    sets.splice(getOptionSetIndex(sets.map(state => state.value), set), 1);
}

export function findCurrentOptionSet(sets: ContractOptionSet[]) {
    const now = DateTime.local();

    return sets.slice().reverse().find(set => toDateTime(set.applies_from, false) <= now);
}

export function findCurrentOptionSetState(sets: ContractOptionSetState<ContractOptionSet>[]) {
    const now = DateTime.local();

    return sets.slice().reverse().find(set => toDateTime(set.value.applies_from, false) <= now);
}
