import { Component, Input } from '@angular/core';
import { AppCommonModule } from '@shared/common/common.module';
import { PopoverModule } from '@shared/common/popover/popover.module';

@Component({
    selector: 'app-password-strength-popover',
    templateUrl: './password-strength-popover.component.html',
    styleUrls: ['./password-strength-popover.component.scss'],
    standalone: true,
    imports: [PopoverModule, AppCommonModule],
})
export class PasswordStrengthPopoverComponent {
    @Input() fulfilLength = false;
    @Input() hasBothCases = false;
    @Input() hasSymbol = false;
    @Input() hasNumber = false;
}
