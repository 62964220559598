import { isEqualWith, isFunction, isObject, isString } from 'lodash-es';
import { DateTime } from 'luxon';

export function isDefined(...elements): boolean {
    for (const el of elements) {
        if (el === undefined) {
            return false;
        }
    }

    return true;
}

export function isPresent(...elements: any[]): boolean {
    for (const el of elements) {
        if (el === undefined || el === null) {
            return false;
        }
    }

    return true;
}

export function isAnyPresent(...elements): boolean {
    for (const el of elements) {
        if (Number.isNaN(el)) {
            continue;
        }

        if (el !== undefined && el !== null) {
            return true;
        }
    }

    return false;
}


interface EqualsOptions {
    ignoreEmpty?: boolean,
    compareObjectIds?: boolean,
    serializeDates?: boolean,
}

export function equals(original: any, other: any, options: EqualsOptions = {}): boolean {
    const {
        ignoreEmpty = false,
        compareObjectIds = false,
        serializeDates = false,
    } = options;

    // isEqualWith has trouble with arrays, so we need to handle them separately
    if (Array.isArray(original)) {
        // Must both be arrays
        if (!Array.isArray(other)) {
            return false;
        }

        // Must be the same length to be equal
        if (original.length !== other.length) {
            return false;
        }

        // Loop through each index and compare the values recursively
        for (let i = 0; i < original.length; i++) {
            if (!equals(original[i], other[i], options)) {
                return false;
            }
        }
    }

    return isEqualWith(original, other, (a, b, key) => {
        if ((isString(key) && key.charAt(0) === '$') || (isFunction(a) && isFunction(b))) {
            return true;
        }

        if (key === 'presenter') {
            return true;
        }

        if (ignoreEmpty && isEmpty(a) && isEmpty(b)) {
            return true;
        }

        if (serializeDates) {
            if (a instanceof DateTime || b instanceof DateTime) {
                const aIso = a instanceof DateTime ? a.toISO() : a;
                const bIso = b instanceof DateTime ? b.toISO() : b;

                return aIso === bIso;
            }
        }

        if (compareObjectIds && key && isObject(a) && isObject(b)) {
            // Shallow check for nested item equality

            if (a.hasOwnProperty('id') || b.hasOwnProperty('id')) {
                return a?.['id'] === b?.['id'];
            }
        }
    });
}

function isEmpty(value: any) {
    return value === null || value === undefined || ((isString(value) || Array.isArray(value)) && !value.length);
}

/**
 * @param value
 * @return
 */
export function mimeType(value) {
    if (!value) {
        return value;
    }

    if (value.substr(0, 6) === 'image/') {
        return 'Image';
    }

    if (value.substr(0, 6) === 'video/') {
        return 'Video';
    }

    switch (value) {
        case null:
            return 'Unknown file type';

        case 'application/vnd.ms-outlook':
        case 'message/rfc822':
            return 'Email';

        case 'application/vnd.ms-office':
            return 'Office Document';

        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'Word Document';

        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return 'Excel Spreadsheet';

        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return 'Powerpoint Presentation';

        case 'application/pdf':
            return 'PDF Document';
    }

    return null;
}

export function parseBoolean(value: any): boolean | null {
    if (isPresent(value)) {
        return value === true || value === 'true' || value === 1;
    }

    return value;
}

export function parseNumber(value: any): number {
    if (isPresent(value)) {
        const number = Number(value);

        if (!isNaN(number)) {
            return number;
        }
    }

    return value;
}

export function keyBy<
    T extends Record<string, any>,
    K extends keyof T
>(value: T[], key: keyof T): Record<string, T> {
    const output: Record<string, T> = {};

    value.forEach(item => {
        const itemKey = item[key];

        // Check if it's keyworthy
        if (itemKey === undefined || itemKey === null) {
            throw new Error(`Key "${String(key)}" is not defined in item: ${JSON.stringify(value)}`);
        }

        // check it's a string
        if (typeof itemKey !== 'string') {
            throw new Error(`Property "${String(key)}" is not a string in item: ${JSON.stringify(value)}`);
        }

        output[itemKey] = item;
    });

    return output;
}
