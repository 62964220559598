<cll-panel [colour]="'primary'">
    <cll-icon cllPanelIcon>info</cll-icon>
    <div cllPanelAction>
        <button cll-icon-button (click)="dismiss.emit()">
            <cll-icon >close</cll-icon>
        </button>
    </div>
    <p>
        <b> Access to this software and any information it contains is limited to authorised users only.</b>
    </p>

    <p>
        Legal action can be taken against unauthorised use of, or unauthorised access to, this software and/or any
        information it contains, including pursuant to the
        <a href="https://www.legislation.gov.uk/ukpga/1990/18/contents" target="_blank">Computer Misuse Act 1990</a>.
    </p>

    <p>
        If you are an authorised user, by proceeding to access and use this software and/or the information it contains,
        you are accepting any terms of use, notices and policies which are contained or referenced within it or which
        have otherwise been drawn to your attention as an authorised user.
    </p>

    <ng-container *ifCountry="AUSTRALIA">
        <hr />

        <p style="text-align: center">
            <a (click)="showCollectionNoticeDialog()">Privacy Collection Notice</a>
        </p>
    </ng-container>
</cll-panel>
