import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { CardModule, IconModule } from '@mastechnicaegroup/carelinelive-ui';
import { AppPipesModule } from '@shared/common/pipes.module';
import { AppAvatarModule } from '@shared/modules/avatar/avatar.module';
import { RoleMultiSelectComponent } from '@shared/modules/roles/select/role-multi-select.component';
import { TeamMultiSelectComponent } from '@shared/modules/teams/select/team-multi-select.component';
import { UserMultiSelectComponent } from '@shared/modules/users/select/user-multi-select.component';
import { AutocompleteInputComponent } from './autocomplete/autocomplete-input.component';
import { CheckboxInputComponent } from './checkbox/checkbox-input.component';
import { ChipsFormFieldComponent } from './chips/chips-form-field.component';
import { ColourInputComponent } from './colour/colour-input.component';
import { CurrencyInputComponent } from './currency/currency-input.component';
import { DataFieldComponent } from './data-field/data-field.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { DateInputComponent } from './date/date-input.component';
import { AppSuffixDirective } from './dumb-elements/suffix.directive';
import { DurationInputComponent } from './duration/duration-input.component';
import { InputMultiEmailComponent } from './email/email-input.component';
import { FileDropZoneComponent } from './file-drop-zone/file-drop-zone.component';
import { FileInputComponent } from './file/file-input.component';
import { FormFieldsSharedModule } from './form-fields-shared.module';
import { GroupedSelectFormFieldComponent } from './grouped-select/grouped-select-form-field.component';
import { InputErrorsComponent } from './input-errors/input-errors.component';
import { MultiSelectFormFieldComponent } from './multi-select/multi-select-form-field.component';
import { NumberInputComponent } from './number/number-input.component';
import {
    PasswordConfirmationInputComponent
} from './password-input-confirmation/password-confirmation-input.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { AppRadioGroupCardInputComponent } from './radio-group-card-input/radio-group-card-input.component';
import { SelectFormFieldComponent } from './select/select-form-field.component';
import { SlideToggleInputComponent } from './slide-toggle/slide-toggle-input.component';
import { StaticInputComponent } from './static/static-input.component';
import { TextAreaInputComponent } from './text-area-input/text-area-input.component';
import { TextInputComponent } from './text/text-input.component';
import { TimeInputComponent } from './time/time-input.component';
import { TimestampInputComponent } from './timestamp/timestamp-input.component';
import { WhenInputComponent } from './when/when-input.component';
import { TagComponent } from '@shared/modules/document-tags/tag/tag.component';
import { AppColourSelectionInputComponent } from './colour-selection-input/colour-selection-input.component';

const components = [
    DateInputComponent,
    DateRangeComponent,
    DurationInputComponent,
    TextInputComponent,
    NumberInputComponent,
    TextAreaInputComponent,
    TimeInputComponent,
    CheckboxInputComponent,
    SelectFormFieldComponent,
    MultiSelectFormFieldComponent,
    WhenInputComponent,
    StaticInputComponent,
    DataFieldComponent,
    AppSuffixDirective,
    TeamMultiSelectComponent,
    RoleMultiSelectComponent,
    CurrencyInputComponent,
    PasswordInputComponent,
    PasswordConfirmationInputComponent,
    ChipsFormFieldComponent,
    AutocompleteInputComponent,
    GroupedSelectFormFieldComponent,
    FileInputComponent,
    TimestampInputComponent,
    ColourInputComponent,
    FileDropZoneComponent,
    InputErrorsComponent,
    UserMultiSelectComponent,
    InputMultiEmailComponent,
    AppRadioGroupCardInputComponent,
    AppColourSelectionInputComponent
];

@NgModule({
    declarations: [
        ...components,
        SlideToggleInputComponent
    ],
    imports: [
        FormFieldsSharedModule,
        MatSlideToggleModule,
        AppPipesModule,
        MatMenuModule,
        MatListModule,
        MatProgressSpinnerModule,
        OverlayModule,
        AppAvatarModule,
        CardModule,
        IconModule,
        TagComponent
    ],
    exports: [
        FormFieldsSharedModule,
        ...components,
        SlideToggleInputComponent
    ],
})
export class AppFormFieldsModule {
}
