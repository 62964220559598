import { Injectable } from '@angular/core';
import type { HelpArticle, HelpArticleKey } from '@shared/modules/help-desk/articles';
import { articles } from '@shared/modules/help-desk/articles';
import _default from 'chart.js/dist/plugins/plugin.tooltip';
import { IntercomService } from './intercom.service';

@Injectable({
    providedIn: 'root'
})
export class HelpDeskService {
    constructor(
        private intercom: IntercomService
    ) {
    }

    public open(article: HelpArticle | HelpArticleKey, newTab = false): void {
        if (!article) {
            return;
        }

        const definition = typeof article === 'string' ? articles[article] : article;

        if (!definition) {
            console.warn('Missing help article definition');
            return;
        }

        const intercomId = definition.i;

        if (intercomId) {
            this.showIntercomArticle(intercomId, newTab);
        }

        console.warn('Missing help article definition');
    }

    public intercomUrl(article: HelpArticle | number | string): string | null {
        if (typeof article === 'number' || typeof article === 'string') {
            return `https://help.carelinelive.com/en/articles/${article}`;
        }

        return article.i ? `https://help.carelinelive.com/en/articles/${article.i}` : null;
    }

    public showIntercomArticle(intercomId: number, newTab = false): void {
        console.log(intercomId);

        if (!newTab && this.intercom.loaded && this.intercom.showArticle(intercomId)) {
            return;
        }

        window.open(this.intercomUrl(intercomId), '_blank');
    }
}
