import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from '@carelinelive/ui';
import { AppPipesModule } from '@shared/common/pipes.module';
import { DateComponent } from '@shared/common/timestamp/date.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { TimestampComponent } from './timestamp.component';

@NgModule({
    declarations: [
        TimestampComponent,
        DateComponent,
        DateRangeComponent
    ],
    exports: [
        TimestampComponent,
        DateComponent,
        DateRangeComponent
    ],
    imports: [
        CommonModule,
        AppPipesModule,
        TooltipModule,
    ]
})
export class AppTimestampModule {
}
