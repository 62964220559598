<div class="ranges-table-container" *ngIf="_rangesModel.length">
    <table class="ranges-table">
        <thead>
        <tr class="no-vertical-padding">
            <th class="day-cell"></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th class="spacer"></th>

            <th [attr.colspan]="rates.length + 1"
                class="non-pro-rata-rates-heading"
                matTooltip="Non-pro-rata rates">
                NPRRs
            </th>
        </tr>

        <tr>
            <th class="day-cell"></th>
            <th>All day?</th>
            <th>Start</th>
            <th>Finish</th>
            <th>Hourly Rate</th>
            <th class="spacer"></th>

            <th *ngFor="let rate of rates; trackBy: _trackRateFn" class="rate">
                {{ rate | appDuration }}

                <button mat-icon-button
                        matTooltip="Delete rate"
                        (click)="deleteRate(rate)">
                    <mat-icon>clear</mat-icon>
                </button>
            </th>

            <th style="text-align: center">
                <button mat-icon-button
                        matTooltip="Add non-pro-rata rate"
                        (click)="addRate()">
                    <mat-icon>add</mat-icon>
                </button>
            </th>
        </tr>

        </thead>
        <tbody>
        <tr *ngFor="let range of _rangesModel; let i = index; trackBy: _trackRange">
            <td class="day-cell">
                <mat-label class="text-label-medium">
                    {{ range.description }}
                </mat-label>
                <mat-form-field appearance="outline"
                                class="day-select"
                                [hideRequiredMarker]="true"
                                *ngIf="specialDays">

                    <mat-select [(ngModel)]="range.day"
                                [compareWith]="_compareDaysWith"
                                placeholder="Choose a day"
                                [required]="true">
                        <mat-option value="weekday">Weekdays</mat-option>
                        <mat-option value="weekend">Weekend</mat-option>
                        <mat-option value="public_holiday">Public Holidays</mat-option>

                        <mat-optgroup label="Special Days"
                                      *ngIf="specialDays.length">
                            <mat-option *ngFor="let specialDay of specialDays"
                                        [value]="specialDay.rangeValue">
                                {{ specialDay.name }}
                            </mat-option>
                        </mat-optgroup>

                        <mat-optgroup label="Days">
                            <mat-option value="monday">Monday</mat-option>
                            <mat-option value="tuesday">Tuesday</mat-option>
                            <mat-option value="wednesday">Wednesday</mat-option>
                            <mat-option value="thursday">Thursday</mat-option>
                            <mat-option value="friday">Friday</mat-option>
                            <mat-option value="saturday">Saturday</mat-option>
                            <mat-option value="sunday">Sunday</mat-option>
                        </mat-optgroup>
                    </mat-select>

                    <button mat-icon-button
                            matSuffix
                            matTooltip="Add description"
                            (click)="showRangeDescriptionDialog($event, range)">
                        <mat-icon>comment</mat-icon>
                    </button>
                </mat-form-field>

            </td>

            <td>
                <mat-slide-toggle matTooltip="All day?"
                                  [(ngModel)]="range.all_day"
                                  (ngModelChange)="onAllDayChanged(range); _onModelChanged()" />
            </td>

            <td>
                <app-input-time appearance="standard"
                                [(ngModel)]="range.start"
                                (ngModelChange)="_onModelChanged()"
                                [required]="true"
                                [disabled]="range.all_day" />
            </td>

            <td>
                <app-input-time appearance="standard"
                                [(ngModel)]="range.finish"
                                (ngModelChange)="_onModelChanged()"
                                [required]="true"
                                [disabled]="range.all_day" />
            </td>

            <td>
                <app-input-currency appearance="standard"
                                    placeholder="Rate"
                                    [(ngModel)]="range.hourly_rate"
                                    (ngModelChange)="_onModelChanged()"
                                    [required]="true" />
            </td>

            <td class="spacer"></td>

            <td *ngFor="let rate of rates; trackBy: _trackRateFn" class="rate">
                <app-input-currency placeholder="Amount"
                                    floatLabel="never"
                                    appearance="standard"
                                    [(ngModel)]="range.non_pro_rata_rates[rate].amount"
                                    (ngModelChange)="_onModelChanged()" />
            </td>

            <td class="delete-range-cell">
                <button mat-icon-button
                        (click)="deleteRange(range)"
                        matTooltip="Delete range & rates">
                    <mat-icon>clear</mat-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>

<div class="add-range-container">
    <button mat-stroked-button
            (click)="addRange()"
            matTooltip="Add a new range">
        Add range
        <mat-icon>add</mat-icon>
    </button>
</div>
