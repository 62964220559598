import { NgModule } from '@angular/core';
import { AppCommonModule } from '@shared/common/common.module';
import { CarerMultiSelectComponent } from './carer-multi-select.component';
import { CarerSelectComponent } from './carer-select.component';

@NgModule({
    imports: [
        AppCommonModule,
    ],
    exports: [
        CarerSelectComponent,
        CarerMultiSelectComponent,
    ],
    declarations: [
        CarerSelectComponent,
        CarerMultiSelectComponent,
    ]
})
export class AppCarerSelectModule {
}
