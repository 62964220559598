import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {

    constructor(
        private domSanitizer: DomSanitizer
    ) {}

    transform(value: string): any {
        return this.domSanitizer.bypassSecurityTrustHtml(value);
    }
}
