import { CommonModule } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTabGroup as MatTabGroup, MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { AbstractComponent } from '@shared/common/abstract.component';
import { AppCommonModule } from '@shared/common/common.module';
import { distanceUnits, distanceUnitSingular } from '@shared/config';
import { ConfirmDialogComponent } from '@shared/dialogs/confirm/confirm-dialog.component';
import { DEFAULT_MILEAGE_RATES, MILEAGE_RATE_TYPE_OPTIONS } from '@shared/modules/mileage/constants';
import type { MileageBand, MileageBandRates } from '@shared/modules/mileage/mileage-band';
import { AppSnackBar } from '@shared/services/snackbar/app-snackbar.service';
import { cloneDeep, isObject, orderBy } from 'lodash-es';


@Component({
    selector: 'app-mileage-bands',
    templateUrl: './mileage-bands.component.html',
    styleUrls: ['./mileage-bands.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatLegacyTabsModule,
        AppCommonModule
    ]
})
export class MileageBandsComponent extends AbstractComponent implements OnInit {
    _bands: MileageBand[];

    @Input() set bands(value: MileageBand[]) {
        this._bands = makeBands(value);
    }

    @Input() editingPayElementCodes: boolean;
    @Output() bandsChange = new EventEmitter<MileageBand[]>();

    @ViewChild('mileageBandTabs') tabGroup: MatTabGroup;

    distanceUnitSingular = distanceUnitSingular;
    distanceUnit = distanceUnits;
    defaultMileageRates = [...DEFAULT_MILEAGE_RATES];
    mileageRateTypeOptions = [...MILEAGE_RATE_TYPE_OPTIONS];

    _newMileageBand = {
        max_distance: null,
    };

    constructor(
        private dialog: MatDialog,
        private snackBar: AppSnackBar
    ) {
        super();
    }

    ngOnInit(): void {
        if (!this._bands) {
            this._bands = [];
        }

        if (this._bands.length < 1) {
            const defaultBand = makeBandWithDefaults(null);

            this._bands.push(defaultBand);

            requestAnimationFrame(() => {
                this.tabGroup.selectedIndex = this._bands.indexOf(defaultBand);
            });
        } else {
            this.sortTabs();
        }
    }

    trackMileageBand(index: number, mileageRate: any) {
        return mileageRate.max_distance;
    }

    addNewBand() {
        const maxDistance = this._newMileageBand.max_distance;

        if (this._bands.find(band => band.max_distance === maxDistance)) {
            this.snackBar.showError(`There is already a band with a maximum distance of ${maxDistance}`);
            return;
        }

        const newBand = makeBandWithDefaults(maxDistance);

        this._bands.push(newBand);

        this.sortTabs();

        this._newMileageBand.max_distance = null;

        requestAnimationFrame(() => {
            this.tabGroup.selectedIndex = this._bands.indexOf(newBand);
        });

        this._updateModel();
    }

    onDistanceChanged(band: MileageBand) {
        if (this._bands.filter(b => b.max_distance === band.max_distance).length >= 2) {
            band.max_distance++;
        }

        this._updateModel();
    }

    deleteMileageBand(bandIndex) {
        ConfirmDialogComponent.show(this.dialog, {
            title: 'Delete this mileage band?',
            content: 'This cannot be undone',
            okay: 'Delete',
        })
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this._bands.splice(bandIndex, 1);
                }
            });
    }

    sortTabs() {
        this._bands = orderBy(this._bands, bands => bands.max_distance ?? Number.MAX_VALUE, ['asc']);
    }

    _updateModel(): void {
        this.bandsChange.emit(this._bands);
    }
}

function fillMissingRates(rates: MileageBandRates) {
    if (!isObject(rates)) {
        rates = {};
    }

    const ratesCopy: MileageBandRates = cloneDeep(rates ?? {});

    DEFAULT_MILEAGE_RATES.forEach(defaultRate => {
        if (!isObject(ratesCopy[defaultRate.key])) {
            ratesCopy[defaultRate.key] = {
                type: defaultRate.default.type,
                amount: defaultRate.default.amount * 100,
                pay_element_code: defaultRate.default.pay_element_code,
            };
        }

        ratesCopy[defaultRate.key].type = ratesCopy[defaultRate.key]?.type ?? defaultRate.default.type;
        ratesCopy[defaultRate.key].amount = ratesCopy[defaultRate.key]?.amount ?? (defaultRate.default.amount * 100);
        ratesCopy[defaultRate.key].pay_element_code = ratesCopy[defaultRate.key]?.pay_element_code ?? defaultRate.default.pay_element_code;
    });

    return ratesCopy;
}

function makeBands(bands: MileageBand[]): MileageBand[] {
    if (!Array.isArray(bands) || !bands.length) {
        bands = [makeBandWithDefaults(null)];
    }

    return bands.map(band => ({
        max_distance: band.max_distance,
        rates: fillMissingRates(band.rates)
    }));
}

function makeBandWithDefaults(maxDistance: number) {
    const rates = {};

    DEFAULT_MILEAGE_RATES.forEach(rate => {
        rates[rate.key] = {
            amount: rate.default.amount * 100,
            pay_element_code: rate.default.pay_element_code,
            type: rate.default.type,
        };
    });

    return {
        max_distance: maxDistance,
        rates: fillMissingRates({}),
    };
}
