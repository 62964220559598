import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { ComponentsModule } from '@carelinelive/ui';
import { AppAlertModule } from '@shared/common/alert/alert.module';
import { ExpandModule } from '@shared/common/animations/expand/expand.module';
import { AppCardModule } from '@shared/common/cards/card/app-card.module';
import { PageLayoutModule } from '@shared/common/layout/index-page-layout/page-layout.module';
import { LoadingLabelModule } from '@shared/common/loading-label/loading-label.module';
import { AppPipesModule } from '@shared/common/pipes.module';
import { AppFormFieldsModule } from '@shared/form-fields';
import { AuthHttpInterceptor } from '@shared/http/interceptors/auth-http-interceptor';
import { AppAvatarModule } from '@shared/modules/avatar/avatar.module';
import { AppHelpDeskModule } from '@shared/modules/help-desk/help-desk.module';
import { TeamSelectComponent } from '@shared/modules/teams/select/team-select.component';
import { AppPaginatorModule } from '@shared/pagination/paginator.module';
import { AppSnackbarModule } from '@shared/services/snackbar/app-snackbar.module';
import { AppSnackBar } from '@shared/services/snackbar/app-snackbar.service';
import { AppTitleService } from '@shared/services/title/app-title.service';
import { AppSpinnerModule } from '@shared/spinner/spinner.module';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { AppMaterialModule } from './material/material.module';
import { TeamGroupSelectComponent } from '@shared/modules/team-groups/select/team-group-select.component';
import { TranslateModule } from '@ngx-translate/core';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
];

@NgModule({
    declarations: [
        TeamSelectComponent,
        TeamGroupSelectComponent,
        ExpansionPanelComponent
    ],
    imports: [
        CommonModule,
        AppFormFieldsModule,
        AppPipesModule,
        AppAlertModule,
        AppSnackbarModule,
        AppSpinnerModule,
        MatExpansionModule,
        MatSlideToggleModule,
        AppMaterialModule,
        AppAvatarModule,
        AppPaginatorModule,
        LoadingLabelModule,
        AppCardModule,
        AppHelpDeskModule,
        PageLayoutModule,
        ExpandModule,
        ComponentsModule,
    ],
    exports: [
        AppPipesModule,
        AppAlertModule,
        CommonModule,
        AppFormFieldsModule,
        TeamSelectComponent,
        TeamGroupSelectComponent,
        AppSpinnerModule,
        ExpansionPanelComponent,
        AppMaterialModule,
        AppAvatarModule,
        AppPaginatorModule,
        LoadingLabelModule,
        AppCardModule,
        AppHelpDeskModule,
        PageLayoutModule,
        ExpandModule,
        ComponentsModule,
        TranslateModule
    ],
    providers: [
        AppSnackBar,
        AppTitleService,
        ...httpInterceptorProviders,
    ].filter(Boolean)
})
export class AppCommonModule {
}
