import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    CardActionsComponent,
    CardComponent,
    CardHeaderActionsDirective,
    CardSubtitleDirective,
    CardTitleDirective
} from './card.component';


const components = [
    CardComponent,
    CardTitleDirective,
    CardSubtitleDirective,
    CardHeaderActionsDirective,
    CardActionsComponent
];

@NgModule({
    declarations: [...components],
    exports: [...components],
    imports: [
        CommonModule
    ]
})
export class AppCardModule {
}
