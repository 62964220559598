export enum DialogResult {
    success = 'success',
    cancelled = 'cancelled',
    reset = 'reset',
    partial = 'partial',
    deleted = 'deleted',
}

export enum DialogWidth {
    small = '420px',
    medium = '960px',
    large = '1240px',
    full = '100%',
}
