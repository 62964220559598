import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { AppAlertModule } from '@shared/common/alert/alert.module';
import { AppBadgeModule } from '@shared/common/badges/badge/badge.module';
import { AppPipesModule } from '@shared/common/pipes.module';
import { CurrencyMaskModule } from '@shared/form-fields/currency/mask/currency-mask.module';
import { AppLuxonDateModule } from '@shared/form-fields/luxon-date-adapter';
import { SelectOptionComponent } from '@shared/form-fields/select-option/select-option.component';
import { AppAvatarModule } from '@shared/modules/avatar/avatar.module';

const modules = [
    AppAlertModule,
    AppAvatarModule,
    AppBadgeModule,
    AppLuxonDateModule,
    AppPipesModule,
    CommonModule,
    CurrencyMaskModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
];

@NgModule({
    declarations: [SelectOptionComponent],
    imports: [...modules],
    exports: [...modules, SelectOptionComponent]
})
export class FormFieldsSharedModule {

}
