import { Component, Inject, Injectable } from '@angular/core';
import type { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogComponent } from '@shared/dialogs/dialog.component';
import type { ApiObservable } from '@shared/models/api-data';
import { successfulResult } from '@shared/models/api-state/operators';
import { shareReplay } from 'rxjs/operators';

@Component({
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
@UntilDestroy()
export class ConfirmDialogComponent extends DialogComponent {
    public options: ConfirmData;

    public action$: ApiObservable<boolean>;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmData
    ) {
        super();

        this.dialogRef = dialogRef;
        this.options = data;
    }

    cancel() {
        this.dialogRef.close(false);
    }

    okay() {
        if (this.data.action) {
            this.action$ = this.action$ ?? this.data.action.pipe(
                untilDestroyed(this),
                shareReplay(1)
            );

            this.action$.pipe(successfulResult()).subscribe(() => {
                this.dialogRef.close(true);
            });
        } else {
            this.dialogRef.close(true);
        }
    }

    public static show(dialog: MatDialog, data: ConfirmData): MatDialogRef<ConfirmDialogComponent, boolean> {
        return dialog.open<ConfirmDialogComponent, ConfirmData, boolean>(ConfirmDialogComponent, {
            data,
            minWidth: '320px',
            maxWidth: '420px'
        });
    }
}

export interface ConfirmData {
    title: string;
    subtitle?: string;
    content?: string;
    warning?: string;
    cancel?: string;
    okay?: string;

    action?: ApiObservable<boolean>;
}
