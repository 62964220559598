<app-dialog-header [closeResult]="false">
    <app-dialog-title>{{ options.title }}</app-dialog-title>
    <app-dialog-subtitle *ngIf="options.subtitle">{{ options.subtitle }}</app-dialog-subtitle>
</app-dialog-header>

<mat-dialog-content *ngIf="options.content?.length || options.warning?.length">
    <p *ngIf="options.content">
        {{ options.content }}
    </p>

    <cll-panel colour="error" *ngIf="options.warning">
        <cll-icon cllPanelIcon>warning</cll-icon>

        {{ options.warning }}
    </cll-panel>
</mat-dialog-content>

<mat-dialog-actions>
    <button cll-button
            mat-dialog-close
            (click)="cancel()">
        {{ options.cancel || 'Cancel' }}
    </button>

    <button cll-filled-button
            colour="primary"
            (click)="okay()"
            data-testid="button:confirm-issue"
            [disabled]="(action$ | async)?.fetching">
        <app-loading-label [loading]="(action$ | async)?.fetching">
            {{ options.okay || 'Okay' }}
        </app-loading-label>
    </button>
</mat-dialog-actions>
