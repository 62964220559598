import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class IntercomService {
    public loaded = false;
    public showing = false;
    public unreadCount = 0;

    constructor() {
        if (window['Intercom']) {
            this.loaded = true;

            window['Intercom']('onShow', () => {
                this.showing = true;
            });

            window['Intercom']('onHide', () => {
                this.showing = false;
            });

            window['Intercom']('onUnreadCountChange', (unreadCount) => {
                this.unreadCount = unreadCount;
            });
        }
    }

    toggleMessenger() {
        if (window['Intercom']) {
            if (this.showing) {
                window['Intercom']('hide');
            } else {
                window['Intercom']('show');
            }
        }
    }

    showArticle(intercomId: number): boolean {
        if (window['Intercom']) {
            window['Intercom']('showArticle', intercomId);
            return true;
        }

        return false;
    }
}
