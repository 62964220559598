export enum Country {
    // https://en.wikipedia.org/wiki/ISO_3166-2:GB
    England = 'GB-ENG',

    // https://en.wikipedia.org/wiki/ISO_3166-2:GB-WLS
    Wales = 'GB-WLS',

    // https://en.wikipedia.org/wiki/ISO_3166-2:GB-NIR
    NorthernIreland = 'GB-NIR',

    // https://en.wikipedia.org/wiki/ISO_3166-2:GB-SCT
    Scotland = 'GB-SCT',

    // https://en.wikipedia.org/wiki/ISO_3166-2:AU
    AustraliaQueensland = 'AU-QLD',

    // https://en.wikipedia.org/wiki/ISO_3166-2:AU
    AustraliaVictoria = 'AU-VIC',

    // https://en.wikipedia.org/wiki/ISO_3166-2:AU
    AustraliaNewSouthWales = 'AU-NSW',

    // https://en.wikipedia.org/wiki/ISO_3166-2:AU
    AustraliaWesternAustralia = 'AU-WA',

    // https://en.wikipedia.org/wiki/ISO_3166-2:AU
    AustraliaNorthernTerritory = 'AU-NT',

    // https://en.wikipedia.org/wiki/ISO_3166-2:NZ
    NewZealand = 'NZ',

    // https://en.wikipedia.org/wiki/ISO_3166-2:IE
    Ireland = 'IE',

    // https://en.wikipedia.org/wiki/ISO_3166-2:ES
    Spain = 'ES-MD',

    // https://en.wikipedia.org/wiki/ISO_3166-2:MT
    Malta = 'MT',

    // https://en.wikipedia.org/wiki/ISO_3166-2:ZW
    Zimbabwe = 'ZW',
}

export const UK: Country[] = [
    Country.England,
    Country.Wales,
    Country.NorthernIreland,
    Country.Scotland,
];

export const AUSTRALIA: Country[] = [
    Country.AustraliaQueensland,
    Country.AustraliaVictoria,
    Country.AustraliaNewSouthWales,
    Country.AustraliaWesternAustralia,
    Country.AustraliaNorthernTerritory,
];
