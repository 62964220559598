import { NgModule } from '@angular/core';
import { AppCommonModule } from '@shared/common/common.module';
import { FixedRatesSelectComponent } from './select/fixed-rates-select.component';

@NgModule({
    imports: [
        AppCommonModule
    ],
    declarations: [
        FixedRatesSelectComponent
    ],
    exports: [
        FixedRatesSelectComponent
    ]
}) export class AppFixedRatesModule { }
