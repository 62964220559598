import { InputService } from './input.service';

export type OnModelChange = (value) => void;
export type OnModelTouched = ($event: Event) => void;

export class InputHandler {

    private inputService: InputService;
    private onModelChange: OnModelChange;
    private onModelTouched: OnModelTouched;

    constructor(htmlInputElement: HTMLInputElement, options: any) {
        this.inputService = new InputService(htmlInputElement, options);
    }

    handleCut(event: any): void {
        setTimeout(() => {
            this.inputService.updateFieldValue();
            this.setValue(this.inputService.value);
            this.onModelChange(this.inputService.value);
        }, 0);
    }

    handleInput(event: any): void {
        const keyCode = this.inputService.rawValue.charCodeAt(this.inputService.rawValue.length - 1);
        const rawValueLength = this.inputService.rawValue.length;
        const rawValueSelectionEnd = this.inputService.inputSelection.selectionEnd;
        const storedRawValueLength = this.inputService.storedRawValue.length;
        this.inputService.rawValue = this.inputService.storedRawValue;

        if (rawValueLength !== rawValueSelectionEnd || Math.abs(rawValueLength - storedRawValueLength) !== 1) {
            this.setCursorPosition(event);
            return;
        }

        if (rawValueLength < storedRawValueLength) {
            this.inputService.removeNumber(8);
        }

        if (rawValueLength > storedRawValueLength) {
            switch (keyCode) {
                case 43:
                    this.inputService.changeToPositive();
                    break;

                case 45:
                    this.inputService.toggleNegative();
                    break;

                default:
                    if (!this.inputService.canInputMoreNumbers) {
                        return;
                    }

                    this.inputService.addNumber(keyCode);
                    break;
            }
        }

        this.setCursorPosition(event);

        this.onModelChange(this.inputService.value);
    }

    handleKeydown(event: KeyboardEvent): void {
        const keyCode = event.which || event.charCode || event.keyCode;

        switch (keyCode) {
            case 38:
                this.inputService.adjustValue(0.01);
                this.onModelChange(this.inputService.value);
                break;

            case 40:
                this.inputService.adjustValue(-0.01);
                this.onModelChange(this.inputService.value);
                break;
        }

        if (keyCode === 8 || keyCode === 46 || keyCode === 63272) {
            event.preventDefault();

            if (this.inputService.inputSelection.selectionStart <= this.inputService.prefixLength() &&
                this.inputService.inputSelection.selectionEnd >= this.inputService.rawValue.length - this.inputService.suffixLength()) {
                this.clearValue();
            } else {
                this.inputService.removeNumber(keyCode);
                this.onModelChange(this.inputService.value);
            }
        }
    }

    clearValue() {
        this.setValue(this.inputService.isNullable() ? null : 0);

        this.onModelChange(this.inputService.value);
    }

    handleKeypress(event: any): void {
        const keyCode = event.which || event.charCode || event.keyCode;
        event.preventDefault();

        if (keyCode === 97 && event.ctrlKey) {
            return;
        }

        switch (keyCode) {
            case undefined:
            case 9:
            case 13:
                return;

            case 43:
                this.inputService.changeToPositive();
                break;

            case 45:
                this.inputService.toggleNegative();
                break;

            default:
                if (this.inputService.canInputMoreNumbers) {
                    const selectionRangeLength = Math.abs(
                        this.inputService.inputSelection.selectionEnd - this.inputService.inputSelection.selectionStart
                    );

                    if (selectionRangeLength === this.inputService.rawValue.length) {
                        this.setValue(null);
                    }

                    this.inputService.addNumber(keyCode);
                }
                break;
        }

        this.onModelChange(this.inputService.value);
    }

    handlePaste(event: any): void {
        setTimeout(() => {
            this.inputService.updateFieldValue();
            this.setValue(this.inputService.value);
            this.onModelChange(this.inputService.value);
        }, 1);
    }

    updateOptions(options: any): void {
        this.inputService.updateOptions(options);
    }

    getOnModelChange(): OnModelChange {
        return this.onModelChange;
    }

    setOnModelChange(callbackFunction: OnModelChange): void {
        this.onModelChange = callbackFunction;
    }

    getOnModelTouched(): OnModelTouched {
        return this.onModelTouched;
    }

    setOnModelTouched(callbackFunction: OnModelTouched) {
        this.onModelTouched = callbackFunction;
    }

    setValue(value: number): void {
        this.inputService.value = value;
    }

    private setCursorPosition(event: any): void {
        setTimeout(() => {
            event.target.setSelectionRange(event.target.value.length, event.target.value.length);
        }, 0);
    }
}
