<div class="app-password-strength-popover__checklist hint">
    <div class="app-password-strength-popover-check-item">
        <div
            class="app-password-strength-popover-tick"
            [ngClass]="{ checked: fulfilLength }">
            <cll-icon [size]="20">done</cll-icon>
        </div>
        At least 8 characters
    </div>
    <div class="app-password-strength-popover-check-item">
        <div
            class="app-password-strength-popover-tick"
            [ngClass]="{ checked: hasBothCases }">
            <cll-icon [size]="20">done</cll-icon>
        </div>
        Have both uppercase and lowercase letters
    </div>
    <div class="app-password-strength-popover-check-item">
        <div
            class="app-password-strength-popover-tick"
            [ngClass]="{ checked: hasNumber }">
            <cll-icon [size]="20">done</cll-icon>
        </div>
        At least 1 number
    </div>
    <div class="app-password-strength-popover-check-item">
        <div
            class="app-password-strength-popover-tick"
            [ngClass]="{ checked: hasSymbol }">
            <cll-icon [size]="20">done</cll-icon>
        </div>
        At least 1 symbol
    </div>
</div>
