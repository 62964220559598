import { Component, Input } from '@angular/core';
import type { When } from '@shared/common/when';
import { AbstractInputComponent } from '@shared/form-fields/abstract-value-accessor/abstract-value';
import { makeProvider } from '@shared/form-fields/make-provider';
import { isPresent } from '@shared/utils/helpers';

@Component({
    selector: 'app-input-when',
    styleUrls: ['./when-input.component.scss'],
    template: `
        <div class="form-row">
            <app-input-date
                label="Date"
                name="start_date"
                class="app-form-field"
                *ngIf="showDate && value"
                [(ngModel)]="value.start"
                (ngModelChange)="onStartChange()"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="disabled"
                data-testid="input:date"
                [required]="required"
                valueType="datetime" />

            <app-input-time
                label="Time"
                name="start_time"
                class="app-form-field"
                *ngIf="value"
                valueType="datetime"
                [(ngModel)]="value.start"
                (ngModelChange)="onStartChange()"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="disabled"
                data-testid="input:time"
                [required]="required" />

            <app-input-duration
                label="Duration"
                name="duration"
                class="app-form-field"
                *ngIf="value && showDuration"
                [(ngModel)]="value.duration"
                (ngModelChange)="onDurationChange()"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="disabled"
                [required]="required" />

            <app-input-time label="Finish"
                            name="finish_time"
                            class="app-form-field"
                            *ngIf="value && showFinish"
                            [(ngModel)]="value.finish"
                            (ngModelChange)="onFinishChange()"
                            [ngModelOptions]="{ standalone: true }"
                            [disabled]="disabled"
                            [required]="required"
                            valueType="datetime" />

            <ng-content></ng-content>
        </div>

        <app-alert info *ngIf="finishesFollowingDay">
            Finishes the following day
        </app-alert>

        <app-alert *ngIf="finishesAfterFollowingDay">
            Finishes on {{ value.finish | appDate }}
        </app-alert>
    `,
    styles: [`
        :host {
            display: block;
        }`,
    ],
    providers: [makeProvider(WhenInputComponent)],
})
export class WhenInputComponent extends AbstractInputComponent<When> {
    @Input() showDate = true;
    @Input() showDuration = true;
    @Input() showFinish = true;

    get finish() {
        if (this.value && this.value.start && isPresent(this.value.duration)) {
            return this.value.start
                .plus({
                    seconds: this.value.duration,
                })
                .toLocal()
                .toFormat('HH:mm');
        }

        return null;
    }

    get finishesFollowingDay() {
        if (!this.value?.finish || !this.value?.start) {
            return false;
        }

        return this.value.finish >= this.value.start.plus({ days: 1 }).startOf('day')
            && !this.finishesAfterFollowingDay;
    }

    get finishesAfterFollowingDay() {
        if (!this.value?.finish || !this.value?.start) {
            return false;
        }

        return this.value.finish >= this.value.start.plus({ days: 2 }).startOf('day');
    }

    writeValue(value: When) {
        super.writeValue(this.transformValue(value));
    }

    protected transformValue(v: When): When {
        if (v) {
            if (!v.finish && (v.start && v.duration)) {
                v.finish = v.start.plus({ seconds: v.duration });
            }
        }

        return super.transformValue(v);
    }

    onStartChange() {
        const value = this.value;
        const start = value?.start;

        if (value && start) {
            value.finish = start.plus({ seconds: value.duration });
        }

        this.dispatchChange();
    }

    onDurationChange() {
        if (this.value && this.value.start) {
            this.value.finish = this.value.start.plus({ seconds: this.value.duration });
        }

        this.dispatchChange();
    }

    onFinishChange() {
        if (this.value && this.value.start && this.value.finish) {
            if (this.value.finish < this.value.start) {
                this.value.start = this.value.finish.minus({
                    seconds: this.value.duration
                });
            } else {
                this.value.duration = (this.value.finish.valueOf() - this.value.start.valueOf()) / 1000;
            }
        }

        this.dispatchChange();
    }
}

