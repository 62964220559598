/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { AppCommonModule } from '@shared/common/common.module';
import { CarerContractSelectModule } from '@shared/modules/carer-contracts/select/carer-contract-select.module';
import { ContractsCommonModule } from '@shared/modules/contracts/common/contracts-common.module';
import { OverridePanelModule } from '@shared/modules/contracts/common/override-panel/override-panel.module';
import {
    ShiftPaymentStrategySelectComponent
} from '@shared/modules/shift-payment-strategies/select/shift-payment-strategy-select.component';
import { MileageBandsComponent } from './mileage-bands/mileage-bands.component';
import { CarerContractPayElementCodesComponent } from './pay-element-codes/carer-contract-pay-element-codes.component';

const components = [
    ShiftPaymentStrategySelectComponent,
    CarerContractPayElementCodesComponent
];

@NgModule({
    imports: [
        CommonModule,
        AppCommonModule,
        ContractsCommonModule,
        MatTabsModule,
        OverridePanelModule,
        CarerContractSelectModule,
        MileageBandsComponent,
    ],
    declarations: components,
    exports: [...components, MatExpansionModule, ContractsCommonModule, CarerContractSelectModule]
})
export class CarerContractsCommonModule {

}
