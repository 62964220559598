<div
    *ngIf="{
        registrationRequest: registrationRequest$ ? (registrationRequest$ | async) : null
    } as context">
    <app-auth-form-card
        [title]="'Set up your ' + type + ' account'">
        <form
            *ngIf="formGroup"
            [formGroup]="formGroup"
            (ngSubmit)="submitForm()">
            <ng-container *ngIf="email; else noEmail">
                <cll-card appearance="outlined">
                    {{ email }}
                </cll-card>

                <br />

                <cll-panel colour="warning">
                    <cll-icon cllPanelIcon>info</cll-icon>
                    If your email address is incorrect, please contact your system administrator.
                </cll-panel>
            </ng-container>
            <ng-template #noEmail>
                <cll-form-field label="Email address">
                    <input
                        type="email"
                        [formControl]="formGroup.controls.email"
                        #formInput="cllFormInput"
                        cllFormInput />
                </cll-form-field>
            </ng-template>

            <div>Choose a new password</div>

            <cll-form-field label="New password">
                <input
                    #password
                    #passwordInput="cllFormInput"
                    [formControl]="formGroup.controls.password"
                    cllFormInput
                    type="password" />

                <cll-password-toggle
                    cllSuffix
                    [input]="password" />
            </cll-form-field>

            <app-expand [expand]="!!formGroup.controls.password.value">
                <app-password-strength-indicator [password]="formGroup.controls.password.value" />
            </app-expand>

            <cll-form-field label="Confirm your new password">
                <input
                    #password_confirmation
                    #passwordInput="cllFormInput"
                    [formControl]="formGroup.controls.password_confirmation"
                    cllFormInput
                    type="password" />

                <cll-password-toggle
                    cllSuffix
                    [input]="password" />
            </cll-form-field>

            <button
                cll-filled-button
                colour="secondary"
                [disabled]="formGroup.invalid || context.registrationRequest?.fetching"
                type="submit">
                <cll-icon>check</cll-icon>
                Reset your password
            </button>
        </form>
    </app-auth-form-card>
</div>
