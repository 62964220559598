import { pick } from 'lodash-es';
import cloneDeep from 'lodash-es/cloneDeep';
import { pad } from './strings';

export function clone<T>(value: T): T {
    return cloneDeep(value);
}

export function cloneOnly<T>(value: T, only: string[]): Partial<T> {
    return pick(cloneDeep(value), only);
}

/**
 * Takes a decimal and returns a time string. E.g. 1.5 -> 1:30
 *
 * @param input
 * @param roundToMinutes
 * @return
 */
export function decimalHoursToHoursMinutes(input: number, roundToMinutes = 0) {
    let hours = Math.floor(input);
    let minutes = (input % 1) * 60;

    if (roundToMinutes) {
        minutes = Math.min(60, roundToNearest(minutes, roundToMinutes));
    }

    if (minutes === 60) {
        hours += 1;
        minutes = 0;
    }

    return `${pad(hours, 2)}:${pad(minutes, 2)}`;
}

export function roundToNearest(value, roundTo) {
    roundTo = 1 / roundTo;

    return parseFloat((Math.round(value * roundTo) / roundTo).toFixed(decimalPlaces(1 / roundTo)));
}

function decimalPlaces(number) {
    const match = String(number).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);

    if (!match) {
        return 0;
    }

    return Math.max(
        0,
        // Number of digits right of decimal point.
        (match[1] ? match[1].length : 0) -
        // Adjust for scientific notation.
        (match[2] ? +match[2] : 0),
    );
}

/**
 * Convert seconds into a readable string.
 *
 * @param value
 * @returns
 */
export function secondsToReadable(value) {
    value = parseInt(value, 10);

    if (isNaN(value)) {
        return '0m';
    }

    const isNegative = value < 0;

    value = Math.abs(value);

    // Value should be in seconds
    const hours = Math.floor(value / 3600);
    value %= 3600;
    const minutes = Math.floor(value / 60);
    value %= 60;
    const seconds = value;

    const parts = [];

    if (hours > 0) {
        parts.push(`${hours}h`);

        if (minutes > 0) {
            parts.push(`${minutes}m`);
        }
    } else if (minutes > 0) {
        parts.push(`${minutes}m`);
        if (seconds > 0) {
            parts.push(`${seconds}s`);
        }
    } else {
        parts.push(`${seconds}s`);
    }

    return (isNegative ? '-' : '') + parts.join(' ');
}

export function trackByProperty(property: string) {
    return (index: number, item: any) => {
        if (!item) {
            return null;
        }

        return item[property];
    };
}

export function trackBy<T>(property: ((item: T) => string)) {
    return (index: number, item: any) => {
        if (!item) {
            return null;
        }

        return property(item);
    };
}

export function trackById(index: number, item: { id: string | number }) {
    if (!item) {
        return null;
    }

    return item.id;
}


export function defaultTrackBy<T>(index: number, item: T): any {
    if (!item) {
        return null;
    }

    if (item.hasOwnProperty('id')) {
        return (item as any).id;
    }

    return item;
}
