import { Component, Input } from '@angular/core';
import { AppSelectOption } from '@shared/form-fields/options/select-option';

@Component({
    selector: 'app-select-option',
    templateUrl: './select-option.component.html',
    styleUrls: ['./select-option.component.scss']
})
export class SelectOptionComponent {
    @Input() option: AppSelectOption<any>;

    @Input() showDescription = true;
    @Input() showMeta = true;
}
