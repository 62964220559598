import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';

type BadgeColour = 'purple' | 'outline-purple' | 'red-solid' | 'red' | 'tonal' | 'green' | 'tonal-purple'| 'tonal-blue'| 'tonal-green';

type BadgeTextTransform = 'initial' | 'uppercase' | 'lowercase';

type BadgeSize = 'small' | 'normal' | 'large' | 'xlarge';

@Component({
    selector: 'app-badge, [appBadge]',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
    @Input() color: BadgeColour = 'tonal';
    @Input() size: BadgeSize;
    @Input() icon: string;

    @HostBinding('style.text-transform')
    @Input() textTransform: BadgeTextTransform = 'initial';

    @HostBinding('class')
    get ngClass() {
        const classes = {};

        if (this.color) {
            classes[`app-badge-${this.color}`] = true;
        }

        if (this.size) {
            classes[`app-badge-${this.size}`] = true;
        }

        return classes;
    }

    constructor(
        private elementRef: ElementRef
    ) {
    }

}
