import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import type { ApiObservable } from '@shared/models/api-data';
import type { ResetPasswordBody } from '../models';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
    formGroup = new FormGroup({
        email: new FormControl<string>(window.CareLineLive.passwordReset.email, [
            Validators.required,
            Validators.email,
        ]),
        password: new FormControl<string>(null, [Validators.minLength(6), Validators.required]),
        password_confirmation: new FormControl<string>(null, Validators.required),
    }, {
        validators: [this.auth.passwordConfirmationValidator('password', 'password_confirmation')],
    });

    resetPasswordRequest$: ApiObservable;

    constructor(private auth: AuthService) {
    }

    submitForm() {
        const body: ResetPasswordBody = {
            ...this.formGroup.getRawValue(),
            token: window.CareLineLive.passwordReset.token,
        };

        this.resetPasswordRequest$ = this.auth.resetPassword(body).pipe(
            tap(response => {
                if (response.fetching) {
                    this.formGroup.disable();
                } else if (response.successful) {
                    this.formGroup.disable();
                    window.open('/auth/login', '_self');
                } else {
                    this.formGroup.enable();
                }
            }),
        );
    }
}
