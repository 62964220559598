import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import type { AfterViewInit } from '@angular/core';
import { Component, ElementRef, Input, NgZone, Renderer2, ViewChild } from '@angular/core';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { AbstractFormFieldComponent } from '@shared/form-fields/abstract-form-field/abstract-form-field.component';
import { makeProvider } from '@shared/form-fields/make-provider';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-input-textarea',
    styleUrls: ['./text-area-input.component.scss'],
    template: `
        <mat-form-field [appearance]="appearance">
            <mat-label *ngIf="label">
                <mat-icon *ngIf="labelIcon">{{ labelIcon }}</mat-icon>
                {{ label }}
            </mat-label>

            <div matPrefix>
                <ng-content select="[appPrefix]"></ng-content>
            </div>

            <textarea
                type="text"
                matInput
                cdkTextareaAutosize
                [cdkAutosizeMinRows]="minRows"
                #textarea="cdkTextareaAutosize"
                [(ngModel)]="value"
                [ngModelOptions]="{ standalone: true }"
                [placeholder]="placeholder"
                [disabled]="disabled"
                [readonly]="readonly"
                [required]="required"
                [minlength]="minlength"
                [maxlength]="maxlength"
                (blur)="_onTouch()">
            </textarea>

            <div matSuffix>
                <ng-content select="[appSuffix]"></ng-content>
            </div>

            <mat-hint *ngIf="hint">
                {{ hint }}
            </mat-hint>

            <mat-error *ngFor="let message of errorMessages">{{ message }}</mat-error>
        </mat-form-field>
    `,
    providers: [makeProvider(TextAreaInputComponent)],
})
export class TextAreaInputComponent extends AbstractFormFieldComponent<string> implements AfterViewInit {
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() placeholder: string;
    @Input() readonly: boolean;
    @Input() minlength: number;
    @Input() maxlength: number;
    @Input() hint: string;
    @Input() label: string;
    @Input() minRows = 1;

    @ViewChild('textarea', { static: false }) autosize: CdkTextareaAutosize;

    constructor(
        protected element: ElementRef,
        protected renderer: Renderer2,
        private _ngZone: NgZone) {
        super(element, renderer);
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        // Wait for changes to be applied, then trigger textarea resize.
        this._ngZone.onStable.pipe(take(1))
            .subscribe(() => this.autosize.resizeToFitContent(true));
    }


}
