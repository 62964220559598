import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { toDateTime } from '../../../utils/date-time';
import { DateTime } from 'luxon';

@Pipe({ name: 'appDateShort' })
export class AppDateShortPipe implements PipeTransform {
    transform(value: any): any {
        const instance = toDateTime(value);

        if (instance?.isValid) {
            return instance.toLocaleString(DateTime.DATE_SHORT);
        }

        return value;
    }
}
