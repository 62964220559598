import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { AppErrorSnackbarComponent } from './error/app-error-snackbar.component';
import { AppProgressSnackbarComponent } from './progress/app-progress-snackbar.component';
import { AppSuccessSnackbarComponent } from './success/app-success-snackbar.component';
import { ComponentsModule } from '@mastechnicaegroup/carelinelive-ui';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AppActionRequiredSnackbarComponent } from './action-required/app-action-required-snackbar.component';

@NgModule({
    declarations: [
        AppProgressSnackbarComponent,
        AppSuccessSnackbarComponent,
        AppErrorSnackbarComponent,
        AppActionRequiredSnackbarComponent,
    ],
    imports: [
        MatProgressSpinnerModule,
        MatButtonModule,
        MatSnackBarModule,
        CommonModule,
        MatIconModule,
        ComponentsModule,
        MatProgressBarModule,
    ],
    exports: [
        AppSuccessSnackbarComponent,
        AppProgressSnackbarComponent,
        AppErrorSnackbarComponent,
        AppActionRequiredSnackbarComponent,
        MatSnackBarModule,
    ]
})
export class AppSnackbarModule {

}
