import type { OnDestroy, PipeTransform } from '@angular/core';
import { ChangeDetectorRef, NgModule, NgZone, Pipe } from '@angular/core';
import { timeAgo, toDateTime } from '@shared/utils/date-time';
import type { DateTime } from 'luxon';


@Pipe({ name: 'appTimeAgo', pure: false })
export class TimeAgoPipe implements PipeTransform, OnDestroy {
    private currentTimer: number | null;

    private lastTime: number;
    private lastValue: DateTime;
    private lastLocale?: string;
    private lastText: string;

    constructor(private cdRef: ChangeDetectorRef, private ngZone: NgZone) {
    }

    format(v: DateTime) {
        return timeAgo(v);
    }

    transform(
        value: string | DateTime,
        utc = true
    ): string {
        const instance = toDateTime(value, utc);

        if (!instance || !instance.isValid) {
            return;
        }

        if (this.hasChanged(instance)) {
            this.lastTime = this.getTime(instance);
            this.lastValue = instance;
            this.lastLocale = this.getLocale(instance);
            this.removeTimer();
            this.createTimer();
            this.lastText = this.format(instance);
        } else {
            this.createTimer();
        }

        return this.lastText;
    }

    ngOnDestroy(): void {
        this.removeTimer();
    }

    private createTimer() {
        if (this.currentTimer) {
            return;
        }

        const momentInstance = this.lastValue;
        const timeToUpdate = this.getSecondsUntilUpdate(momentInstance) * 1000;

        this.currentTimer = this.ngZone.runOutsideAngular(() => {
            if (typeof window !== 'undefined') {
                return window.setTimeout(() => {
                    this.lastText = this.format(this.lastValue);

                    this.currentTimer = null;
                    this.ngZone.run(() => this.cdRef.markForCheck());
                }, timeToUpdate);
            } else {
                return null;
            }
        });
    }

    private removeTimer() {
        if (this.currentTimer) {
            window.clearTimeout(this.currentTimer);
            this.currentTimer = null;
        }
    }

    private getSecondsUntilUpdate(instance: DateTime) {
        const howOld = Math.abs(instance.diffNow('minute').minutes);

        if (howOld < 1) {
            return 1;
        } else if (howOld < 60) {
            return 30;
        } else if (howOld < 180) {
            return 300;
        } else {
            return 3600;
        }
    }

    private hasChanged(value: DateTime): boolean {
        return this.getTime(value) !== this.lastTime
            || this.getLocale(value) !== this.lastLocale;
    }

    private getTime(value: DateTime): number {
        return value.valueOf();
    }

    private getLocale(value: DateTime): string {
        return value.locale;
    }
}

@NgModule({
    declarations: [
        TimeAgoPipe
    ],
    exports: [
        TimeAgoPipe
    ]
})
export class TimeAgoPipeModule {

}
