import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { PageHeaderComponent, PageSubtitleComponent, PageTitleComponent } from './page-header.component';


@NgModule({
    declarations: [
        PageHeaderComponent,
        PageTitleComponent,
        PageSubtitleComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        PageHeaderComponent,
        PageTitleComponent,
        PageSubtitleComponent,
    ]
})
export class PageHeaderModule {
}
