import { CurrencyPipe } from '@angular/common';
import type { AfterViewInit, ElementRef } from '@angular/core';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import type { SafeHtml } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { isPresent } from '@shared/utils/helpers';
import { toTitleCase } from '@shared/utils/strings';
import { PluralPipe } from '../../../../pipes/plural.pipe';
import { secondsToReadable } from '../../../../utils';
import { currentOptionSetByDate } from '../../../contract-option-sets/contract-option-sets';

export type ValueType = 'currency'
    | 'text'
    | 'boolean'
    | 'number'
    | 'duration'
    | 'non_pro_rata_rates'
    | 'hourly_rate_ranges'
    | 'fixed_rates'
    | 'duration_rounding'
    | 'call_out_charges'
    | 'minimum_contract_value'
    | 'xero';

interface InheritedValue {
    date: string;
    html: string | SafeHtml;
}

@Component({
    selector: 'app-override-panel',
    styleUrls: ['./override-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './override-panel.component.html',
    providers: [
        CurrencyPipe,
        PluralPipe
    ]
})
export class OverridePanelComponent implements AfterViewInit {

    @Input()
    set inherited(value: { data: Record<string, any>; key: string; type: ValueType }) {
        if (value) {
            const dates: string[] = Object.keys(value.data);

            this._inherited = dates.map(date => {
                const html = this.formatCurrentValue(value.key, value.type, value.data[date][value.key]);

                return ({
                    date,
                    html: html ? this.sanitizer.bypassSecurityTrustHtml(
                        html
                    ) : null
                });
            });

            const currentOptionSet = currentOptionSetByDate(dates);

            this._currentInherited = this._inherited.find(i => i.date === currentOptionSet);
        } else {
            this._inherited = null;
            this._currentInherited = null;
        }
    }

    @ViewChildren('inheritedContainer') inheritedRef: QueryList<ElementRef>;

    @Input() disabled = false;

    @Input() override: boolean;
    @Output() overrideChange = new EventEmitter<boolean>();

    _inherited: InheritedValue[];
    _currentInherited: InheritedValue;

    showInheritedContainer = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private currencyPipe: CurrencyPipe,
        private pluralPipe: PluralPipe,
        private sanitizer: DomSanitizer
    ) {
    }

    ngAfterViewInit(): void {
        this.inheritedRef.changes.subscribe(() => {
            this._checkInheritedExists();
        });

        this._checkInheritedExists();
    }

    private _checkInheritedExists() {
        requestAnimationFrame(() => {
            this.showInheritedContainer = false;

            for (const ref of this.inheritedRef) {
                if (ref.nativeElement.children.length > 0) {
                    this.showInheritedContainer = true;
                    break;
                }
            }

            this.changeDetectorRef.markForCheck();
        });
    }

    formatCurrentValue(key: string, type: ValueType, value: any): string {
        switch (type) {
            case 'currency':
                return isPresent(value) ? this.currencyPipe.transform(value / 100) : 'None';

            case 'duration':
                return secondsToReadable(value);

            case 'text':
            case 'number':
                if (key === 'default_rate_strategy' || key === 'shift_payment_strategy') {
                    return toTitleCase(value.split('_').join(' '));
                }

                return isPresent(value) ? `${value}` : null;

            case 'boolean':
                return `<mat-icon class="mat-icon material-icons">${value ? 'check' : 'clear'}</mat-icon>`;

            case 'non_pro_rata_rates':
                const ratesCount = value ? Object.keys(value).length : 0;
                return `${ratesCount} ${this.pluralPipe.transform('rate', ratesCount)}`;

            case 'hourly_rate_ranges':
                const rangesCount = value ? Object.keys(value).length : 0;
                return `${rangesCount} ${this.pluralPipe.transform('range', rangesCount)}`;

            case 'fixed_rates':
                const fixedRatesCount = value ? Object.keys(value).length : 0;
                return `${fixedRatesCount} ${this.pluralPipe.transform('rate', fixedRatesCount)}`;
        }

        return null;
    }
}
