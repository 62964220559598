import type { PipeTransform } from '@angular/core';
import { Pipe, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'nl2br', pure: true })
export class Nl2BrPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(value: string, args: string[] = []): any {
        if (!value) {
            return value;
        }

        const breakTag = '<br>';

        const sanitized = this.sanitizer.sanitize(SecurityContext.HTML, value);

        return this.sanitizer.bypassSecurityTrustHtml(
            sanitized
                .replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n|&#10;)/g, `$1${breakTag}$2`)
        );
    }
}
