import { NgModule } from '@angular/core';
import { IfCountryDirective } from './if-country.directive';
import { UnlessCountryDirective } from './unless-country.directive';

@NgModule({
    declarations: [IfCountryDirective, UnlessCountryDirective],
    exports: [IfCountryDirective, UnlessCountryDirective],
})
export class AppCountriesModule {
}
