export interface NonProRataRate {
    amount: number;
    duration: number;
    pay_element_code?: string;
}

export interface NonProRataRateDictionary {
    [prop: number]: NonProRataRate;
}

export function createNonProRataRateDictionary(rates: NonProRataRate[]): NonProRataRateDictionary {
    const dictionary: NonProRataRateDictionary = {};

    if (Array.isArray(rates)) {
        rates.forEach(rate => {
            dictionary[rate.duration] = rate;
        });
    }

    return dictionary;
}
